const OLD_API_URL = "https://staging-esg-backend.appricott.in/zais/";
const API_URL = "https://staging-esg-backend.appricott.in/api/";
const SUCCESS_MESSAGE = {
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
};
const REQUEST_MESSAGE = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGOUT: "USERS_LOGOUT",
  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  DELETE_REQUEST: "USERS_DELETE_REQUEST",
};
const ERROR_MESSAGE = {
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",
};

const ALERT_MESSAGE = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
};

const ROLE_ID_MAPPING = {
  COMPANY: 1,
  SUPER_ADMIN: 2,
  SUPPLIER: 3,
  GROUP_ADMIN: 4,
  SUB_ADMIN: 7,
  INTERNAL_AUDITOR: 9,
  EXTERNAL_AUDITOR: 10,
  COMPANY_DATA_ADMIN: 12,
  SUPPLIER_DATA_ADMIN: 32,
};

const ROLE_ID_MAPPING_NUMBER_BASED = {
  1: "COMPANY",
  2: "SUPER_ADMIN",
  3: "SUPPLIER",
  4: "GROUP_ADMIN",
  7: "SUB_ADMIN",
  9: "INTERNAL_AUDITOR",
  10: "EXTERNAL_AUDITOR",
  12: "COMPANY_DATA_ADMIN",
  32: "SUPPLIER_DATA_ADMIN",
};

const USER_TYPE_CODE_MAPPING = {
  COMPANY: "company",
  SUB_ADMIN: "company",
  COMPANY_DATA_ADMIN: "company",
  INTERNAL_AUDITOR: "company",
  EXTERNAL_AUDITOR: "company",
  SUPPLIER: "supplier",
  SUPPLIER_DATA_ADMIN: "supplier",
};

export {
  OLD_API_URL,
  API_URL,
  SUCCESS_MESSAGE,
  REQUEST_MESSAGE,
  ERROR_MESSAGE,
  ALERT_MESSAGE,
  ROLE_ID_MAPPING,
  USER_TYPE_CODE_MAPPING,
  ROLE_ID_MAPPING_NUMBER_BASED
};
