import React from "react";
import "./twobutton.css";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import image from "../../../img/Filter.png";
import { useState, useEffect } from "react";
import AssignQuestionModal from "./AssignQuestionModal";
import OperationalModal from "./operationalmodal";
import { useLocation } from "react-router-dom";
import ReassignQuestionModal from "./ReassignQuestionModal";

const TwoButtonComponent = ({
  onFilteredData,
  handleAssignedDetails,
  questionIds,
  financeObjct,
  menu,
  managementListValue,
  moduleName,
  moduleData,
}) => {
  const data="bottom"
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(moduleData);
  const location = useLocation();

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterData(value);
  };

  useEffect(() => {
    // Reset searchTerm when the URL changes
    setSearchTerm('');
  }, [location]);

  // Filter moduleData based on search term
  const filterData = (term) => {
    if (!term) {
      setFilteredData(moduleData);
      onFilteredData(moduleData); // Passing the entire data if no search term
      return;
    }

    const filtered = moduleData.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredData(filtered);
    onFilteredData(filtered); // Passing the filtered data
  };

  useEffect(() => {
    if(menu !=="audit"){
    filterData(searchTerm);


    }
  }, [moduleData]);

  const handleAssignClick = () => setShowAssignModal(true);
  const handleReassignClick = () => setShowReassignModal(true);
  const handleAssignClose = () => setShowAssignModal(false);
  const handleReassignClose = () => setShowReassignModal(false);
  return (
    <div
      className="d-flex justify-content-start w-100 bg-white"
      style={{ paddingLeft: "3%" , height:"14vh",padding:"2%",borderRadius:"10px",}}
    >
      <div className="conta">
        <div className="w-100" style={{paddingTop:"2%",}}>
          <Button
            variant="primary"
            className=" mr-2 cbn"
            style={{backgroundColor: "#3F88A5", marginBottom:"10%", color:"white",paddingRight:"10%",paddingLeft:"10%"}}
            onClick={handleAssignClick}
          >
            Assign 
          </Button>
        </div>
   
      </div>
      <div className="search-bar-wrapper">
        <InputGroup className="search-bar h-100">
          <InputGroup.Text id="basic-addon1">
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            placeholder="Search Questions"
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <div
        className="filter-icon"
        style={{
          border: "2px solid #3F88A5",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "3px",
          borderRadius: "10px",
          width: "5%",
        }}
      >
        <img
          src={image}
          alt="filter"
          style={{ width: "20px", height: "20px" }}
        />
      </div>

      <AssignQuestionModal
        handleAssignedDetails={handleAssignedDetails}
        questionIds={questionIds}
        financeObjct={financeObjct}
        managementListValue={managementListValue}
        data={data}
        moduleName={moduleName}
        moduleData={moduleData}
        showAssignModal={showAssignModal}
        handleAssignClose={handleAssignClose}
      />
      <ReassignQuestionModal
        handleAssignedDetails={handleAssignedDetails}
        moduleData={moduleData}
        questionIds={questionIds}
        financeObjct={financeObjct}
        managementListValue={managementListValue}
        moduleName={moduleName}
        showReassignModal={showReassignModal}
        handleReassignClose={handleReassignClose}
      />
    </div>
  );
};

export default TwoButtonComponent;
