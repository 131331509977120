import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentWaste from "./TopComponentWaste";
import WasteGenerated from "./WasteGenerated";
import WasteRecovered from "./WasteRecovered";
import WasteDisposed from "./WasteDisposed";
import WasteComparison from "./WasteComparison";
import TotalWasteGeneratedByLoc from "./TotalWasteGeneratedByLoc";
import WasteDisposedMulti from "./WwasteDisposedMultiLoc";
import WasteRecoveredMulti from "./WasteRecoveredMulti";
import WasteRecTarget from "./WasteRecTraget";
import WasteCostAnalysis from "./WasteCostAnalysis";

import WwasteCostAnalysisSingle from "./WasteCostAnalysisSingle";
import WasteRecoveredSingle from "./WasteRecoveredSingle";
import WasteDisposalChart from "./WasteDisposalChart";
import WasteGeneratedSingle from "./WaterStorageTrackingUnit.jsx";

const Waste = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {
  const location = 2;
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const [totalSumTwo, setTotalSumTwo] = useState(0);
  const [companyFramework, setCompanyFramework] = useState([]);
  const [totalSumThree, setTotalSumThree] = useState(0);
  
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
  
  const lastWeekActivity = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        const activityData = {
          "Total Waste Generated": {
            number: `${totalSum} MT`,
            questionId: [],
          },
          "Total Waste Disposed": {
            number: `${totalSumTwo} MT`,
            questionId: [],
          },
          "Total Waste Recovered": {
            number: `${totalSumThree} MT`,
            questionId: [],
          },
          message: "Good Evening, Sunil Kumar",
        };
        setLastWeekActivities(activityData);
      }
    } catch (error) {
      console.error('Error fetching last week activities:', error);
      // Handle the error as needed
    }
  };
  
  // Initialize states for different types of waste data
  const [matchedDataWaste, setMatchedDataWaste] = useState([]);
  const [wasteRecovered, setWasteRecovered] = useState([]);
  const [wasteDisposal, setWasteDisposal] = useState([]);
  
  const wasteSeries = [
    "Plastic",
    "E-Waste",
    "Biomedical",
    "Construction and demolition",
    "Battery",
    "Radioactive",
    "Other hazardous wastes",
    "Other non-hazardous wastes",
  ];
  
  const wasteSeriesTwo = [
    "Incineration",
    "Landfilling",
    "Other disposal operations",
  ];
  
  const recoverySeries = [
    "Re-cycled",
    "Re-used",
    "Other Recovery Options",
  ];
  
  useEffect(() => {
    // Safeguard against undefined graphData or timePeriods
    const newWaterType = graphData?.filter(item => item.title === "Waste Management") || [];
    const newWasteDischarge = graphData?.filter(item => item.title === "Waste Disposal") || [];
    const newWasteRecovery = graphData?.filter(item => item.title === "Waste Recovery") || [];
  
    // Update state with filtered data
    setWasteRecovered(newWasteRecovery);
    setWasteDisposal(newWasteDischarge);
    setMatchedDataWaste(newWaterType);
  
    const timePeriodsArray = Object.values(timePeriods || []);
  
    // Filter matched data based on time periods
    const newMatchedDataWaste = newWaterType.filter(item => 
      timePeriodsArray.includes(item.formDate)
    );
  
    const newMatchedDataWasteDischarge = newWasteDischarge.filter(item => 
      timePeriodsArray.includes(item.formDate)
    );
  
    const wasteRecovery = newWasteRecovery.filter(item => 
      timePeriodsArray.includes(item.formDate)
    );
  
    setWasteDisposal(newMatchedDataWasteDischarge);
    setMatchedDataWaste(newMatchedDataWaste);
    setWasteRecovered(wasteRecovery);
  
    // Calculate total sum for waste management
    const aggregated = wasteSeries.reduce((acc, wasteType) => {
      acc[wasteType] = 0; // Initialize each waste type with 0
      return acc;
    }, {});
  
    let sum = 0;
    newMatchedDataWaste.forEach(item => {
      const answers = item.answer?.[0] || [];
      answers.forEach((value, index) => {
        if (wasteSeries[index]) {
          const numericValue = value === "NA" || !value ? 0 : parseFloat(value);
          aggregated[wasteSeries[index]] += numericValue;
          sum += numericValue; // Add to total sum
        }
      });
    });
  
    setTotalSum(sum);
  
    // Calculate total sum for waste disposal
    const aggregatedTwo = wasteSeriesTwo.reduce((acc, disposalType) => {
      acc[disposalType] = 0; // Initialize each disposal type with 0
      return acc;
    }, {});
  
    newMatchedDataWasteDischarge.forEach(item => {
      const answers = item.answer?.[0] || [];
      answers.forEach((value, index) => {
        if (wasteSeriesTwo[index]) {
          const numericValue = value === "NA" || !value ? 0 : parseFloat(value);
          aggregatedTwo[wasteSeriesTwo[index]] += numericValue;
        }
      });
    });
  
    const seriesData = wasteSeriesTwo.map(disposalType => aggregatedTwo[disposalType] || 0);
    const totalSumTwo = seriesData.reduce((sum, value) => sum + value, 0);
    setTotalSumTwo(totalSumTwo);
  
    // Calculate total sum for waste recovery
    const aggregatedThree = recoverySeries.reduce((acc, recoveryType) => {
      acc[recoveryType] = 0; // Initialize each recovery type with 0
      return acc;
    }, {});
  
    wasteRecovery.forEach(item => {
      const answers = item.answer?.[0] || [];
      answers.forEach((value, index) => {
        if (recoverySeries[index]) {
          const numericValue = value === "NA" || !value ? 0 : parseFloat(value);
          aggregatedThree[recoverySeries[index]] += numericValue;
        }
      });
    });
  
    const totalSumThree = Object.values(aggregatedThree).reduce((sum, value) => sum + value, 0);
    setTotalSumThree(totalSumThree);
  
  }, [graphData, timePeriods]);
  
  useEffect(() => {
    lastWeekActivity();
  }, [totalSum, totalSumTwo, totalSumThree]);
  
  useEffect(() => {
    if (Array.isArray(frameworkValue) && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  return (
    <div className="progress-container">
      {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && <div className="topcompo">
        {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && lastWeekActivities && (
          <TopComponentWaste
            lastWeekActivities={lastWeekActivities}
            icons={icons}
          />
        )}
      </div>}

    

      {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) ?   <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "80vh", marginBottom: "3%" }}
          >
            <WasteGeneratedSingle
              matchedDataWaste={matchedDataWaste}
            />
          </div>

          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <WasteDisposalChart wasteDisposall={wasteDisposal}/>
            </div>
            <div className="secondhalfprogress">
              {/* <div style={{ height: "56%" , marginBottom:"2%"}}>
                <WwasteCostAnalysisSingle
                  consumption={5}
                  notGenerated={5}
                  maxConsumption={12}
                />
              </div> */}
              <div style={{ height: "50%" }}>
                <WasteRecoveredSingle
               wasteRecovered={wasteRecovered}
                />
              </div>
            </div>
          </div>
        </div> : (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TotalWasteGeneratedByLoc
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            {/* <div
          className="firsthalfprogressenergy"
          
        > */}
            <WasteRecoveredMulti
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />

            {/* </div> */}
            {/* <div className="secondhalfprogress" >
        <WasteRecoveredMulti/>
        </div> */}
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            {/* <div
          className="firsthalfprogressenergy"
          
        > */}
            <WasteDisposedMulti
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />

            {/* </div> */}
            {/* <div className="secondhalfprogress" >
        <WasteRecoveredMulti/>
        </div> */}
          </div>
          {/* <div className="d-flex flex-row flex-space-between"
      style={
        {
          height:"70vh"
        }
      }>
       <div
          className="firsthalfprogressenergy"
          
        >
          <WasteRecTarget/>

        </div>
        <div className="secondhalfprogress" >
          <WasteCostAnalysis/>
        </div>

      </div> */}
        </div>
      )}
    </div>
  );
};

export default Waste;
