import React, { useEffect, useState } from "react";
import {Container, Form, Row, Col } from "react-bootstrap";

const YesNoAnswer = ({ item }) => {
    console.log("Dipakverma",item)
  const [answerObject, setAnswerObject] = useState();




  const formatNote = (note) => {
    if (typeof note === "string") {
      // If note is an empty string, return an empty 2D array
      return note.trim() === "" ? [[""]] : [[note]];
    } else if (Array.isArray(note)) {
      // If note is a 1D array, wrap it inside another array to make it 2D
      return Array.isArray(note[0]) ? note : [note];
    } else {
      // Default to an empty 2D array if note is neither string nor array
      return [[""]];
    }
  };

  const note = formatNote(
    item?.matchingAuditors[0]?.auditDetail[0]?.answer?.note
  );


const renderDynamicInput = (detail) => {
    let parsedAnswer;
    console.log("Dipakverma",item)
    try {
        console.log("Dipakverma")
      parsedAnswer = JSON.parse(
       
        item.matchingAuditors[0]?.auditDetail[0].answer.answer
      );
      console.log("Dipakverma",parsedAnswer)
    } catch (error) {
        console.log("Dipakverma")
      throw error; // Rethrow to catch in the outer block
    }

    switch (detail?.option_type) {
      case "attibutes":
        return (
          <Form.Group
            controlId={`attributes_${detail.detail_id}`}
            key={detail.detail_id}
          >
            <Form.Label className="custom-label">Attributes</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              value={detail.option || ""}
              readOnly
              style={{ backgroundColor: "#BFD7E0" }}
              //   onDoubleClick={() => handleDoubleClick(detail.option)}
            />
          </Form.Group>
        );
      case "weblink":
        return (
          <Form.Group
            controlId={`weblink_${detail.detail_id}`}
            key={detail.detail_id}
          >
            <Form.Label className="custom-label">{detail?.option}</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              value={answerObject[detail?.option_type]}
              //   onDoubleClick={() =>
              //     handleDoubleClick(answerObject[detail?.option_type])
              //   }
              readOnly
              style={{ backgroundColor: "#BFD7E0" }}
            />
          </Form.Group>
        );
      default:
        return (
          <Form.Group
            controlId={`details_${detail?.detail_id}`}
            key={detail.detail_id}
          >
            <Form.Label className="custom-label">{detail.option}</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              value={parsedAnswer[detail?.option_type]}
              //   onDoubleClick={() =>
              //     handleDoubleClick(answerObject[detail?.option_type])
              //   }
              readOnly
              style={{ backgroundColor: "#BFD7E0" }}
            />
          </Form.Group>
        );
    }
  };
 
// useEffect(()=>{


    const notApplicable =
    item.matchingAuditors[0]?.auditDetail[0]?.answer.notApplicable;
  
    if (notApplicable) {
      return (
        <Container className="">
            <Form.Group controlId={`form`}>
                <Form.Label>Response</Form.Label>
                <Form.Control
                  style={{ backgroundColor: "#BFD7E0" }}
                  type="text"
                  as="textarea"
                  readOnly
                  value={
                   "Not Applicable"
                  }
                />
              </Form.Group>
        </Container>
      );
    }
// },[answerObject])
  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      ></div>
      <Row>
        {item?.question?.details
          ?.slice()
          .reverse()

          .map((detail) => {
            return (
              <Col md={12} style={{ marginTop: "10px" }}>
                {renderDynamicInput(detail)}
              </Col>
            );
          })}
      </Row>
      <Row>
      {note && (
        <>
          <Row className="mb-1">
            <Col xs={12}>
              <Form.Group controlId="formNoteLabel">
                <Form.Label
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                  }}
                >
                  Note
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group controlId="formNote">
                <Form.Control
                  as="textarea"
                  readOnly
         
                  value={note}
                  style={{
                    backgroundColor: "#BFD7E0",
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      </Row>
    </Form>
  );
};

export default YesNoAnswer;
