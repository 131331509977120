import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { apiCall } from "../../_services/apiCall";
import config from "../../config/config";
import { Modal, Dropdown, Button,Table, Row,Col,Form } from "react-bootstrap";

const Unit = () => {
    const location = useLocation();
    const [questionData, setQuestionData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [editingQuestionId, setEditingQuestionId] = useState(null);
    const [editUnits, setEditUnits] = useState([]);
    const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [newQuestionTitle, setNewQuestionTitle] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [deleteQuestionId, setDeleteQuestionId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [editingQuestionTitle, setEditingQuestionTitle] = useState("");
    const [titleError, setTitleError] = useState("");
  
    const handleEditQuestionTitle = (questionId, currentTitle) => {
      setEditId(questionId);
      setEditingQuestionTitle(currentTitle);
      setShowEditTitleModal(true);
    };
  
    const handleSaveEditTitle = () => {
      if (editId) {
        updateEditTitle(editingQuestionTitle);
  
        setShowEditTitleModal(false);
      }
    };
  
    const [totalUnits, setTotalUnits] = useState();
  
    const handleCloseModal = () => {
      setSelectedUnits([]);
      setShowModal(false);
  
  
    }
    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);
  
    const handleDeleteQuestion = () => {
      if (deleteQuestionId) {
        deleteUnitBasedQuestion();
        setShowDeleteModal(false);
      }
    };
  
    const handleShowEditModal = (questionId, unitMappingIds) => {
      setEditingQuestionId(questionId);
      if (totalUnits && totalUnits.length) {
        const selectedUnits = totalUnits.map((unit) => ({
          ...unit,
          isChecked:
            unitMappingIds && unitMappingIds.length
              ? unitMappingIds.includes(unit.id)
              : false,
        }));
        setEditUnits(selectedUnits);
        setShowEditModal(true);
      }
    };
  
    const handleSaveEdit = () => {
      if (editingQuestionId) {
        const selectedIds = editUnits
          .filter((unit) => unit.isChecked)
          .map((unit) => unit.id);
        console.log(
          "Saving units for question ID:",
          editingQuestionId,
          "Selected IDs:",
          selectedIds
        );
        updateUnitBasedQuestion(selectedIds);
  
        // Call your API to update the question's units
        // Example API call
        // api.updateQuestionUnits(editingQuestionId, selectedIds).then(() => {
        //   // handle success, e.g., refresh data
        // }).catch((error) => {
        //   // handle error
        // });
        setShowEditModal(false);
      }
    };
  
    const handleCheckboxChange = (unitId) => {
      setEditUnits((prevUnits) =>
        prevUnits.map((unit) =>
          unit.id === unitId ? { ...unit, isChecked: !unit.isChecked } : unit
        )
      );
    };
  
    const updateEditTitle = async (title) => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}updateUnitBasedQuestion`,
        {},
        {
          id: editId,
          title: title,
        },
        "POST"
      );
      if (isSuccess) {
        getUnitBasedQuestion();
      }
    };
  
    const updateUnitBasedQuestion = async (selectedIds) => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}updateUnitBasedQuestion`,
        {},
        {
          id: editingQuestionId,
          unit_mapping_ids: selectedIds,
        },
        "POST"
      );
      if (isSuccess) {
        getUnitBasedQuestion();
      }
    };
  
    const deleteUnitBasedQuestion = async () => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}deleteUnitBasedQuestion`,
        {},
        {
          id: deleteQuestionId,
        },
        "POST"
      );
      if (isSuccess) {
        getUnitBasedQuestion();
      }
    };
  
    const handleAddQuestion = () => {
      // Handle the addition of the new question (e.g., save to state or make an API call)
      console.log("New Question Title:", newQuestionTitle);
      createUnitBasedQuestion(newQuestionTitle);
      // Clear the input and close the modal
      setNewQuestionTitle("");
      handleCloseAddModal();
    };
  
    const handleShowDeleteModal = (questionId) => {
      setDeleteQuestionId(questionId);
      setShowDeleteModal(true);
    };
  
    const getUnit = async () => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getUnit`,
        {},
        {
          type: "ALL",
        },
        "GET"
      );
      if (isSuccess) {
        console.log(data);
        setTotalUnits(data.data);
      }
    };
  
    const handleSvgClick = (unitMappingIds) => {
      if (unitMappingIds) {
        const selectedUnits = totalUnits.filter((unit) =>
          unitMappingIds.includes(unit.id)
        );
  
        setSelectedUnits(selectedUnits);
        setShowModal(true);
      } else {
        const selectedUNits = [];
        setSelectedUnits(selectedUnits);
        setShowModal(true);
      }
    };
  
    const createUnitBasedQuestion = async (title) => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}createUnitBasedQuestion`,
        {},
        {
          title: title,
        },
        "POST"
      );
      if (isSuccess) {
        getUnitBasedQuestion();
      }
    };
  
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
  
    const getUnitBasedQuestion = async () => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getUnitBasedQuestion`,
        {},
        {
          type: "ALL",
        },
        "GET"
      );
      if (isSuccess) {
        console.log(data);
        setQuestionData(data?.data);
      }
    };
  
    const isTitleDuplicate = (title) => {
      return questionData.some((question) => question.title === title);
    };
  
    useEffect(() => {
      getUnitBasedQuestion();
      getUnit();
    }, []);
  return (
    <div style={{width:"100%"}}>
         <div className="color_div_on framwork_2 pt-0">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <Button variant="primary" onClick={handleShowAddModal}>
                Add Question
              </Button>
            </div>

            <div className="business_detail">
              <div className="table-responsive">
                <Row>
                  <Col md={12}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Question Title</th>
                          <th>Unit</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {questionData && questionData.length > 0 ? (
                          questionData.reverse().map((question, index) => (
                            <tr key={question.id}>
                              <td>{index + 1}</td>
                              <td>{question?.title}</td>
                              <td>
                                <div
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleSvgClick(question.unit_mapping_ids)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ height: "100%", width: "100%" }}
                                    viewBox="0 0 576 512"
                                  >
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                  </svg>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignContent: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "80%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleShowEditModal(
                                        question.id,
                                        question.unit_mapping_ids
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ width: "100%", height: "100%" }}
                                      viewBox="0 0 448 512"
                                    >
                                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                                    </svg>
                                  </div>
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleShowDeleteModal(question.id)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ width: "100%", height: "100%" }}
                                      viewBox="0 0 448 512"
                                    >
                                      <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                    </svg>
                                  </div>
                                  <div
                                    style={{ width: "20px", height: "20px" }}
                                    onClick={() =>
                                      handleEditQuestionTitle(
                                        question.id,
                                        question.title
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ width: "100%", height: "100%" }}
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" />
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" style={{ textAlign: "center" }}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    <Modal
                      show={showEditModal}
                      onHide={() => setShowEditModal(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Question Units</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Conversion Factor</th>
                              <th>Target Unit</th>
                              <th>Select</th>
                            </tr>
                          </thead>
                          <tbody>
                            {editUnits &&
                              editUnits.map((unit) => (
                                <tr key={unit.id}>
                                  <td>{unit.title}</td>
                                  <td>{unit.conversion_factor}</td>
                                  <td>{unit.target_unit}</td>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={`unit-${unit.id}`}
                                      checked={unit.isChecked}
                                      onChange={() =>
                                        handleCheckboxChange(unit.id)
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowEditModal(false)}
                        >
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveEdit}>
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={showEditTitleModal}
                      onHide={() => setShowEditTitleModal(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Question Title</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="editQuestionTitle">
                            <Form.Label>Question Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter new question title"
                              value={editingQuestionTitle}
                              onChange={(e) => {
                                const value = e.target.value;
                                setEditingQuestionTitle(value);
                                if(!value){
                                  setTitleError("Please enter something");
                                } else if(isTitleDuplicate(value)){
                                  setTitleError("Title already exists");
                                }
                                else{
                                  setTitleError("");
                                }
                                
                              }}
                            />
                          </Form.Group>
                          {titleError && (
                            <Form.Text className="text-danger">
                              {titleError}
                            </Form.Text>
                          )}
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowEditTitleModal(false)}
                        >
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveEditTitle} disabled={!editingQuestionTitle || titleError}>
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Unit Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {" "}
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>Title</th>
                              <th>Conversion Factor</th>
                              <th>Target Unit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedUnits &&
                              selectedUnits.map((unit, index) => (
                                <tr key={unit?.id}>
                                  <td>{index + 1}</td>
                                  <td>{unit?.title}</td>
                                  <td>{unit?.conversion_factor}</td>
                                  <td>{unit?.target_unit}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this question?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteQuestion}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewQuestionTitle">
              <Form.Label>Question Title</Form.Label>
              <Form.Control
                type="text"
                value={newQuestionTitle}
                onChange={(e) => {
                  const value = e.target.value;
                  setNewQuestionTitle(value);
                  if (!value) {
                    setTitleError("Please enter something");
                  } else if (isTitleDuplicate(value)) {
                    setTitleError("Title already exists");
                  } else {
                    setTitleError("");
                  }
                }}
                placeholder="Enter question title"
              />
            </Form.Group>
            {titleError && (
              <Form.Text style={{ color: "red" }}>{titleError}</Form.Text>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleAddQuestion}
            disabled={!newQuestionTitle || titleError}
          >
            Add Question
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
   
  )
}

export default Unit