import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentDiversity from "./TopComponentDiversity";
import SecondTopComponent from "./SecondTopComponent";
import DifferentlyAbled from "./DifferentlyAbled";
import WorkersIncludingDiAble from "./WorkersIncludingDiAble";
import TurnOverRate from "./TurnOverRate";
import EmployeeDifAbled from "./EmployeeDifAbled";
import RepresentationOfWomen from "./RepresenationWomen";
import PercentageOfPeopleReceivingMoreThanMinWages from "./PercentageOfPeopleReceivingMoreThanMinWages";
import DetailsOfMinimumWagesPaid from "./DetailsOfMinimumWagesPaid";
import PercentageOfWorkersReceivingMoreThanMinimumWages from "./PercentageOfWorkers";
import DetailsOfMinWorkers from "./DetailsOfMinWorker";
import EmployeeChart from "./EmployeesChart";
import TotalDifAbled from "./TotalDifAbled";
import TotalDifAbledWo from "./TotalDifAbWo";
import TotalDifWorkIncAb from "./TotalWorkIncDifAb";
import LeadershipRolesChart from "./LeadershipRolesChart";
import GenderDistributionInLeadership from "./GenderDistriInLeader";
import PromotionRatesByGender from "./PromotionRatesByGender";
import GenderPayGapAnalysis from "./GenderPayGapAnalysis";
import PercentageOfNewHires from "./PercentageOfNewHires";
import TotalEmplInclDifSingle from "./TotalEmplInclDifSingle";
import TotalDifAbledEmpSingle from "./TotalDifAbledEmpSingle";
import WomenMinoritiesChart from "./WomenMinorities";
import TotalWorkersIncDif from "./TotalWorkersIncDif";
import TurnoverRateChart from "./TurnOverSingle";
import TotalDiffAbleWorkerSingle from "./TotalDifAblWorkerSingle";
import PromotionRatesByGenderGap from "./PromotionRatesByGenderGap";
import PercentageOfNewHireSingle from "./PercentageOfNewHireSingle";
import GenderPayGapAnalysisSingle from "./GenderPayGapAnalysisSingle";

const Diversity = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {
  const location = 2;
const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
const [employeeData, setEmployeeData] = useState({
  males: 0,
  females: 0,
  others: 0,
  maxConsumption: 0,
});
const icons = {
  done: done,
  updated: updated,
  due: due,
  pending: defaulted,
};

const lastWeekActivity = async () => {
  const { isSuccess, data } = await apiCall(
    `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
    {},
    {},
    "GET"
  );
  if (isSuccess) {
    const data = {
      "Total Employees": {
        number: `${employeeData.maxConsumption}`,
        questionId: [],
      },
      "Total Males": {
        number: `${employeeData.males}`,
        questionId: [],
      },
      "Total Females": {
        number: `${employeeData.females}`,
        questionId: [],
      },
      "Total Others": {
        number: `${employeeData.others}`,
        questionId: [],
      },
      message: "Good Evening, Sunil Kumar",
    };
    setLastWeekAcitivities(data);
  }
};

const [empInclDif, setEmpInclDif] = useState([]);
const [workInclDif, setWorkInclDif] = useState([]);
const [empDif, setEmpDif] = useState([]);
const [workDif, setWorkDif] = useState([]);
const [diversity, setDiversity] = useState([]);
const [companyFramework, setCompanyFramework] = useState(0);

useEffect(() => {
  // Update states based on filtered graphData and timePeriods
  const empDiffIncl = graphData?.filter(item => item.title === "Gender Diversity (including differently abled employees)") || [];
  const workDiffIncl = graphData?.filter(item => item.title === "Gender Diversity (including differently abled workers)") || [];
  const empDiff = graphData?.filter(item => item.title === "Gender Diversity (diffferently abled employees)") || [];
  const workDiff = graphData?.filter(item => item.title === "Gender Diversity (diffferently abled workers)") || [];
  const diver = graphData?.filter(item => item.title === "Diversity in Leadership") || [];

  const matchedDiffIncl = empDiffIncl.filter(item => {
    return Object.values(timePeriods || {}).includes(item?.formDate);
  }) || [];

  const matchedWorkDiffIncl = workDiffIncl.filter(item => {
    return Object.values(timePeriods || {}).includes(item?.formDate);
  }) || [];

  const matchedEmpDiff = empDiff.filter(item => {
    return Object.values(timePeriods || {}).includes(item?.formDate);
  }) || [];

  const matchedWorkDiff = workDiff.filter(item => {
    return Object.values(timePeriods || {}).includes(item?.formDate);
  }) || [];

  setEmpInclDif(matchedDiffIncl);
  setWorkInclDif(matchedWorkDiffIncl);
  setEmpDif(matchedEmpDiff);
  setWorkDif(matchedWorkDiff);
  setDiversity(diver);

  let totalMales = 0;
  let totalFemales = 0;
  let totalOthers = 0;

  matchedDiffIncl.forEach((item) => {
    const answers = item.answer || [[], []];
    
    // Add values from answers[0]
    if (answers[0]?.length === 3) {
      totalMales += parseInt(answers[0][0], 10) || 0;
      totalFemales += parseInt(answers[0][1], 10) || 0;
      totalOthers += parseInt(answers[0][2], 10) || 0;
    }

    // Add values from answers[1]
    if (answers[1]?.length === 3) {
      totalMales += parseInt(answers[1][0], 10) || 0;
      totalFemales += parseInt(answers[1][1], 10) || 0;
      totalOthers += parseInt(answers[1][2], 10) || 0;
    }
  });

  // Set the maximum consumption to the sum of all categories
  const maxConsumption = totalMales + totalFemales + totalOthers;

  setEmployeeData({
    males: totalMales,
    females: totalFemales,
    others: totalOthers,
    maxConsumption: maxConsumption,
  });

}, [graphData, timePeriods]);

useEffect(() => {
  lastWeekActivity();
}, [employeeData]);

useEffect(() => {
  if (frameworkValue?.length) {
    const frameworkId = frameworkValue.map((value) => value?.id).filter(id => id !== undefined);
    setCompanyFramework(frameworkId);
  }
}, [frameworkValue]);

  return (
    <div className="progress-container">
      {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentDiversity
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>}

      {/* <div className="topcompo">
        {lastWeekAcitivities && (
          <SecondTopComponent
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div> */}
   
      {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1)?  <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "40vh", marginBottom: "3%" }}
          >
            <TotalEmplInclDifSingle empInclDif={empInclDif} />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
             <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <TotalDifAbledEmpSingle empDif={empDif}/>
              
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              {/* <WomenMinoritiesChart/> */}
              <TotalDiffAbleWorkerSingle workDif={workDif}/>
        
            </div>
            
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "40vh", marginBottom: "3%" }}
          >
            <TotalWorkersIncDif workInclDif={workInclDif}/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
           <RepresentationOfWomen diversity={diversity}/>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
            <TurnoverRateChart/>
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <TotalDiffAbleWorkerSingle/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <PromotionRatesByGenderGap title={"Promotion Rates By Gender and Age"} />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
             <PercentageOfNewHireSingle consumption={10} notGenerated={20} maxConsumption={40}/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
           <GenderPayGapAnalysisSingle consumption={5} maxConsumption={20} notGenerated={2}/>
          </div> */}
        </div> : (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={false}
                type="EMPLOYEE"
              />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={true}
                type="EMPLOYEE"
              />
            </div>
          </div>

          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%", height:"100%"}}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={true}
                type="WORKER"
              />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%", height:"100%"}}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={false}
                type="EMPLOYEE"
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
           
              <LeadershipRolesChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
              />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <GenderDistributionInLeadership
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
              />
            </div>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TurnOverRate></TurnOverRate>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <PromotionRatesByGender />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <PercentageOfNewHires />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <GenderPayGapAnalysis />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Diversity;
