import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEmission from "../Emission/TopComponentEmission";
import WaterWithdrawal from "./WaterWithdrawal";
import TopComponentWater from "./TopComponentWater";
import WaterComparison from "./WaterComparison";
import WaterRevenueIntensity from "./WaterRevenueIntensity";
import WaterStorageTracking from "./WaterUnit";
import NegativeWaterConsumption from "./NegativeWaterConsumption";
import WaterStorageTrackingUnit from "./WwaterStorageTracking";
import WwaterWithdrawalSingle from "./WaterWithdrawalSingle";
import CompareWaterConsumption from "./CompareWaterConsumption";
import WaterRevenueIntensitySingle from "./WaterRevenueIntensitySingle";
import WwaterStorageTrackingSingle from "./WaterStorageTrackingSingle";
import WaterSustainable from "./WaterSustainable";
import NegativeWaterSingle from "./NegativeWaterConsumptionSinfle";

const Water = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {


const [lastWeekActivities, setLastWeekActivities] = useState({});
const [companyFramework, setCompanyFramework] = useState([]);

const activeGraph = 2;
const icons = {
  0: done,
  1: updated,
  2: due,
  3: defaulted,
};

const [timePeriodValues, setTimePeriodValues] = useState([]);
const [waterType, setWaterType] = useState([]);
const [matchedDataWater, setMatchedDataWater] = useState([]);
const [totalConsumption, setTotalConsumption] = useState(0);
const [totalConsumptionTwo, setTotalConsumptionTwo] = useState(0);

const [waterDischarge, setWaterDischarge] = useState([]);
const [matchedWaterDis, setMatchedWaterDis] = useState([]);

useEffect(() => {
  const waterSeries = [
    "Surface Water",
    "Ground Water",
    "Third Party Water",
    "Municipal Water",
    "Seawater / Desalinated Water",
    "Others",
  ];

  const waterSeriesTwo = [
    "To Surface Water",
    "To Ground Water",
    "To Sea Water",
    "Sent to other parties",
    "Others",
  ];

  // Safeguard against undefined graphData or timePeriods
  const timePeriodsArray = Object.values(timePeriods || []);
  setTimePeriodValues(timePeriodsArray);

  const newWaterType = graphData?.filter(item => item.title === "Water withdrawal") || [];
  const newWaterDischarge = graphData?.filter(item => item.title === "Details of Water Discharge") || [];
  setWaterType(newWaterType);
  setWaterDischarge(newWaterDischarge);

  // Safeguard against undefined timePeriodsArray
  const newMatchedDataWater = newWaterType.filter(item =>
    timePeriodsArray.includes(item.formDate)
  );
  const newMatchedDataWaterDischarge = newWaterDischarge.filter(item =>
    timePeriodsArray.includes(item.formDate)
  );

  setMatchedWaterDis(newMatchedDataWaterDischarge);
  setMatchedDataWater(newMatchedDataWater);

  if (!Array.isArray(newMatchedDataWater) || newMatchedDataWater.length === 0) {
    setTotalConsumption(0);
    return;
  }

  const aggregatedValues = waterSeries.map((_, index) =>
    newMatchedDataWater.reduce((acc, obj) => {
      const value = obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
      return acc + (value === "NA" || !value ? 0 : parseFloat(value || 0));
    }, 0)
  );

  const total = aggregatedValues.reduce((sum, value) => sum + value, 0);
  setTotalConsumption(total);

  const aggregated = waterSeriesTwo.map((_, index) =>
    newMatchedDataWaterDischarge.reduce((acc, obj) => {
      const value = obj.answer && Array.isArray(obj.answer) && obj.answer[index] ?
        obj.answer[index].slice(0, 2).reduce((sum, val) => sum + (val === "NA" || !val ? 0 : parseFloat(val)), 0) : 0;
      return acc + value;
    }, 0)
  );

  const totalTwo = aggregated.reduce((sum, value) => sum + value, 0);
  setTotalConsumptionTwo(totalTwo);
}, [graphData, timePeriods]);

const lastWeekActivity = async () => {
  try {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const activityData = {
        "Total Water Withdrawal": {
          number: `${totalConsumption}`,
          questionId: [],
        },
        "Total Water Disposed": {
          number: `${totalConsumptionTwo}`,
          questionId: [],
        },
        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekActivities(activityData);
    }
  } catch (error) {
    console.error('Error fetching last week activities:', error);
    // Handle the error as needed
  }
};

useEffect(() => {
  lastWeekActivity();
}, [totalConsumption, totalConsumptionTwo]);

useEffect(() => {
  if (Array.isArray(frameworkValue) && frameworkValue.length) {
    const frameworkId = frameworkValue.map((value) => value.id);
    setCompanyFramework(frameworkId);
  }
}, [frameworkValue]);

  return (
    <div className="progress-container">
     {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && <div className="topcompo">
      {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && lastWeekActivities && (
        <TopComponentWater
          lastWeekActivities={lastWeekActivities}
          icons={icons}
        />
      )}
    </div>}
    
      {/* {
      locationOption && locationOption.length === 1 && 
      <div className='d-flex flex-column flex-space-between'>
        <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
         <div className="firsthalfprogressenergy" style={{width:"50%"}}>
            <LocationWiseEmission/>
            <WaterWithdrawal/>

        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
            <WaterComparison/>
        </div>

      </div>
      </div>
    } */}
      {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1)? <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <WwaterWithdrawalSingle timePeriodValues={timePeriodValues} waterType={matchedDataWater} title={"Water Withdrawal"} />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <div style={{ height: "100%", marginBottom:"2%" }}>
                <CompareWaterConsumption
                matchedWaterDis={matchedWaterDis}
                  consumption={5}
                  notGenerated={7}
                  maxConsumption={20}
                />
              </div>
              
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "30vh", marginBottom: "3%" }}
          >
           <WwaterStorageTrackingSingle consumption={10} notGenerated={20} maxConsumption={40}/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "45vh", marginBottom: "3%" }}
          >
            <div className="secondhalfprogress">
              <WaterSustainable/>
             
            </div>
            <div className="firsthalfprogressenergy" style={{marginLeft:"5%"}}>
            <NegativeWaterSingle consumption={10} notGenerated={20} maxConsumption={40}/>
            </div>

          </div> */}
        </div> :(
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            {/* <div className="firsthalfprogressenergy" style={{width:"50%"}}> */}
            {/* <LocationWiseEmission/> */}
            {/* <WaterWithdrawal locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} /> */}

            {/* </div> */}
            {/* <div className="secondhalfprogress" style={{width:"50%"}}> */}
            <WaterComparison
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
            {/* </div> */}
          </div>

          {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        <div className="firsthalfprogressenergy" style={{width:"50%"}}>
            <WaterRevenueIntensity/>
          
        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
        <WaterStorageTracking/>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        <div className="firsthalfprogressenergy" style={{width:"50%"}}>
        <WaterStorageTracking/>
       
          
        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
          <NegativeWaterConsumption/>
        </div>
      </div> */}
        </div>
      )}
    </div>
  );
};

export default Water;
