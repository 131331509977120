import React from "react";
import { Tooltip, OverlayTrigger,Image } from "react-bootstrap";

import reject from "../../../img/rejected.png";
import accept from "../../../img/accept.png";
import complain from "../../../img/complain.png";



const StatusIcon = ({ status }) => {
    let icon;
    let color;

    switch (status) {
      case "ANSWERED":
        icon = <Image src={complain} />;
        color = "yellow";
        break;
      case "ACCEPTED":
        icon = <Image src={accept} />;
        color = "#43AC70";
        break;
      case "REJECTED":
        icon = <Image src={reject} />;
        color = "#F2684A";
        break;
      default:
        icon = null;
    }

    return (
      <div
        style={{
          color,
          fontSize: "10px",
          height: "30px",
          width: "40px",
          display: "flex",
          marginLeft: "125%",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </div>
    );
  };

const StatusWithTooltip = ({ status }) => {
  return (
    <div
      style={{
        flex: "0 0 15%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {status && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${status}`}>
              {status} {/* This is the tooltip content */}
            </Tooltip>
          }
        >
          <div>
            <StatusIcon status={status} />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default StatusWithTooltip;
