/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { Link, NavLink } from "react-router-dom";
import config from "../../config/config.json";
import { FaBell } from "react-icons/fa";
import { FiBell } from "react-icons/fi";

import "./header.css";
import {
  getSubscriptionAuth,
  checkIsAuthenticated,
} from "../../utils/UniversalFunction";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import swal from "sweetalert";
import { apiCall } from "../../_services/apiCall";
import { ROLE_ID_MAPPING } from "../../_constants/constants";
import { transformRoleOrder } from "../../utils/UniversalFunction";
import { ROLE_ID_MAPPING_NUMBER_BASED } from "../../_constants/constants";
import NoNotification from "../../img/no-results.png";
import { Button, Modal } from "react-bootstrap";
const ProfilePics =
  "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
export default class header extends Component {
  static contextType = PermissionMenuContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      mode: null,
      isPopUpOpen: false,
      isLoaded: false,
      isDropdownOpen: false,
      usersData: [],
      notificationData: [],
      name: "",
      profilePic: null,
      firstName: "",
      lastName: "",
      checked: false,
      role: localStorage.getItem("role"),
      roles: transformRoleOrder(
        JSON.parse(localStorage.getItem("currentUser"))?.user_type_code
      ),
    };

    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.getNotificationToUser = this.getNotificationToUser.bind(this);
    this.routeChecking = this.routeChecking.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
    this.calculateTime = this.calculateTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SwitchUserRole = this.SwitchUserRole.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked: checked });
    if (this.state.checked) {
      this.context.setToggleUserRole("company");
    } else {
      this.context.setToggleUserRole("supplier");
    }
  }
  goToPreviousPath() {
    window.history.back();
  }
  handleDropdownToggle = () => {
    this.getNotificationToUser();
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    });
  };
  logout() {
    authenticationService.logout();
    history.push("/");
    localStorage.clear();
  }
  // getProfileData

  async routeChecking() {
    let data = await checkIsAuthenticated();
    let checkCondition = data.status === false;

    if (checkCondition) {
      localStorage.clear();
      return false;
    } else {
      const subscriptionAuth = getSubscriptionAuth("subscriptionAuth");
      // if (subscriptionAuth === false || subscriptionAuth === undefined) {
      //   window.location.href = "/#/subscription_plan";
      // }
      const currentUser = localStorage.getItem("role");
      if (currentUser === null) {
        window.location.href = "/";
      } else {
        if (currentUser === "super_admin" || currentUser === "sub_admin") {
          window.location.href = "/";
        }
      }
      return true;
    }
  }

  saveToContext = (payloadValue, typeValue) => {
    const { dispatch } = this.context;
    dispatch({
      type: typeValue,
      payload: payloadValue,
    });
  };

  async getNotificationToUser() {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getNotificationToUser`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({
        notificationData: data?.data,
      });

      // setFrameworks(data?.data?.reverse());
      // closeAddFrameworkPopup();
    }
  }
  async getProfileData(role_id) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
    };
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getProfileData?userId=${localStorage.getItem(
        "user_temp_id"
      )}${role_id ? `&role_id=${role_id}` : ""}`,
      {}
    );
    if (isSuccess) {
      localStorage.setItem("user_temp_id", JSON.stringify(data?.user?.id));
      localStorage.setItem(
        "role",
        JSON.stringify(data?.currentRole).replaceAll('"', "").toUpperCase()
      );
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          ...data?.user,
          is_head: data?.is_head,
          hasValidPlan: data?.hasValidPlan,
        })
      );
      localStorage.setItem("menu", JSON.stringify(data?.menu));
      localStorage.setItem("subscriptionAuth", JSON.stringify(200));

      const menu = JSON.parse(localStorage.getItem("menu"));
      this.saveToContext(menu, "Add_Menu");
      this.saveToContext(localStorage.getItem("role"), "Add_Role");
      // if (data.user[0]?.userCategory !== "Partner") {
      //   this.calculateTime(data.user[0]?.subscriptionExpiredAt);
      // }
      let beforeThis = data?.user?.profile_picture?.split(":")[0];
      console.log("profilePic>>>", data.user);
      this.setState({
        isLoaded: true,
        usersData: data.user,
        name: data.user?.register_company_name,
        profilePic:
          data?.user?.profile_picture && beforeThis != "https"
            ? ProfilePics
            : data?.user?.profile_picture,
        firstName: data?.user?.first_name,
        lastName: data?.user?.last_name,
        user_type: data?.user?.currentRole,
        roles: transformRoleOrder(data?.user?.user_type_code),
      });
    }
  }

  calculateTime(endDate) {
    let url = document.URL.split("/");
    if (url[3] !== "checkout") {
      if (endDate !== null) {
        let start = Date.now() / 1000;
        let end = endDate;
        let dd = start.toFixed(0);
        function getMinDiff(startDate, endDate) {
          let response = false;
          if (endDate < startDate) {
            response = true;
          }
          return response;
        }
        let diffMinutes = getMinDiff(dd, end);
        if (diffMinutes === true) {
          window.location.href = "/#/subscription_plan";
        }
      }
    }
  }

  async componentDidMount() {
    // this.getNotificationToUser();
    let getData = await this.routeChecking();
    let checkCondition = getData === false;
    if (checkCondition) {
      window.location.href = "/";
    }
    // else {
    //   this.getProfileData();
    // }
  }

  sidebarOpen() {
    const div = document.getElementById("sidebar-wrapper");
    const res = div.classList.contains("active-sidebar"); // true

    if (res) {
      document
        .getElementById("sidebar-wrapper")
        .classList.remove("active-sidebar");
      document.getElementById("sidebar-wrapper").style.display = "block";
      document.getElementById("sidebar-wrapper").style.zIndex = "3";
      setTimeout(() => {
        document.getElementById("sidebar-wrapper").style.marginLeft = "0rem";
      }, 200);
    } else {
      document
        .getElementById("sidebar-wrapper")
        .classList.add("active-sidebar");
      document.getElementById("sidebar-wrapper").style.marginLeft = "-15rem";
      setTimeout(() => {
        document.getElementById("sidebar-wrapper").style.display = "none";
      }, 200);
    }
  }

  SwitchUserRole = async (e) => {
    // let newRole = e.target.getAttribute("value").toUpperCase();
    let possibleRoles = JSON.parse(
      localStorage.getItem("currentUser")
    ).role_id.split(",");
    let newRole =
      localStorage.getItem("role") ===
      ROLE_ID_MAPPING_NUMBER_BASED[possibleRoles[0]]
        ? ROLE_ID_MAPPING_NUMBER_BASED[possibleRoles[1]]
        : ROLE_ID_MAPPING_NUMBER_BASED[possibleRoles[0]];
    this.setState({
      ...this.state,
      user_type: newRole,
      checked: !this.state.checked,
    });
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
    };

    let response = await this.getProfileData(ROLE_ID_MAPPING[newRole]);
    try {
      let value = this;
      swal({
        icon: "success",
        title: "Success",
        text: "User role switched successfully .",
        timer: 2000,
      });

      // localStorage.setItem("currentUser", JSON.stringify(response));
      // localStorage.setItem("menu", JSON.stringify(response?.data?.menu));
      // localStorage.setItem(
      //   "user_temp_id",
      //   JSON.stringify(response?.data?.user_id)
      // );
      // localStorage.setItem(
      //   "role",
      //   JSON.stringify(response?.data?.user_type_code)
      // );
      // localStorage.setItem("subscriptionAuth", JSON.stringify(200));

      //  const menu =JSON.parse(localStorage.getItem("menu"));
      // this.saveToContext(menu, "Add_Menu");
      // this.saveToContext(JSON.parse(localStorage.getItem("role")), "Add_Role");

      // this.props.history.push('/home');
      return (window.location.href = config.baseURL + "/#/home");
    } catch (error) {}
  };

  handlePopUp = (mode) => {
    this.setState({
      isPopUpOpen: true,
      mode: mode,
    });
  };
  handleClose = () => {
    this.setState({
      isPopUpOpen: false,
    });
  };
  render() {
    const { isDropdownOpen, notificationData } = this.state;
    const { state } = this.context;
    let fullUrl = window.location.href.split("/");
    let profilePicture;
    let FirstName;
    let LastName;
    let Position;
    if (localStorage.getItem("currentUser")) {
      profilePicture = JSON.parse(
        localStorage.getItem("currentUser")
      ).profile_picture;
      FirstName = JSON.parse(localStorage.getItem("currentUser")).first_name;
      LastName = JSON.parse(localStorage.getItem("currentUser")).last_name;
      Position = JSON.parse(localStorage.getItem("currentUser")).position;
    }

    let urr = "";
    if (fullUrl[3] !== "sub_accounts") {
      urr = fullUrl.pop().toUpperCase();
    } else {
      urr = fullUrl[3];
    }
    let finalResult = urr.replaceAll("_", " ");
    if (finalResult === "SUPPLIER FAST") {
      finalResult = "SUPPLIER";
    }
    if (finalResult === "SUSTAINABLE") {
      finalResult = "SUSTAINABLE DEVELOPMENT GOALS";
    }

    if (fullUrl[3] === "checkout") {
      finalResult = "Success";
    }

    let uri = window.location.pathname.split("/");
    let path = uri[1];

    if (path === "Leadership_Governance") {
      finalResult = "Leadership & Governance";
    }
    if (path === "Cyber_Digital") {
      finalResult = "Cyber & Digital";
    }
    if (path === "Business_Model_Innovation") {
      finalResult = "Business Model & Innovation";
    }

    if (path === "suppliers_fast") {
      finalResult = "Suppliers";
    }

    if (finalResult === "HOME") {
      finalResult = "Dashboard";
    }
    return (
      <div style={{ position: "sticky", top: "0", zIndex: 99,width:"100%",boxSizing:"border-box"}}>
        <div className="d-flex" id="wrapper" style={{width:"100%"}}>
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper"  style={{width:"100%"}}>
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg  border-bottom navclassName background topbar_esg"  style={{width:"100%"}}>
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord " id="sidebarToggle">
                  <NavLink
                    to="#"
                    className="sidebar_bar"
                    onClick={this.sidebarOpen}
                  >
                    <i className="fas fa-bars"></i>
                  </NavLink>
                  <h4 className="back_quninti back_quninti_2 m-0">
                    <a className="back_text">
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>
                {/* <App/> */}
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav align-items-center ms-auto gap-3">
                    {/* {JSON.parse(
                      localStorage.getItem("currentUser")
                    ).role_id.split(",").length > 1 && ( */}
                    {/* <li>
                        <div className="toggle__btn__styling">
                          <label className="toggle__label">
                            <span className="text-capitalize">
                              {localStorage.getItem("role").replaceAll('"', "")}
                            </span>
                            <Switch
                              onChange={this.SwitchUserRole}
                              checked={this.state.checked}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onColor="#209ed1"
                              offColor="#209ed1"
                            />
                          </label>
                        </div>
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span className="navbar-toggler-icon">
                            <i className="fas fa-bars"></i>
                          </span>
                        </button>
                      </li> */}
                    {/* )} */}
                    {/* <li className="nav-item">
                      {this.state.roles.length === 1 && (
                        <span
                          className="new_button_style"
                        >
                          {this.state.roles[0].role}
                        </span>
                      )}
                      {this.state.roles.length > 1 && (
                        <Dropdown
                        >
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            style={{
                              background: "#209ed1",
                              borderColor: "#209ed1",
                              padding: "10px",
                              cursor: "pointer",
                              border: "none",
                            }}
                          >
                            {localStorage.getItem("role").replaceAll('"','')}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.roles.map((role) => (
                              <Dropdown.Item
                                as="button"
                                eventKey={role.role}
                                value={role.role}
                                onClick={this.SwitchUserRole}
                                disabled={
                                  localStorage.getItem("role").toLowerCase() ===
                                  role.role.toLowerCase()
                                    ? true
                                    : false
                                }
                              >
                                {role.role.toUpperCase().replaceAll('"','')}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      <div className="has-search">
                      </div>
                    </li>  */}

                    <li className="nav-item dropdown text_down">
                      <a
                        className="nav-link home_drop"
                        // id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        onClick={this.handleDropdownToggle}
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen ? "true" : "false"}
                      >
                        <div className="elly">
                          <NavLink to="#">
                            <FiBell className="ellly"/>
                          </NavLink>
                          {/* <span className="badge">5</span> */}
                          {/* <span className="badge">5</span> */}
                        </div>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        // aria-labelledby="navbarDropdown"
                        style={{ width: 500 }}
                      >
                        <div className="notification_section">
                          <div className="hstack border-bottom justify-content-between">
                            <h5>Notification</h5>
                            <div className="hstack gap-3">
                              <a onClick={() => this.handlePopUp("MARKREAD")}>
                                Mark all as read
                              </a>
                              <a onClick={() => this.handlePopUp("CLEARALL")}>
                                Clear all
                              </a>
                              <i className="fas fa-arrow-right"></i>
                            </div>
                          </div>
                          <div className="notification_body">
                            {notificationData &&
                            notificationData?.length > 0 ? (
                              notificationData.map((item, key) => (
                                <div className="d-flex gap-4 border-bottom">
                                  <i className="fas fa-user-tie"></i>
                                  <div className="my-2">
                                    <h4>RIU</h4>
                                    <Link
                                      to={{
                                        pathname: "/sector_questions",
                                        state: {
                                          questionIds: item?.questionIds,
                                        },
                                      }}
                                    >
                                      <p>{item?.massage}</p>
                                    </Link>
                                    <a href="#" className="p-0">
                                      Mark as read
                                    </a>
                                    {/* <a href="#" className="p-0 mx-2">Mark as unread</a> */}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <img
                                src={NoNotification}
                                alt="No Notification Here..."
                                className="w-100 h-100"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img
                          src={profilePicture}
                          alt="Ban"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://placeimg.com/200/300/animals";
                          }}
                          className="image--coverq"
                        />
                      </div>
                      <a
                        className="home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {FirstName} {LastName}{" "}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        {/* <p className="text_p">{Position}</p> */}
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        {/*<Link to="/Google_2FA" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        <Link to="/Setting_Billing" className="dropdown-item">
                          <i className="fa fa-dollar-sign"></i>
                          <span>Subscription</span>
                        </Link>
                        <Link to="/sub_accounts" className="dropdown-item">
                          <i className="fa fa-users"></i>
                          <span>Sub Admins</span>
                        </Link>*/}
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out"></i>
                          <span>Logout</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <Modal show={this.state.isPopUpOpen} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>
                  Do you want to{" "}
                  {this.state.isPopUpOpen === "CLEARALL"
                    ? "clear all the"
                    : "mark as read all"}{" "}
                  notifications?
                </h5>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleClose}>
                  cancel
                </Button>
                <Button variant="info" onClick={this.handleClose}>
                  confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
