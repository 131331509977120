import React, { useEffect, useState } from "react";
import { Form, Col, Row, Dropdown, Modal, Button } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

import { FaFileDownload, FaTrash, FaUpload } from "react-icons/fa";
import { IoDownloadOutline } from "react-icons/io5";

import "./Row.css";

const RowComponent = ({
  setProofDocument,
  menu,
  handleCommentChange,
  handleNoteChange,
  file,
  edit,
  assignedToDetails,
  commentModal,
  setAnswer,
  setCommentModal,
  handleProofChange,
  currentUserId,
  comment,
  rowIndex,

  columns,
  heading,
  proofDocument,
  note,
  rowData,
  check,
  item,
  handleInputChange,
  updateMatrixData,
  updatedRows,
  radioValuee,
}) => {
  const [errors, setErrors] = useState({});
  const [docs, setDocs] = useState(proofDocument);
  const [rows, setRows] = useState(updatedRows);
  const [coll, setColl] = useState(columns);
  const [data, setData] = useState(rowData);
  const [columnsToRender, setColumnsToRender] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [radioValue, setRadioValue] = useState(radioValuee);
  const [showData, setShowData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [comments, setComments] = useState("");

  const handleDoubleClick = (data) => {
    if (data.trim() !== '') { // Check if data is not an empty string
      setShowData(data);
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    setData(rowData);
  }, [item]);

  useEffect(() => {
    setRadioValue(radioValuee);
  }, [radioValuee]);

  useEffect(() => {
    setRows(updatedRows);
  }, [updatedRows, item]);

  useEffect(() => {
    setDocs(proofDocument);
  }, [proofDocument]);

  useEffect(() => {
    setColl(columns);
    const pageColumnType = `column${rowIndex + 1 > 0 ? rowIndex + 1 : ""}`;
    const hasOnlyGeneralColumn = columns.every(
      (col) => col.option_type.toLowerCase() === "column"
    );

    let filteredColumns;

    if (hasOnlyGeneralColumn) {
      // If columns only have "column", use all as default
      filteredColumns = columns;
    } else {
      // Otherwise, filter based on the specific column type
      filteredColumns = columns.filter((col) => {
        return col.option_type.toLowerCase() === pageColumnType.toLowerCase();
      });
    }

    // Set the filtered columns to render
    setColumnsToRender(filteredColumns);
  }, [columns, updatedRows]);

  const confirmDelete = () => {
    setProofDocument((prevState) => {
      // Clone the current proofDocument array
      const newProofDocument = [...prevState];

      // Update the specific page by filtering out the fileToDelete
      newProofDocument[rowIndex] = newProofDocument[rowIndex].filter(
        (url) => url !== fileToDelete
      );

      return newProofDocument;
    });
    setAnswer((prevState) => {
      const newProofDocument = [...prevState.proofDocument];

      newProofDocument[rowIndex] = newProofDocument[rowIndex].filter(
        (url) => url !== fileToDelete
      );

      return {
        ...prevState,
        proofDocument: newProofDocument,
      };
    });
    setShowModal(false);
    setFileToDelete(null); // Clear the file to delete
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const handleComment = (e) => {
    setComments(e.target.value);
  };

  const cancelComment = () => {
    setCommentModal(false);
    setFileToDelete(null);
  };

  const handleChange = (colId, value) => {
    let isValid = true;
    let isTemp = true;
    let errorMessage = "";

    const col = coll[colId];
    const colOption = col.option.toLowerCase();

    // Validate email
    if (["email", "email id", "email address"].includes(colOption)) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        isTemp = false;
        errorMessage = "Please enter a valid email address.";
      }
    }

    // Validate number
    if (["number"].includes(colOption)) {
      const numberPattern = /^[0-9]+$/;
      if (!numberPattern.test(value)) {
        isTemp = false;
        errorMessage = "Please enter a valid number.";
      }
    }

    // Validate phone number
    if (["phone number", "phone no", "phone no."].includes(colOption)) {
      const phoneNumberPattern = /^[0-9]{10}$/;
      if (!phoneNumberPattern.test(value)) {
        isTemp = false;
        errorMessage = "Please enter a valid 10-digit phone number.";
      }
    }

    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${rowIndex}-${colId}`]: isTemp ? "" : errorMessage,
    }));

    if (isValid) {
      // Update rowData state for the specific column
      handleInputChange(rowIndex, colId, value);

      // Update matrixData with the new rowData
      updateMatrixData(rowIndex, colId, value);
    }
  };
  const handleFileDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handleDeleteClick = (url) => {
    setFileToDelete(url);
    setShowModal(true);
  };

  // Handle percentage input change
  const handlePercentageChange = (colId, value) => {
    let finalValue = value;

    // Allow "NIL" as a valid input
    if (value !== "NIL") {
      // Check if "N", "NI", or "NIL" is being typed and allow it
      if (value === "N" || value === "NI" || value === "NIL") {
        finalValue = value;
      } else {
        // Remove non-numeric characters except for a single decimal point
        const sanitizedValue = value.replace(/[^\d.]/g, "");

        // Ensure there's only one decimal point
        finalValue =
          sanitizedValue.split(".").length > 2
            ? sanitizedValue.split(".").slice(0, 2).join("")
            : sanitizedValue;
      }
    }

    handleChange(colId, finalValue);
  };

  const saveComment = () => {
    // Save the comment and close the modal
    handleCommentChange(comments);

    setCommentModal(false);
  };

  const handleRadioChange = (value, colId, otherColId) => {
    setRadioValue(value);
    handleChange(colId, value);
    handleChange(otherColId, ""); // Set the other option to an empty string
  };

  useEffect(() => {}, [rows, data]);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  const itemHeight = "6vh"; // Adjust height as per design
  const inputStyle = {
    height: itemHeight,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #ccc",

    backgroundColor: "white",
    borderRadius: "5px",
  };
  const filteredDocs = (docs || []).filter(
    (doc) => doc && typeof doc === "string" && doc.trim() !== ""
  );

  const filteredComments = Array.isArray(comment)
    ? comment.filter(
        (c, index) =>
          index < filteredDocs.length && (typeof c === "string" || c === "")
      )
    : [];

  return (
    <>
      <Row>
        {heading && heading?.length ? (
          <Col md={12}>
            <Form.Group controlId={`formInput-${rowIndex}-entity`}>
              <Form.Label className="custom-label">Question</Form.Label>
              <Form.Control
                style={{
                  backgroundColor: "#BFD7E0",
                  lineHeight: "1",
                  minHeight: "40px", // Set a minimum height (adjust as necessary)
                  overflowY: "auto", // Allow vertical scroll if content exceeds maxHeight
                  resize: "vertical", // Prevent resizing, or use "vertical" to allow only vertical resizing
                }}
                type="textarea"
                value={heading[0]?.option || ""}
                onDoubleClick={() => handleDoubleClick(heading[0]?.option)}
                readOnly
              />
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        {data && data?.option !== "1" && data?.option !== "one" ? (
          <Col md={12}>
            <Form.Group controlId={`formInput-${rowIndex}-entity`}>
              <Form.Label className="custom-label">Attribute</Form.Label>
              <Form.Control
                style={{
                  backgroundColor: "#BFD7E0",
                  lineHeight: "1",
                  minHeight: "40px", // Set a minimum height (adjust as necessary)
                  overflowY: "auto", // Allow vertical scroll if content exceeds maxHeight
                  resize: "vertical", // Prevent resizing, or use "vertical" to allow only vertical resizing
                }}
                as="textarea"
                type="text"
                value={data?.option || ""}
                onDoubleClick={() => handleDoubleClick(data?.option)}
                readOnly
              />
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        {columnsToRender?.map((col, index) => {
          const colOptionLower = col.option.toLowerCase(); // Convert to lowercase for a case-insensitive match
          const isEmail = ["email", "email id", "email address"].includes(
            colOptionLower
          );
          // const isNumber = ["number", "phone number", "phone no"].includes(
          //   colOptionLower
          // );
          const isYesOrNo =
            colOptionLower.includes("(yes/no)") ||
            data?.option.includes("Yes/No");
          const isPercentage =
            colOptionLower.includes("%age") ||
            colOptionLower.includes("%") ||
            colOptionLower.includes("percentage");

          const isRadioOption1 =
            col.option ===
            "Disclosures under this report made on a standalone basis (i.e. only for the entity).";
          const isRadioOption2 =
            col.option ===
            "On a consolidated basis (i.e. for the entity & all the entities which form a part of its consolidated financial statements, taken together)";

          if (isRadioOption1 || isRadioOption2) {
            return (
              <Col key={col.detail_id} md={12}>
                <Form.Group
                  controlId={`formInput-${rowIndex}-${col.detail_id}`}
                >
                  <Form.Check
                    type="radio"
                    id={`radio-${rowIndex}-${index}`}
                    name={`radio-${rowIndex}`}
                    label={
                      <span
                        className="custom-radio-label"
                        style={{
                          color: "rgba(0,0,0,0.70)",
                          fontSize: "12px",
                          fontFamily: "Open Sans",
                          fontWeight: 400,
                          wordWrap: "break-word",
                        }}
                      >
                        {col.option}
                      </span>
                    }
                    checked={radioValue === col.option}
                    onChange={() =>
                      handleRadioChange(
                        col.option,
                        index,
                        isRadioOption1 ? index + 1 : index - 1
                      )
                    }
                    readOnly={
                      menu === "audit" ||
                      (assignedToDetails?.assignedTo?.length > 0 &&
                        !assignedToDetails?.assignedTo?.some(
                          (id) => parseInt(id, 10) === currentUserId
                        )) ||
                      (check && edit) ||
                      (assignedToDetails?.dueDate &&
                        new Date(assignedToDetails.dueDate).setHours(
                          0,
                          0,
                          0,
                          0
                        ) < new Date().setHours(0, 0, 0, 0))
                    }
                  />
                </Form.Group>
              </Col>
            );
          }

          const mdValue = isYesOrNo
          ? 3
          : (columnsToRender[index - 1]?.option.toLowerCase().includes("(yes/no)") ||
             columnsToRender[index - 1]?.option.includes("Yes/No"))
          ? 9
          : (col.option.replace(/\(Yes\/No\)/g, "").length > 200 ? 12 : 6);
          return (
            <Col
              key={col.detail_id}
              md={
                // isYesOrNo ? 3 :
                 (col.option.replace(/\(Yes\/No\)/g, "").length > 200 ? 12 : 6)
                // mdValue
              }
            >
              <Form.Group controlId={`formInput-${rowIndex}-${col.detail_id}`}>
                <Form.Label className="custom-label">
                  {col.option.replace(/\(Yes\/No\)/g, "")}
                </Form.Label>

                {isYesOrNo ? (
                  <div className="select-wrapper">
                    <Dropdown
                      onSelect={(eventKey) => handleChange(index, eventKey)}
                      placeholder="Select Yes/No"
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          backgroundColor: "#BFD7E0",
                          color: "black",
                          borderColor: "white",
                          width: "100%",
                        }}
                        // readOnly={
                        //   menu === "audit" ||
                        //   (assignedToDetails?.assignedTo?.length > 0 &&
                        //     parseInt(assignedToDetails.assignedTo[0], 10) !==
                        //       currentUserId)
                        // }
                        readOnly={
                          menu === "audit" ||
                          (assignedToDetails?.assignedTo?.length > 0 &&
                            !assignedToDetails?.assignedTo?.some(
                              (id) => parseInt(id, 10) === currentUserId
                            )) ||
                          (check && edit) ||
                          (assignedToDetails?.dueDate &&
                            new Date(assignedToDetails.dueDate).setHours(
                              0,
                              0,
                              0,
                              0
                            ) < new Date().setHours(0, 0, 0, 0))
                        }
                      >
                        <span style={{ marginRight: "85%" }}>
                          {rows?.[rowIndex]?.[index] ?? "Select "}
                        </span>
                      </Dropdown.Toggle>
                      {!(menu === "audit" ||
        (assignedToDetails?.assignedTo?.length > 0 &&
          !assignedToDetails?.assignedTo?.some(
            (id) => parseInt(id, 10) === currentUserId
          )) ||
        (check && edit) ||
        (assignedToDetails?.dueDate &&
          new Date(assignedToDetails.dueDate).setHours(
            0,
            0,
            0,
            0
          ) < new Date().setHours(0, 0, 0, 0))) && (
        <Dropdown.Menu>
          <Dropdown.Item eventKey="Yes">Yes</Dropdown.Item>
          <Dropdown.Item eventKey="No">No</Dropdown.Item>
        </Dropdown.Menu>
      )}
                    </Dropdown>
                  </div>
                ) : isPercentage ? (
                  <div className="input-percentage-wrapper">
                    <Form.Control
                      style={{
                        backgroundColor: "#BFD7E0",
                        lineHeight: "1",
                        minHeight: "40px", // Set a minimum height (adjust as necessary)
                        overflowY: "auto", // Allow vertical scroll if content exceeds maxHeight
                        resize: "vertical", // Prevent resizing, or use "vertical" to allow only vertical resizing
                      }}
                      type="text"
                      as="textarea"
                      value={rows?.[rowIndex]?.[index] ?? ""}
                      onChange={(e) =>
                        handlePercentageChange(index, e.target.value)
                      }
                      // readOnly={
                      //   menu === "audit" ||
                      //   (assignedToDetails?.assignedTo?.length > 0 &&
                      //     parseInt(assignedToDetails.assignedTo[0], 10) !==
                      //       currentUserId)
                      // }
                      readOnly={
                        menu === "audit" ||
                        (assignedToDetails?.assignedTo?.length > 0 &&
                          !assignedToDetails?.assignedTo?.some(
                            (id) => parseInt(id, 10) === currentUserId
                          )) ||
                        (check && edit) ||
                        (assignedToDetails?.dueDate &&
                          new Date(assignedToDetails.dueDate).setHours(
                            0,
                            0,
                            0,
                            0
                          ) < new Date().setHours(0, 0, 0, 0))
                      }
                    />
                    <span className="percentage-sign">%</span>
                  </div>
                ) : (
                  <>
                    <Form.Control
                      style={{
                        backgroundColor: "#BFD7E0",
                        height: "auto", // Auto height based on content

                        lineHeight: "1",
                        minHeight: "40px", // Set a minimum height (adjust as necessary)
                        overflowY: "auto", // Allow vertical scroll if content exceeds maxHeight
                        resize: "vertical", // Prevent resizing, or use "vertical" to allow only vertical resizing
                      }}
                      type={isEmail ? "email" : "text"}
                      as="textarea"
                      // type={isEmail ? "email" : isNumber ? "tel" : "text"}
                      value={rows?.[rowIndex]?.[index] ?? ""}
                      onDoubleClick={() =>
                        handleDoubleClick(rows?.[rowIndex]?.[index])
                      }
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder=" "
                      // readOnly={
                      //   menu === "audit" ||
                      //   (assignedToDetails?.assignedTo?.length > 0 &&
                      //     parseInt(assignedToDetails.assignedTo[0], 10) !==
                      //       currentUserId)
                      // }
                      readOnly={
                        menu === "audit" ||
                        (assignedToDetails?.assignedTo?.length > 0 &&
                          !assignedToDetails?.assignedTo?.some(
                            (id) => parseInt(id, 10) === currentUserId
                          )) ||
                        (check && edit) ||
                        (assignedToDetails?.dueDate &&
                          new Date(assignedToDetails.dueDate).setHours(
                            0,
                            0,
                            0,
                            0
                          ) < new Date().setHours(0, 0, 0, 0))
                      }
                    />
                    {errors[`${rowIndex}-${index}`] && (
                      <Form.Text className="text-danger">
                        {errors[`${rowIndex}-${index}`]}
                      </Form.Text>
                    )}
                  </>
                )}
              </Form.Group>
            </Col>
          );
        })}
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>{showData}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      {item?.applicableNote === 1 && (
        <Row>
          <Col md={12}>
            <Form.Group controlId="formInput12">
              <Form.Label className="custom-label">Note</Form.Label>
              <Form.Control
                style={{
                  backgroundColor: "#BFD7E0",
                  lineHeight: "1",
                  minHeight: "40px", // Set a minimum height (adjust as necessary)
                  overflowY: "auto", // Allow vertical scroll if content exceeds maxHeight
                  resize: "vertical", // Prevent resizing, or use "vertical" to allow only vertical resizing
                }}
                type="text"
                as="textarea"
                // value={note ? note : ""}
                value={note && !/^(\s*,\s*)+$/.test(note) ? note : ""}
                onDoubleClick={() => handleDoubleClick(note)}
                onChange={handleNoteChange}
                readOnly={
                  menu === "audit" ||
                  (assignedToDetails?.assignedTo?.length > 0 &&
                    !assignedToDetails?.assignedTo?.some(
                      (id) => parseInt(id, 10) === currentUserId
                    )) ||
                  (check && edit) ||
                  (assignedToDetails?.dueDate &&
                    new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                      new Date().setHours(0, 0, 0, 0))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Form.Group>
        <Col md={6}>
        {(menu!=="audit") &&  <Form.Group
            controlId="formFile"
            className="custom-file-upload"
            style={{ marginBottom: "5%" }}
          >
            <Form.Label className="custom-label">Upload Attachment</Form.Label>
            <div className="file-upload-wrapper">
              <label className="upload-btn">
                <div style={{ height: "12px", width: "12px" }}>
                  <FaUpload height={"12px"} width={"12px"} />
                </div>
                <span>Upload a file</span>
                <Form.Control
                  type="file"
                  disabled={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && edit) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                  style={{ display: "none" }} // Hide the default file input
                  onChange={(e) => handleProofChange(e)}
                />
              </label>
            </div>
          </Form.Group>}
        </Col>
        {filteredDocs && filteredDocs?.length > 0 && (
          <Row
            style={{
              backgroundColor: "#DFEBEF",
              width: "100%",
              padding: "20px",
              borderRadius: "8px",
              marginLeft: "0px",
                 marginTop: (menu!=="audit") ? "0%" : "3%"
            }}
          >
            {filteredDocs &&
              filteredDocs?.length > 0 &&
              filteredDocs?.map((url, index) => {
                const docSegments = url.split("/");
                const docFileName =
                  docSegments.length > 1
                    ? docSegments[docSegments.length - 2] ===
                      docSegments[docSegments.length - 1]
                      ? docSegments[docSegments.length - 1]
                      : docSegments[docSegments.length - 1]
                    : url;

                let commentText;
                if (
                  filteredComments.length > 0 &&
                  index < filteredComments.length
                ) {
                  commentText =
                    filteredComments[index] !== 0
                      ? filteredComments[index]
                      : ""; // Use ternary to avoid displaying 0
                } else {
                  commentText = ""; // Handle case where there's no valid comment
                }

                return (
                  <Row key={index} style={{ marginBottom: "10px" }}>
                    <Col md={6}>
                      <div style={inputStyle}>
                        <span
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            wordBreak: "break-all",
                            flexGrow: 1,
                            fontSize: "12px",
                            maxWidth: "70%",
                          }}
                          title={docFileName}
                        >
                          {decodeURIComponent(docFileName).slice(0, 50)}
                        </span>

                        <IoDownloadOutline
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            height: "20px",
                            width: "20px",
                            color: "black",
                          }}
                          onClick={() => handleFileDownload(url)}
                          title="Download File"
                        />

                        {!(
                          menu === "audit" ||
                          (assignedToDetails?.assignedTo?.length > 0 &&
                            !assignedToDetails?.assignedTo?.some(
                              (id) => parseInt(id, 10) === currentUserId
                            ))
                        ) && (
                          <RiDeleteBinLine
                            style={{
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                              color: "black",
                              marginLeft: "1rem",
                            }}
                            onClick={() => handleDeleteClick(url)}
                            title="Remove File"
                          />
                        )}
                      </div>
                    </Col>

                    <Col md={6} style={{ marginLeft: "1.8rem" }}>
                      <div style={inputStyle}>
                        <Form.Control
                          type="text"
                          defaultValue={commentText}
                          readOnly={
                            menu === "audit" ||
                            (assignedToDetails?.assignedTo?.length > 0 &&
                              !assignedToDetails?.assignedTo?.some(
                                (id) => parseInt(id, 10) === currentUserId
                              )) ||
                            (check && edit) ||
                            (assignedToDetails?.dueDate &&
                              new Date(assignedToDetails.dueDate).setHours(
                                0,
                                0,
                                0,
                                0
                              ) < new Date().setHours(0, 0, 0, 0))
                          }
                          placeholder="No Comment"
                          style={{
                            height: "100%",
                            width: "100%",
                            border: "none",
                            borderRadius: "5px",
                            border: "1.5px solid #3F88A5",
                            borderColor: "#3F88A5",
                            background: "#DFEBEF",
                            backgroundColor: "#DFEBEF",
                            paddingLeft: "10px",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}
          </Row>
        )}
      </Form.Group>
      <Modal show={showModal} onHide={cancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this file?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={commentModal} onHide={cancelComment} size="lg"centered>
        <Modal.Header closeButton>
          <Modal.Title>Add a comment for file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <div>{file}</div>
            <Form.Label>Please provide a comment (optional):</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => handleComment(e)}
              placeholder="Enter your comment here"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveComment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RowComponent;
