import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import './TopicManager.css';
import Header from "../../../header/header";
import AssignQuestions from "./AssignQuestions";
import axios from "axios";
import "./SectorQuestion.css";
import config from "../../../../config/config.json";
import { FormControl } from "react-bootstrap";
import { Row, Col, Table, Form, Accordion, ListGroup, Button, Card } from "react-bootstrap";
import QuestionTypeTabSection from "./QuestionTypeTabSection";
import { InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { apiCall } from "../../../../_services/apiCall";
import { useRef } from "react";
import { sweetAlert } from "../../../../utils/UniversalFunction";
import { authenticationService } from "../../../../_services/authentication";
import SectorAnswer from "./SectorAnswer";
import Review from "../../../../img/sector/reviewing.png";
import Verified from "../../../../img/sector/accept.png";
import Reject from "../../../../img/sector/decline.png";
import NotAnswered from "../../../../img/sector/notAnswer.png";
import Maximize from "../../../../img/sector/maximize.png";
import Minimize from "../../../../img/sector/minimize.png";
import SectorQuestionFilter from "./Filter/SectorQuestionFilter";
import { USER_TYPE_CODE_MAPPING } from "../../../../_constants/constants";
import { NavLink } from "react-router-dom";
import Loader from "../../../loader/Loader";
import ReAssignQuestions from "./ReassignUser";
import QualitativeQuestionType from "./QualitativeQuestionType";
import TabularQuestionType from "./TabularQuestionType";
import YesNoType from "./YesNoType";
import QuantitativeTrendsType from "./QuantitativeTrendsType";

const SectorQuestionNew = (props) => {
  // const questionsIds = props.location?.state?.questionIds
  const [questionList, setQuestionList] = useState();
  const [questionsIds, setQuestionsIds] = useState([]);
  const [quesWidth, setQuesWidth] = useState(6);
  const [ansWidth, setAnsWidth] = useState(6);
  const [hideCol, setHideCol] = useState(false);
  const [sectorQuestionType, setSectorQuestionType] = useState();
  const [uploadItem, setUploadItem] = useState([]);
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [entity, setEntity] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [menuList, setMenuList] = useState([]);




  const [questionData, setQuestionData] = useState({
    title: "",
    id: "",
    questionType: "",
    frameworkId: "",
    topicId: "",
    kpiId: "",
    title: "",
    question_detail: [],
    audit_status: "",
    add_row: 0,
    formula: null,
    yes_no_option: null,
    add_note_options: null,
  });



  const [filterQuestionList, setFilterQuestionList] = useState([]);
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState(false);
  const [companyEsgData, setCompanyEsgData] = useState();
  const [answers, setAnswers] = useState([]);
  const [assignedDetails, setAssignedDetails] = useState([]);
  const [financialYearId, setFinancialYearId] = useState(0);
  const [financialYear, setFinancialYear] = useState([]);
  const authValue = JSON.parse(localStorage.getItem("currentUser"));
  const [initalValue, setInitialValue] = useState({});
  const [meterList, setMeterList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const ref = useRef();
  const current_role = localStorage.getItem("role");
  const current_user_type_code = USER_TYPE_CODE_MAPPING[current_role];

  useEffect(() => {
    if (entity[0]) {
      getFinancialYear();
    }
  }, [entity[0]]);
  useEffect(() => {
    getFinancialYear();
  }, []);
  useEffect(() => {
    if (filterQuestionList) {
      setQuestionData(filterQuestionList[0]);
      setSelectedRow(filterQuestionList[0]?.id);
      setIneerRowSelectedRow(false);
    }
  }, [filterQuestionList]);

  useEffect(() => {
    if (financialYearId && entity) fetchStoredData();
  }, [financialYearId, entity]);
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length) {
        setFinancialYearId(data.data[data?.data?.length - 1].id);
        if (financialYearId && entity) {
          fetchStoredData();
        }
      }
    }
  };
  useEffect(() => {
    setInitialValue({
      starting_month: JSON.parse(localStorage.getItem("currentUser"))
        .starting_month,
      frequency: JSON.parse(localStorage.getItem("currentUser")).frequency,
    });
  }, []);





  const settingEntities = async () => {
    if (current_user_type_code === "company") {
      setEntity([{ name: "company", id: 1 }]);

      setEntityList([
        { name: "company", id: 1 },
        { name: "supplier", id: 3 },
      ]);
    }
    if (current_user_type_code === "supplier") {
      const { isSuccess, data, error } = await getSupplierEntities();
      if (isSuccess) {
        setEntityList(
          (data?.entities).map((entity) => ({
            name: entity.register_company_name,
            id: entity.companyId,
          }))
        );

      }
      if (error) {
        swal({
          icon: "error",
          title: "Could not get entity companies!",
          timer: 1000,
        });
      }
    }
  };
  useEffect(() => {
    settingEntities();

  }, []);




  const getSupplierEntities = async () => {
    return await apiCall(
      `${config.API_URL}getSupplierEntities`,
      {},
      {
        supplier_id:
          current_role === "SUPPLIER" ? authValue.id : authValue.parent_id,
      }
    );
  };

  const setEntityValue = (value) => {
    let entityName = entityList?.filter((entity) => {
      if (entity.id === parseInt(value)) {
        return entity;
      }
    });

    setEntity([{ name: entityName[0]?.name, id: parseInt(value) }]);
  };

  const fetchStoredData = async () => {
    if (financialYearId) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getESGReport`,
        {},
        {
          type: "SQ",
          financial_year_id: financialYearId ? financialYearId : 6,
        },
        "GET"
      );
      if (isSuccess) {
        const responseData = data?.data;
        if (data?.mainCompany && responseData.length === 0) {
        } else {
          if (!responseData?.mainCompany) {
            getSectorQuestion([], [], []);
          }
          const storeData = responseData[0]?.frameworkTopicKpi || "{}";
          setCompanyEsgData(storeData);
          if (!storeData.frameworkId || storeData.frameworkId.length === 0) {
          } else {
            if (responseData.length === 0 && responseData?.mainCompany) {
            } else {
              getSectorQuestion(
                storeData.frameworkId,
                storeData.mandatoryTopicsId
                  .concat(storeData.voluntaryTopicsId)
                  .concat(storeData.customTopicsId),
                storeData.mandatoryKpiId
                  .concat(storeData.voluntaryKpiId)
                  .concat(storeData.customKpiId)
              );
            }
          }
        }
      }
    } else {
    }
  };
  const getSectorQuestion = (
    frameworkIds,
    topicIds,
    kpiIds,
    selectedDesignationId,
    selectedUserId,
    selectedLocationId,
    fromDate,
    toDate,
    selectedStatusId
  ) => {
    setLoading(true);
    let questionId = props.location?.state?.questionIds;
    if (questionId) {
      setFinancialYearId(6);
    }
    const locatStorageIds = localStorage.getItem("questionIds");
    if (locatStorageIds) {
      questionId = [locatStorageIds];
    }
    axios
      .get(
        `${config.POSTLOGIN_API_URL_COMPANY
        }getSectorQuestion?type=CUSTOM&financialYearId=${financialYearId ? financialYearId : 6
        }&questionnaireType=SQ&frameworkIds=[${frameworkIds}]&topicIds=[${topicIds}]&kpiIds=[${kpiIds}]${questionId ? `&questionIds=[${questionId}]` : "&questionIds=undefined"
        }& roleIds=[${selectedDesignationId}]&userIds=[${selectedUserId}]&locationIds=[${selectedLocationId}]&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            userId: JSON.parse(localStorage.getItem("currentUser")).id,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        setLoading(false);
        getSource();
        getProcess();
        localStorage.removeItem("questionIds");
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setDatas(true);
          const groupedTopicsData = response.data.data.reduce((acc, obj) => {
            const { report_id, topic_name, ...rest } = obj;
            if (!acc[topic_name]) {
              acc[topic_name] = [];
            }
            acc[topic_name].push({ report_id, topic_name, ...rest });
            return acc;
          }, {});
          setData([1]);
          console.log("Grouped Topics Data", groupedTopicsData);
          setGroupedTopicsData(groupedTopicsData);


          setAnswers(response.data.answers);
          console.log("Answers", response.data.answers);
          setAssignedDetails(response?.data?.assignedDetails);
        } else {
          setData([]);
          setQuestionLoading(false);
          setQuestionList([]);
          setFilterQuestionList([]);
          setAnswers([]);
          setAssignedDetails([]);
          setLoading(false);
          setFilterQuestionList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setMeterList(locationArray);
    }
  };
  const getProcess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getProcess`,
      {},
      { type: "ALL" },
      "GET"
    );
    if (isSuccess) {
      setProcessList(data?.data?.reverse());
    }
  };






  const [groupedTopicsData, setGroupedTopicsData] = useState(null);
  const [questionsDataList, setQuestionsDataList] = useState([]);


  useEffect(() => {
    setQuestionsDataList(filterQuestionList);
  }, [filterQuestionList]);
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = JSON.parse(localStorage.getItem("menu")).find(
      (item) => item?.url === "sector_questions"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);
  useEffect(() => {
    if (financialYearId) fetchStoredData();
  }, [entity]);


  useEffect(() => {
    setQuestionsIds(props.location);
    const locatStorageIds = localStorage.getItem("questionIds");
    if (locatStorageIds || props.location?.state?.questionIds) {
      setFinancialYear([
        {
          id: 6,
          financial_year_value: "2023-2024",
        },
      ]);

      setFinancialYearId(6);
      if (6) fetchStoredData();
    }
  }, [props.location?.state?.questionIds]);





  const [selectedHeading, setSelectedHeading] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedSection, setSelectedSection] = useState();
  const [availableSection, setAvailableSection] = useState([]);





  useEffect(() => {
    console.log("Grouped Topics Dataaaaaa", groupedTopicsData);

    if (groupedTopicsData)
      setAvailableSection(Object.keys(groupedTopicsData))
  }, [groupedTopicsData])

  const [searchTerm, setSearchTerm] = useState('');


  const groupedByHeading = selectedSection
    ? groupedTopicsData[selectedSection].reduce((acc, topic) => {
      if (!acc[topic.heading]) {
        acc[topic.heading] = [];
      }
      acc[topic.heading].push(topic);
      return acc;
    }, {})
    : {};

  console.log("Grouped By Heading", groupedByHeading);
  const [showModalTopic, setShowModalTopic] = useState(false);

  const [selectedInnerRow, setIneerRowSelectedRow] = useState(null);

  const [activeIndexAccordion, setActiveIndexAccordion] = useState(null);

  const handleReplaceSection = (section) => {
    setSelectedSection(section);
    setSelectedHeading(null);
    setShowModalTopic(false);
  };

  const filteredItems = groupedByHeading[selectedHeading]
    ? groupedByHeading[selectedHeading].filter((item) => {
      return item?.title?.toLowerCase().includes(searchTerm?.toLowerCase());
    })
    : [];

  console.log("Filtered Items:", filteredItems);

  console.log("Filtered Items", filteredItems)

  console.log()
  const matchedItems = filteredItems.map((item) => {
    console.log("item")

    const correspondingAnswer = answers.find(
      (answer) => answer.questionId === item.id
    );
    console.log("corr", correspondingAnswer, item)
    return {
      ...item,
      notApplicable:correspondingAnswer?.notApplicable,
      note: correspondingAnswer?.note,
      combinedAnswers: correspondingAnswer?.combinedAnswers || "No Combined",
      answer: item?.questionType === "quantitative_trends"
        ? (correspondingAnswer?.answer || "No Combined")
        : (correspondingAnswer?.answer || "No Answer"),
    };
  });

  console.log("Matched Items", matchedItems)


  const handleHeadingClick = (heading) => {
    setSelectedHeading(selectedHeading === heading ? null : heading);
  };


  const handleAccordionClick = (index) => {
    setActiveIndexAccordion(activeIndexAccordion === index ? null : index);
  };


  const sortItems = (items) => {
    return items.sort((a, b) => {
      // If either report_id is null or undefined, keep the original order
      if (!a?.report_id || !b?.report_id) {
        if (!a?.report_id && !b?.report_id) {
          return 0; // If both are null or undefined, they are considered equal
        }
        return !a?.report_id ? 1 : -1; // If only one is null or undefined, move it to the end
      }

      const regex = /^(\d+)([a-zA-Z()]*)$/;

      const aMatch = a.report_id.match(regex);
      const bMatch = b.report_id.match(regex);

      if (!aMatch || !bMatch) {
        // Handle cases where the regex doesn't match
        return a.report_id.localeCompare(b.report_id);
      }

      const [, aNum, aChar] = aMatch;
      const [, bNum, bChar] = bMatch;

      if (parseInt(aNum, 10) !== parseInt(bNum, 10)) {
        return parseInt(aNum, 10) - parseInt(bNum, 10);
      }

      return aChar.localeCompare(bChar);
    });
  };



  const sortedItems = sortItems(matchedItems);
  useEffect(()=>{
    setSearchTerm("");
  },[selectedHeading])

  const renderQuestionComponent = (item) => {
    console.log("this is yure", item)
    switch (item.questionType) {
      case 'qualitative':
        return <QualitativeQuestionType title={item.title} answer={item.answer} note={item?.note} />;
      case 'tabular_question':
        return <TabularQuestionType item={item} note={item?.note} combinedAnswers={item.combinedAnswers} question_detail={item.question_detail} title={item.title} answer={item.answer} />;
      case 'quantitative':
        return <QualitativeQuestionType note={item?.note} title={item.title} answer={item.answer} />;
      case 'yes_no':
        return <YesNoType note={item?.note} title={item.title} answer={item.answer} notApplicable={item?.notApplicable}/>
      case 'quantitative_trends':
        return <QuantitativeTrendsType item={item} note={item?.note} title={item.title} answer={item?.answer} />
      default:
        return <p>Unknown question type</p>;
    }
  };
  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <div style={{ flex: "0 0 21%", position: "sticky", top: 0, zIndex: 999 }}>
        <Sidebar dataFromParent={props.location.pathname} />
      </div>
      <div style={{ flex: "1 1 79%" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper p-3" >
          <>
            <div className="w-100 d-flex justify-content-between p-4">
              <div className="d-flex flex-column align-items-start">
                <div style={{ width: '100%', color: 'rgba(1, 22, 39, 0.50)', marginBottom: "1px", fontSize: "13px", fontFamily: 'Open Sans', fontWeight: '400', wordWrap: 'break-word' }}>
                  Financial Year
                </div>
                {financialYear && financialYear?.length === 1 ? (
                  <select
                    className="sector-question-select"
                    onChange={async (e) => {
                      if (e.target.value !== "Select Financial Year") {
                        setFinancialYearId(e.target.value);
                        if (financialYearId && entity) {
                          fetchStoredData();
                        }
                      } else {
                        setFinancialYearId("");
                      }
                    }}
                  >
                    {financialYear?.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item.financial_year_value}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    className="sector-question-select"
                    value={
                      financialYearId ||
                      (financialYear.length > 0
                        ? financialYear[financialYear.length - 1].id
                        : "")
                    }
                    onChange={async (e) => {
                      if (e.target.value !== "Select Financial Year") {
                        setFinancialYearId(e.target.value);
                        if (financialYearId && entity) {
                          fetchStoredData();
                        }
                      } else {
                        setFinancialYearId("");
                      }
                    }}
                  >
                    <option value={0}>Select Financial Year</option>
                    {financialYear?.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item.financial_year_value}
                      </option>
                    ))}
                  </select>
                )}

              </div>

              <div className="dropdown hstack pb-2">
                {((current_user_type_code === "company" &&
                  current_role === "COMPANY") ||
                  current_user_type_code === "supplier") && (
                    <select
                      className="select_one_all"
                      onChange={(e) => {
                        setEntityValue(e.target.value);
                      }}
                      defaultValue={
                        current_user_type_code === "company" &&
                          current_role === "COMPANY"
                          ? 1
                          : 3
                      }
                    >
                      <option
                        value=""
                        disabled
                        selected={
                          current_user_type_code === "supplier"
                            ? true
                            : false
                        }
                      >
                        {current_user_type_code === "company"
                          ? current_role === "COMPANY"
                            ? "Select Entity"
                            : ""
                          : "Select Company"}
                      </option>
                      {/* )} */}
                      {entityList.map((entity) => (
                        <option
                          value={entity.id}
                          ref={ref}
                          selected={
                            current_user_type_code === "company" &&
                            current_role === "COMPANY" &&
                            entity.name === "company"
                          }
                        >
                          {entity.name}
                        </option>
                      ))}
                      {/* <option value="company">Company</option>
                                  <option value="supplier">Supplier</option> */}
                    </select>
                  )}
              </div>
              <div>
                {/* <button className="new_button_style" disabled={true}>
                  Download Report
                </button> */}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between p-4">
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', width: "100%", backgroundColor: "white", borderRadius: "10px" }}>
                <div style={{ width: '70%' }}>
                  <ListGroup>
                    {selectedSection ? (
                      <ListGroup>
                        <ListGroup.Item
                          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderColor: "#3F88A5" }}
                        >
                          <span>{selectedSection}</span>

                        </ListGroup.Item>
                      </ListGroup>
                    ) : (
                      <div style={{
                        color: '#3F88A5',
                        fontSize: "14px",
                        fontFamily: 'Open Sans',
                        fontWeight: '700',
                        paddingTop: "10px",

                        wordWrap: 'break-word'
                      }}>Please add a section first</div>
                    )}
                  </ListGroup>
                </div>
                <div style={{ width: '30%', display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                  <Button style={{ color: "white", backgroundColor: "#3F88A5", paddingLeft: "25%", paddingRight: "25%", borderColor: "transparent" }} onClick={() => setShowModalTopic(true)}>{selectedSection ? "Replace" : "Add"}</Button>
                </div>

                <Modal show={showModalTopic} onHide={() => setShowModalTopic(false)} size="lg" style={{ width: "100%", maxHeight: "80vh" }}>
                  <Modal.Header closeButton>
                    <Modal.Title>Select Section</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ListGroup >

                      {availableSection?.map(section => (
                        <ListGroup.Item
                          key={section}
                          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                          <span>{section}</span>
                          <Button
                            style={{ color: "white", backgroundColor: "#3F88A5", paddingLeft: "5%", paddingRight: "5%", borderColor: "transparent" }}
                            onClick={() => handleReplaceSection(section)}
                          >
                            {selectedSection ? "Replace" : "Add"}
                          </Button>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Modal.Body>

                </Modal>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between " style={{ paddingTop: "0px", paddingLeft: "2rem", paddingRight: "0rem" }}>
              {selectedSection ? (
                <div className="w-100">
                  <div className="w-100">
                    <Row className="w-100">
                      {Object.keys(groupedByHeading).map((heading, index) => (
                        <Col md={3} key={index} style={{ padding: '10px', marginRight: "0px" }}>
                          <div
                            className={`normal-text ${selectedHeading === heading ? 'selected' : ''}`}
                            onClick={() => handleHeadingClick(heading)}
                          >
                            {heading}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              ) : null}

            </div>
            <div className="w-100 d-flex justify-content-between p-4" style={{ borderRadius: "10px" }}>
              {selectedHeading ? (
                <div className="container w-100 p-4" style={{ backgroundColor: "#F4F7F8", borderRadius: "10px" }}>
                  <div className="accordion" id="accordionExample" style={{ maxWidth: "69vw" }}>
                    <div className="search-bar-wrapper" style={{ marginLeft: "0%", width: "100%", marginBottom: "5%" }}>
                      <InputGroup className="search-bar h-100">
                        <InputGroup.Text id="basic-addon1">
                          <FaSearch style={{ color: "#9CBFCD" }} />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search Questions"
                          aria-label="Search"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>

                    {sortedItems ? (
                      sortedItems.map((item, index) => (
                        <div className="accordion-item my-3" key={index} style={{ border: "none", backgroundColor: "transparent" }}>
                          <h2 className="accordion-header" id={`heading${index}`}>
                            <button
                              className="accordion-button d-flex justify-content-between align-items-center"
                              type="button"
                              style={{ backgroundColor: "#BFD7E0", color: "black" }}
                              onClick={() => handleAccordionClick(index)}
                              aria-expanded={activeIndexAccordion === index}
                              aria-controls={`collapse${index}`}
                            >
                              <span style={{ color: "black" }}>{item?.report_id}. {" "}{item?.title?.replace(/\b(Yes|No)\b/g, '')}</span>
                              <span
                                className="btn btn-sm btn-outline-secondary ms-2"
                                style={{
                                  fontWeight: "bold",
                                  border: "1.5px solid",
                                  borderColor: "black",
                                  padding: "0.4%",
                                  paddingLeft: "1%",
                                  paddingRight: "1%",
                                }}
                              >
                                {activeIndexAccordion === index ? "-" : "+"}
                              </span>
                            </button>
                          </h2>
                          <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${activeIndexAccordion === index ? "show" : ""}`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                            style={{
                              backgroundColor: '#F4F7F8',
                              border: "2px solid #3F88A5",
                              borderRadius: "10px",
                              color: 'black',
                              marginTop: "10px"
                            }}
                          >
                            <div className="accordion-body" style={{ width: '100%', maxWidth: "100%", overflow: "auto", paddingBottom: "0px" }}>
                              <div className="w-100" style={{ maxWidth: "100%", overflow: "auto" }}>
                                {renderQuestionComponent(item)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : null}

                  </div>
                </div>
              ) : null}


            </div>
            {financialYear && financialYear?.length > 0 ? (
              <div className="">
                <div>
                  {!groupedTopicsData ? (
                    !datas && financialYearId ? (
                      <div className="row">
                        <div className="col-12">
                          <Loader />
                        </div>
                      </div>
                    ) : (
                      <Loader />
                    )
                  ) : (
                    <>
                    </>
                  )}
                </div>
              </div>
            ) : (
              financialYearId && (
                <div className="row">

                </div>
              )
            )}
          </>
          {/* </section> */}

        </div>
      </div>
    </div>
  );
};

export default SectorQuestionNew;
