import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import { Row, Col } from "react-bootstrap";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEnvironment from "./TopComponentEnvironment";
import Overview from "./Overview";
import DashboardCharts from "./DashboardCharts";
import DashboardChartsTwo from "./DashboardChartsTwo";
import TotalEmissionGenerated from "../Emission/TotalCommissionGranted";
import TotalEmissionGeneratedSingle from "./TotalEmissionGenerated";
import TotalEnergyConsumption from "../Energy/TotalEnergyConsumption";
import TotalWaterConsumed from "./TotalWwaterConsumed";
import TotalWasteGenerated from "./TotalWasteDisposed";
import TotalWaterWithdrawn from "./TotalWaterWithdrawn,";
import WasteDisposedEn from "./WasteDisposedEn";
import GreenProcurementRate from "./GreenProcurementRate";
import IndustryBenchmarkComparison from "./IndustryBenchmark";
import ProgressTowardsESGTargets from "./ProgressTowardsESGCharts";
import EnergyConsumptionBar from "./EnergyConsumptionBar";
import DonutChart from "./DonutChart";
import ProgressTowardsESG from "./ProgressTowardsESG";
import SplineAreaChart from "./SplineAreaChart";
import Renewable from "./RenewableAndNoneRenew";
import WaterWithdraw from "./TotalWaterWithdraw";
import IndustryStandards from "./IndustryStandards";
import WaterDonutChart from "./DonutChart";
import WasteDonutChart from "./WasteDonutChart";
import ScopeBar from "./ScopeBar";

const Environment = ({
  locationOption,
  timePeriods,
  sectorQuestionAnswerDataForGraph,
  financialYearId,
  frameworkValue,
  graphData,
}) => {
  console.log("location", graphData);

  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [companyFramework, setCompanyFramework] = useState();

  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const renewableEnergy = Number(
    JSON.parse(
      (sectorQuestionAnswerDataForGraph?.[1]?.["answer"] || '[]')
    )[4]?.[0] || 0
  );
  
  const nonRenewableEnergy = Number(
    JSON.parse(
      (sectorQuestionAnswerDataForGraph?.[1]?.["answer"] || '[]')
    )[9]?.[0] || 0
  );
  
  const scopeOne = Number(
    JSON.parse(
      (sectorQuestionAnswerDataForGraph?.[0]?.["answer"] || '[]')
    )[0]?.[1] || 0
  );
  
  const scopeTwo = Number(
    JSON.parse(
      (sectorQuestionAnswerDataForGraph?.[0]?.["answer"] || '[]')
    )[1]?.[1] || 0
  );
  
  const totalEnergy = renewableEnergy + nonRenewableEnergy;
  
  const totalScope = scopeOne + scopeTwo;
  
  
  const timePeriodValues = Object.values(timePeriods || {});
  
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataDischarge, setFilteredDataDischarge] = useState([]);
  const [matchedData, setMatchedData] = useState([]);
  const [matchedDataDischarge, setMatchedDataDischarge] = useState([]);
  const [matchedDataWaste, setMatchedDataWaste] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [totalDischarge, setTotalDischarge] = useState(0);
  const [totalSumWaste, setTotalSumWaste] = useState(0);
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  // const [companyFramework, setCompanyFramework] = useState([]);
  
  // Effect to filter data based on titles and match time periods
  useEffect(() => {
    const newFilteredData = graphData?.filter(
      (item) => item.title === "Water withdrawal"
    ) || [];
    const newFilteredDataDischarge = graphData?.filter(
      (item) => item.title === "Details of Water Discharge"
    ) || [];
  
    setFilteredData(newFilteredData);
    setFilteredDataDischarge(newFilteredDataDischarge);
  
    const timePeriodValues = Object.values(timePeriods || {});
  
    const newMatchedData = newFilteredData.filter((item) =>
      timePeriodValues.includes(item.formDate)
    );
    const newMatchedDataDischarge = newFilteredDataDischarge.filter((item) =>
      timePeriodValues.includes(item.formDate)
    );
  
    setMatchedData(newMatchedData);
    setMatchedDataDischarge(newMatchedDataDischarge);
  
    const newFilteredDataWaste = graphData?.filter(
      (item) => item.title === "Waste Disposal"
    ) || [];
    const newMatchedDataWaste = newFilteredDataWaste.filter((item) =>
      timePeriodValues.includes(item.formDate)
    );
  
    setMatchedDataWaste(newMatchedDataWaste);
  
    calculateSums(newMatchedData, newMatchedDataWaste, newMatchedDataDischarge);
  }, [graphData, timePeriods]);
  
  // Function to calculate total sums
  const calculateSums = (matchedData, matchedDataWaste, matchedDataDischarge) => {
    const sumWater = matchedData.reduce((sum, item) => {
      return (
        sum +
        item.answer?.slice(0, 6)?.reduce((innerSum, innerArray) => {
          return innerSum + (Number(innerArray?.[0]) || 0);
        }, 0) || 0
      );
    }, 0);
  
    const sumWaste = matchedDataWaste.reduce((sum, item) => {
      return (
        sum +
        item.answer?.reduce((innerSum, innerArray) => {
          return (
            innerSum +
            innerArray?.reduce((arraySum, value) => {
              return arraySum + (Number(value) || 0);
            }, 0) || 0
          );
        }, 0) || 0
      );
    }, 0);
  
    const sumDischarge = matchedDataDischarge.reduce((total, item) => {
      const sum = item.answer?.reduce((subTotal, dataArray) => {
        const value = dataArray?.[0] === "NA" ? 0 : Number(dataArray?.[0]);
        return subTotal + (value || 0);
      }, 0) || 0;
      return total + sum;
    }, 0);
  
    setTotalSum(sumWater);
    setTotalSumWaste(sumWaste);
    setTotalDischarge(sumDischarge);
  };
  
  // Function to fetch and set last week activities
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const activityData = {
        "Total Energy": {
          number: `${totalEnergy}(M) GJ`,
          questionId: [],
        },
        "Total Emission": {
          number: `${totalScope} tCO2e`,
          questionId: [],
        },
        "Total Water": {
          number: `${totalSum} KL`,
          questionId: [],
        },
        "Total Waste": {
          number: `${totalSumWaste} (mt)`,
          questionId: [],
        },
        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekActivities(activityData);
    }
  };
  
  useEffect(() => {
    lastWeekActivity();
  }, [totalSum, totalSumWaste]);
  
  useEffect(() => {
    if (frameworkValue && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  
  return (
    <div className="progress-container">
     {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && <div className="topcompo">
        {lastWeekActivities &&
        companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) ? (
          <TopComponentEnvironment
            lastWeekActivities={lastWeekActivities}
            icons={icons}
            timePeriods={timePeriods}
            graphData={graphData}
            sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph}
          />
        ) : (
          <></>
        )}
      </div>}

      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          {companyFramework?.includes(1) && (
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "40vh", marginBottom: "3%" }}
            >
              <Overview
                timePeriods={timePeriods}
                graphData={graphData}
                sectorQuestionAnswerDataForGraph={
                  sectorQuestionAnswerDataForGraph
                }
              />
            </div>
          )}

         { companyFramework?.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "22vh", marginBottom: "3%" }}
          >
            <EnergyConsumptionBar
              sectorQuestionAnswerDataForGraph={
                sectorQuestionAnswerDataForGraph
              }
              graphData={graphData}
            />
          </div>}

          {companyFramework?.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "200vh", marginBottom: "3%" }}
          >
            {/* <div
              style={{
                height: "100%",
                width: "100%",
                background: "transparent",
                marginLeft: "0.5%",
              }}
            >
              <DashboardCharts />
            </div> */}
            <div
              className="secondhalfprogress"
              style={{ width: "50%", height: "100%" }}
            >
              <div style={{ height: "8%", marginBottom: "5%" }}>
                <TotalEmissionGeneratedSingle totalScope={totalScope} />
              </div>
              <div style={{ height: "25%", marginBottom: "5%" }}>
                <WaterDonutChart
                  waterType={matchedData}
                  percentageConsumed={75}
                  timePeriodValues={timePeriodValues}
                  percentageNotConsumed={25}
                  title="Water Consumption"
                  color="#4caf50" // Custom color for the chart
                />
              </div>
              <div style={{ height: "25%", marginBottom: "5%" }}>
                <WasteDonutChart
                  percentageConsumed={60}
                  matchedDataWaste={matchedDataWaste}
                  percentageNotConsumed={40}
                  title="Total Waste Disposed"
                  color="#4cc200" // Custom color for the chart
                />
              </div>
            </div>
            <div
              className="firsthalfprogressenergy"
              style={{ height: "100%", width: "50%", marginLeft: "3%" }}
            >
              <div style={{ height: "13%", marginBottom: "3%" }}>
                <ScopeBar
                  title={"Scope 1 and Scope 2 Emissions"}
                  consumption={scopeOne}
                  notGenerated={scopeTwo}
                />
              </div>
              <div style={{ height: "10%", marginBottom: "3%" }}>
                <Renewable
                  consumption={renewableEnergy}
                  notGenerated={nonRenewableEnergy}
                  maxConsumption={totalEnergy}
                />
              </div>
              <div style={{ height: "13%", marginBottom: "3%" }}>
                <WaterWithdraw
                  consumption={totalSum}
                  notGenerated={totalDischarge}
                />
              </div>
              {/* <div style={{ height: "30%",  marginBottom: "3%" }}>
               <IndustryStandards title={"Industry Standards"}/>
              </div> */}
              {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "10%", marginBottom: "3%" }}
          >
            <GreenProcurementRate consumption={20} maxConsumption={80} />
          </div> */}
            </div>
          </div>}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "20vh", marginBottom: "3%" }}
          >
            <ProgressTowardsESG />
          </div> */}
        </div>
      )}
      {locationOption.length >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          {companyFramework?.includes(1) &&<div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <Overview />
          </div>}
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            {companyFramework?.includes(1) &&<div className="firsthalfprogressenergy">
              <TotalEmissionGenerated />
            </div>}
           {companyFramework?.includes(1) && <div className="secondhalfprogress">
              <TotalEnergyConsumption />
            </div>}
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
           { companyFramework?.includes(1) &&<div className="firsthalfprogressenergy">
              <TotalWaterConsumed />
            </div>}
            { companyFramework?.includes(1) && <div className="secondhalfprogress">
              <TotalWasteGenerated />
            </div>}
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            { companyFramework?.includes(1) &&<div className="firsthalfprogressenergy">
              <TotalWaterWithdrawn />
            </div>}
          { companyFramework?.includes(1) && <div className="secondhalfprogress">
              <WasteDisposedEn />
            </div>}
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
           {companyFramework?.includes(1) && <div className="firsthalfprogressenergy">
              <GreenProcurementRate />
            </div>}
            {companyFramework?.includes(1) &&<div className="secondhalfprogress">
              <IndustryBenchmarkComparison />
            </div>}
          </div>
          { companyFramework?.includes(1) &&<div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <ProgressTowardsESGTargets />
          </div>}
        </div>
      )}
    </div>
  );
};

export default Environment;
