import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentSafety from "./TopComponentSafety";
import WellBeingMeasures from "./WellBeingMeasures";
import SafetyRelatedIncidents from "./SafetyRelatedIncidents";
import IncidentRateAnalysis from "./IncidentRateAnalysis";
import SeverityRate from "./SevrityRate";
import SafetyTrainingCompletionRate from "../Training/SafetyTrainingCompletionRate";
import MeasuresForTheWellBeing from "./MeasuresForTheWellBeing";
import OccupationalHealth from "./OccupationalHealth";
import SafetyTrainingCompletionRateWorkers from "./SafetyTrainingCompletionRatesWorker";
import SafetyStandards from "./SafetyStandards";
import SafetyTrainingCompletionSingle from "./SafetyTrainingCompletionSingle";
import WorkerSafety from "./Compliance";
import ComplianceSafety from "./ComplianceSafety";
import MeasureWellBeingWorker from "./MeasureWellBeingWorker";

const Safety = ({ locationOption,graphData,timePeriods , frameworkValue}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
  const [companyFramework, setCompanyFramework] = useState(0);
  
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess && data?.data) {
      setLastWeekAcitivities(data.data);
    }
  };
  
  const [wellBeingEmployee, setWellBeingEmployee] = useState([]);
  const [wellBeingWorker, setWellBeingWorker] = useState([]);
  
  useEffect(() => {
    // Update wellBeingEmployee and wellBeingWorker states based on filtered graphData and timePeriods
  
    const empDiffIncl = graphData?.filter(item => item.title === "Details of measures for the well-being of employees") || [];
    const workDiffIncl = graphData?.filter(item => item.title === "Details of measures for the well-being of workers") || [];
  
    const matchedDiffIncl = empDiffIncl?.filter(item => {
      return Object.values(timePeriods || {}).includes(item?.formDate);
    }) || [];
  
    const matchedWorkDiffIncl = workDiffIncl?.filter(item => {
      return Object.values(timePeriods || {}).includes(item?.formDate);
    }) || [];
  
    setWellBeingEmployee(matchedDiffIncl);
    setWellBeingWorker(matchedWorkDiffIncl);
  
  }, [graphData, timePeriods]);
  
  useEffect(() => {
    lastWeekActivity();
  }, []);
  
  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value?.id).filter(id => id !== undefined);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  
  return (
    <div className="progress-container">
      {/* <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentSafety
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>{" "} */}
      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "1%" }}
          >
            <WellBeingMeasures wellBeingEmployee={wellBeingEmployee} />
          </div>}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "25vh", marginBottom: "3%" }}
          >
            <SafetyTrainingCompletionSingle
            
              nonPermanentData={{
                consumption: 400,
                notGenerated: 500,
              }}
              maxConsumption = {1200}
              permanentData={{
                consumption: 700,
                notGenerated: 300,
              }}
            />
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
           <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <OccupationalHealth/>
            </div>
            <div className="secondhalfprogress" style={{ width: "50%", height:"100%" }}>
              <div style={{ width: "100%", height:"52%", marginBottom:"3%" }}>
                <ComplianceSafety consumption={5} notGenerated={10} maxConsumption={20}/>

              </div>
              <div style={{ width: "100%", height:"45%" }}>
                <WorkerSafety consumption={5} notGenerated={10} maxConsumption={20}/>

              </div>
            </div>
          </div> */}
          {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "1%" }}
          >
           <MeasureWellBeingWorker wellBeingWorker={wellBeingWorker}/>
          </div>}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "40vh", marginBottom: "1%" }}
          >
            <IncidentRateAnalysis/>

          </div> */}
        </div>
      )}
      {/* {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <WellBeingMeasures />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyRelatedIncidents />
          </div>
        </div>
      )} */}
      {locationOption >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            { companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) &&<div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <IncidentRateAnalysis />
            </div>}
            { companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) &&<div className="secondhalfprogress" style={{ width: "50%" }}>
              <SeverityRate />
            </div>}
          </div>
        {  companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyTrainingCompletionRate />
          </div>}
       { companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) &&  <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <MeasuresForTheWellBeing />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <OccupationalHealth />
            </div>
          </div>}
        { companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyTrainingCompletionRateWorkers />
          </div>}
        {  companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) && <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyStandards />
          </div>}
        </div>
      )}
    </div>
  );
};

export default Safety;
