// import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';
// import { Row,Col } from 'react-bootstrap';

// import ReactApexChart from 'react-apexcharts';

// const WwaterWithdrawalSingle = ({ timePeriodValues, waterType, title }) => {
//   const waterSeries = [
//     "Surface Water",
//     "Ground Water",
//     "Third Party Water",
//     "Municipal Water",
//     "Seawater / Desalinated Water",
//     "Others",
//   ];

//   const [chartOptions, setChartOptions] = useState({});
//   const [chartSeries, setChartSeries] = useState([]);
//   const [totalConsumption, setTotalConsumption] = useState(0);
//   const [totalLabel, setTotalLabel] = useState("0 KL");

//   useEffect(() => {
//     if (!Array.isArray(waterType) || waterType.length === 0) {
//       setChartSeries([]);
//       setTotalConsumption(0);
//       setTotalLabel("0 KL");
//       return;
//     }

//     const aggregatedValues = waterSeries.map((_, index) =>
//       waterType.reduce((acc, obj) => {
//         const value = obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
//         return acc + (value === "NA" || !value ? 0 : parseFloat(value || 0));
//       }, 0)
//     );

//     const total = aggregatedValues.reduce((sum, value) => sum + value, 0);
//     setTotalConsumption(total);

//     const percentages = total > 0
//       ? aggregatedValues.map((value) => (value / total) * 100)
//       : [];

//     setChartSeries(percentages);
//     setTotalLabel(`${total.toFixed(2)} KL`);

//     setChartOptions({
//       chart: {
//         type: "donut",
//       },
//       plotOptions: {
//         pie: {
//           donut: {
//             size: "55%",
//             labels: {
//               show: true,
//               name: {
//                 show: true,
//               },
//               value: {
//                 show: true,
//                 fontSize: "16px",
//                 fontWeight: "bold",
//                 color: "#333",
//                 formatter: (val) => `${parseFloat(val).toFixed(2)}%`,
//               },
//               total: {
//                 show: false,
//                 showAlways: false,
//                 fontSize: "24px",
//                 fontWeight: "bold",
//                 color: "#333",
//                 formatter: () => totalLabel, 
//               },
//             },
//           },
//         },
//       },
//       stroke: {
//         show: false,
//       },
//       tooltip: {
//         enabled: true,
//       },
//       dataLabels: {
//         enabled: true,
//       },
//       legend: {
//         show: false,
//       },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: {
//               width: 200,
//             },
//           },
//         },
//       ],
//       colors: [
//         "#2a6478",
//         "#C1DDEA",
//         "#3abec7",
//         "#deeff8",
//         "#2980B9",
//         "#27AE60",
//       ],
//       labels: waterSeries,
//     });
//   }, [waterType, timePeriodValues]);

//   return (
//     <div className="donut-chart-container">
//       <div className="donut-chart-title" style={{ height: "10%", justifyContent: "space-between" }}>
//         <div>{title}</div>
//         <div style={{ fontSize: "15px", fontWeight: "lighter" }}>
//           Total: {totalConsumption} KL
//         </div>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           alignContent: "center",
//           justifyItems: "center",
//           justifyContent: "center",
//           height: "80%",
//         }}
//       >
//         <ReactApexChart
//           options={chartOptions}
//           series={chartSeries}
//           type="donut"
//           height={"100%"}
//         />
//       </div>
//       <Row className="donut-chart-legend" style={{ marginTop: "0px" }}>
//         {waterSeries.map((seriesName, index) => (
//           <Col md={4} key={index} style={{ display: "flex", alignItems: "center" }}>
//             <span style={{
//               display: "inline-block",
//               width: "15px",
//               height: "15px",
//               borderRadius:"50%",
//               backgroundColor: chartOptions.colors ? chartOptions.colors[index] : "",
//               marginRight: "5px"
//             }}></span>
//             <span style={{fontSize:"12px"}}>{seriesName}</span>
//           </Col>
//         ))}
//       </Row>
//     </div>
//   );
// };

// export default WwaterWithdrawalSingle;
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Row, Col } from 'react-bootstrap';

const WwaterWithdrawalSingle = ({ timePeriodValues, waterType, title }) => {
  const waterSeries = [
    "Surface Water",
    "Ground Water",
    "Third Party Water",
    "Municipal Water",
    "Seawater / Desalinated Water",
    "Others",
  ];

  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [totalConsumption, setTotalConsumption] = useState(0);
  const [totalLabel, setTotalLabel] = useState("0 KL");

  useEffect(() => {
    if (!Array.isArray(waterType) || waterType.length === 0) {
      setChartSeries([]);
      setTotalConsumption(0);
      setTotalLabel("0 KL");
      return;
    }

    const aggregatedValues = waterSeries.map((_, index) =>
      waterType.reduce((acc, obj) => {
        const value = obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
        return acc + (value === "NA" || !value ? 0 : parseFloat(value || 0));
      }, 0)
    );

    const total = aggregatedValues.reduce((sum, value) => sum + value, 0);
    setTotalConsumption(total);

    setChartSeries(aggregatedValues);
    setTotalLabel(`${total.toFixed(2)} KL`);

    setChartOptions({
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "55%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "16px",
                fontWeight: "bold",
                color: "#333",
                formatter: (val) => `${val}`, // Show exact value
              },
              value: {
                show: true,
                fontSize: "16px",
                fontWeight: "bold",
                color: "#333",
                formatter: (val) => `${val}`, // Show exact value
              },
              total: {
                show: false,
                fontSize: "24px",
                fontWeight: "bold",
                color: "#333",
                formatter: () => totalLabel, // Total value
              },
            },
          },
        },
      },
      stroke: {
        show: false,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val) => `${parseFloat(val).toFixed(2)} KL`,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => `${parseFloat(val).toFixed(2)} KL`, // Show exact value
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      colors: [
        "#2a6478",
        "#C1DDEA",
        "#3abec7",
        "#deeff8",
        "#2980B9",
        "#27AE60",
      ],
      labels: waterSeries,
    });
  }, [waterType, timePeriodValues]);

  return (
    <div className="donut-chart-container">
      <div className="donut-chart-title" style={{ height: "10%", justifyContent: "space-between" }}>
        <div>{title}</div>
        <div style={{ fontSize: "15px", fontWeight: "lighter" }}>
          Total: {totalConsumption.toFixed(2)} KL
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80%",
        }}
      >
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          height={"100%"}
        />
      </div>
      <Row className="donut-chart-legend" style={{ marginTop: "0px" }}>
        {waterSeries.map((seriesName, index) => (
          <Col md={4} key={index} style={{ display: "flex", alignItems: "center" }}>
            <span style={{
              display: "inline-block",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: chartOptions.colors ? chartOptions.colors[index] : "",
              marginRight: "5px"
            }}></span>
            <span style={{ fontSize: "12px" }}>{seriesName}</span>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default WwaterWithdrawalSingle;
