import React from "react";
import "./progress.css";
import { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../../src/config/config.json";
import TopComponent from "../DashboardComponents/TopComponent";
import FrameworkComponent from "../Company Sub Admin/Pages/home/FrameworkComponent";
import AnsweredQuestions from "../AnswerComponent/answeredquestions";
import UnAnsweredQuestions from "../AnswerComponent/unansweredquestions";
import SectorProgress from "../ProgressBySector/sectorprogress";
import RecentActivity from "../RecentActivity/recentactivity";
import WorkloadProgress from "../WorkloadProgress/workloadprogress";
import SummeryGraph from "../Company Sub Admin/Pages/home/SummeryGraph";
import UnassignedPie from "../UnassignedPieChart/UnassignedPie";
import FocusAreaComponent from "../../component/Company Sub Admin/Pages/home/FocusAreaComponent";
import TeamWorkLoad from "../TeamWorkLoad/TeamWorkLoad";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";

const Progress = ({fromDate,toDate,financialYearId}) => {
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [permissionList, setPermissionList] = useState([]);

  const icons={
    "done": done,
    "updated":updated,
    "due":due,
    "pending":defaulted,
  }

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {
        // fromDate:fromDate,
        // toDate: toDate,
        // financialYearId:financialYearId
      },
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };

  const dashboardMenu = JSON.parse(localStorage.getItem("menu"));
  const dashboardObject = dashboardMenu?.find(
    (item) => item.caption === "Dashboard"
  ).permissions;

  useEffect(() => {
    setPermissionList(dashboardObject);
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponent lastWeekAcitivities={lastWeekAcitivities} icons={icons}/>
        )}
      </div>
      <div className="d-flex flex-row flex-space-between" style={{height:"80vh",marginBottom:"1%"}}>
        <div className="firsthalfprogress" style={{height:"100%"}}>
          <FrameworkComponent fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>
        </div>
        <div className="secondhalfprogress" style={{height:"100%"}}>
          <div style={{height:"10%", marginBottom:"1%"}}>
          <AnsweredQuestions fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>


          </div>
          <div style={{height:"10%",marginBottom:"1%"}}>
          <UnAnsweredQuestions fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>


          </div>
          <div style={{height:"78%"}}>
          <SectorProgress fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>


          </div>
        </div>
      </div>
      <div className="d-flex flex-row flex-space-between " style={{height:"70vh"}}>
        <div className="firsthalfprogress">
          {permissionList?.some(
            (permission) =>
              permission.permissionCode === "RECENT_ACTIVITY" &&
              permission.checked
          ) && <RecentActivity fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>}
        </div>
        <div className="secondhalfprogress">
          <WorkloadProgress fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>
        </div>
      </div>
      {/* <div className="d-flex flex-row flex-space-between secondarycont">
        <div className="firsthalfprogress">
          <SummeryGraph fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>
        </div>
        <div className="secondhalfprogress">
          <UnassignedPie fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>
  
        </div>
      </div> */}
      <div className="d-flex flex-row flex-space-between secondarycont" style={{height:"60vh"}}>
        <div className="firsthalfprogresss" style={{height:"100%"}}>
        <FocusAreaComponent fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>

        </div>
        <div className="secondhalfprogresss" style={{height:"100%", width:"60%"}}>
          <TeamWorkLoad fromDate={fromDate} toDate={toDate} financialYearId={financialYearId} user='user'/>
        </div>
      </div>
      <div className="d-flex flex-row flex-space-between secondarycont" style={{height:"60vh"}}>     
        <div className="secondhalfprogresss" style={{height:"100%",width:"60%"}}>
          <TeamWorkLoad fromDate={fromDate} toDate={toDate} financialYearId={financialYearId} user='audit'/>
        </div>
      </div>

    </div>
  );
};

export default Progress;
