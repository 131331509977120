import React from "react";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";
import TopComponent from "./components/topcomponent";
import TwoButtonComponent from "./components/twobuttoncomponent";
import AccordionComponent from "./components/accordioncomponent";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
// import { useReport } from "../sidebar/sidebar";

const OperationalModule = (props) => {
  const { moduleName } = useParams();
  // const [moduleFromRefresh,setModuleFromRefresh]=useState()

  const location = useLocation();

  // const {getReportingQuestions,module} = useReport()

  // useEffect(() => {
  //   const path = location.pathname;
  //   const segment = path.split("/reporting-modules/")[1]; // Get the part after /audit-listing/

  //   if (segment) {
  //     const formattedName = segment
  //       .replace(/-/g, " ")
  //       .replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to "General Information" format

  //     if (module && module[formattedName]) {
      

          

  //       setModuleFromRefresh( module[formattedName]);
  //     } else {
  //       setModuleFromRefresh(null);
  //     }
  //   }
  // }, [location.pathname, module ,location.state?.moduleData]);

  // const moduleData = location.state?.moduleData;
  const [moduleData, setModuleData] = useState(() => {
    // Initial state: Get from localStorage if available
    const storedData = localStorage.getItem("moduleData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [sourceData, setSourceData] = useState(() => {
    const storedData = localStorage.getItem("sourceData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [auditAssignedTo, setAuditAssignedTo] = useState([]);

  const [filteredModuleData, setFilteredModuleData] = useState(moduleData);
  const [currentUserId, setCurrentUserId] = useState();
  const [financialYear, setFinancialYear] = useState();
  const [financeObjct, setFinanceObjct] = useState();
  const [startingMonth, setStartingMonth] = useState("");
  const [managementListValue, setManagementListValue] = useState([]);
  const [assignedTo, setAssignedTo] = useState();
  const [correctModuleId, setCorrectModuleId] = useState();
  const [moduleId, setModuleId] = useState(() => {
    // Initial state: Get from localStorage if available
    const storedData = localStorage.getItem("moduleId");
    console.log("storeddata", storedData);
    return storedData ? JSON.parse(storedData) : null;
  });
  const userId = JSON.parse(localStorage.getItem("user_temp_id"));
  useEffect(() => {
    setStartingMonth(
      JSON.parse(localStorage.getItem("currentUser")).starting_month
    );
    setCurrentUserId(JSON.parse(localStorage.getItem("currentUser")).id);
    getFinancialYear();
  }, []);

  const handleAssignedDetails = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAssignedDetails`,
      {},
      { financialYearId: await getFinancialYear() },
      "GET"
    );
    if (isSuccess) {
      setAssignedTo(data.assignedDetails);
      localStorage.setItem("assignedTo", JSON.stringify(data.assignedDetails));
    }
  };
  const fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
      {},
      { type: "ALL" }
    );
    if (isSuccess) {
      return data?.data.map((item) => item.id);
    }
  };
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data[data.data.length - 1].financial_year_value);
      setFinanceObjct(data.data[data.data.length - 1].id);
      return(data.data[data.data.length - 1].id)

    }
  };

  const getReportingQuestions = async () => {
    try {
      const dataExist =  JSON.parse(localStorage.getItem("reportingQuestion"));
      if((location.state?.reportingQuestion && location.state?.reportingQuestion.length) || (dataExist && dataExist.length)){
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
        {},
        {
          financialYearId: await getFinancialYear(),
          frameworkIds: await fetchFrameworkApi()
        },
        "GET"
      );
      if (response.isSuccess) {
        const tmpLocalData =  location.state?.reportingQuestion || dataExist;

        handleAssignedDetails();
        getSource();
        const data = response.data;
        const tmpData =tmpLocalData.length ? data?.data.filter(item =>tmpLocalData.includes(item.questionId)) : data?.data;


        // const moduleNames = [...new Set(data.data.map(item => item.moduleName))];

        // setModuleNames(moduleNames);
        const groupedByModuleName = tmpData.reduce((acc, item) => {
          if (!acc["All Module"]) {
            acc["All Module"] = [];
          }
          acc["All Module"].push(item);
          if (!acc[item.moduleName]) {
            acc[item.moduleName] = [];
          }
          acc[item.moduleName].push(item);


          return acc;
        }, {});
        setModuleData(groupedByModuleName["All Module"])
        const uniqueModuleIds = Array.from(
          new Set((groupedByModuleName["All Module"]).map((item) => item.moduleId))
        );
     
        setCorrectModuleId(uniqueModuleIds);
        localStorage.setItem("moduleId", JSON.stringify(uniqueModuleIds));

        // setModule(groupedByModuleName);

        // const assignedToData = data.assignedDetail
        // setAssignedTo(assignedToData); // Assuming `setAssignedTo` is a state sette

      }}
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setSourceData(data?.data);
    }
  };
  const questionIds = moduleData?.map((item) => item.questionId);
  useEffect(() => {
    if(location.state?.reportingQuestion && location.state?.reportingQuestion.length){
      getReportingQuestions();
   
    }
  }, [location.state?.reportingQuestion]);

  useEffect(()=>{
    getReportingQuestions();

  },[])
  useEffect(() => {
    // Check if moduleData is provided in location.state
    if (location.state?.sourceData) {
      setSourceData(location.state.sourceData);
      localStorage.setItem(
        "sourceData",
        JSON.stringify(location.state.sourceData)
      );
    } else if (!sourceData) {
      const storedData = localStorage.getItem("sourceData");
      if (storedData) {
        setSourceData(JSON.parse(storedData));
      }
      else{
        getSource()
      }
    }
    if (location.state?.assignedTo) {
      setAssignedTo(location.state.assignedTo);
      localStorage.setItem(
        "assignedTo",
        JSON.stringify(location.state.assignedTo)
      );
    } else if (!assignedTo) {
      const storedData = localStorage.getItem("assignedTo");
      if (storedData) {
        setAssignedTo(JSON.parse(storedData));
      }
    }

    if (location.state?.moduleData) {
      setModuleData(location.state?.moduleData);
      const uniqueModuleIds = Array.from(
        new Set(location.state?.moduleData.map((item) => item.moduleId))
      );
      setCorrectModuleId(uniqueModuleIds);
      localStorage.setItem("moduleId", JSON.stringify(uniqueModuleIds));
      localStorage.setItem(
        "moduleData",
        JSON.stringify(location.state.moduleData)
      );
    } else if (!moduleData) {
      const storedData = localStorage.getItem("moduleData");
      const storedId = localStorage.getItem("moduleId");
      if (storedData) {
        setModuleData(JSON.parse(storedData));
      }
      if (storedId) {
        setModuleId(JSON.parse(storedId));
      }
    }
  }, [
    location.state?.moduleData,
    location.state?.assignedTo,
    location.state?.sourceData,
  ]);
  useEffect(() => {
    if (location.state?.moduleData) {
      const uniqueModuleIds = Array.from(
        new Set(location.state?.moduleData.map((item) => item.moduleId))
      );
      setCorrectModuleId(uniqueModuleIds);
    }
  }, []);

  const getDesignation = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getMasterData`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      const nonAuditorRoles = data?.data.filter(
        (role) => role.onlyauditor !== true
      );
      setManagementListValue(nonAuditorRoles?.reverse());
    }
  };

  useEffect(() => {
    setFilteredModuleData(moduleData);
  }, [moduleData]);

  // useEffect(() => {
  //   setModuleData(moduleFromRefresh);
  // }, [moduleFromRefresh]);

  const handleFilteredData = (filteredData) => {
    setFilteredModuleData(filteredData);
  };
  useEffect(() => {
    getDesignation();
  }, []);
  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <div style={{ flex: "0 0 21%", position: "sticky", top: 0, zIndex: 999 }}>
        <Sidebar
          financeObjct={financeObjct}
          dataFromParent={props.location.pathname}
        />
      </div>
      <div style={{ flex: "1 1 79%" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper p-3">
          {/* <div className="w-100 p-4 ">
            <TopComponent
              handleAssignedDetails={handleAssignedDetails}
              currentUserId={currentUserId}
              financeObjct={financeObjct}
              managementListValue={managementListValue}
              moduleName={moduleName}
              moduleData={moduleData}
            />
          </div> */}
          <div
            className="w-100"
            style={{
              paddingRight: "2.5%",
              marginLeft: "2%",
            }}
          >
            <TwoButtonComponent
              handleAssignedDetails={handleAssignedDetails}
              currentUserId={currentUserId}
              questionIds={questionIds}
              financeObjct={financeObjct}
              managementListValue={managementListValue}
              moduleName={moduleName}
              onFilteredData={handleFilteredData}
              moduleData={moduleData}
            />
          </div>
          <div className="w-100 p-4 ">
            <AccordionComponent
              sourceData={sourceData}
              modId={moduleId}
              correctModuleId={correctModuleId}
              assignedTo={assignedTo}
              currentUserId={currentUserId}
              // getReportingQuestions={getReportingQuestions}
              moduleData={filteredModuleData}
              financialYear={financialYear}
              startingMonth={startingMonth}
              financeObject={financeObjct}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationalModule;
