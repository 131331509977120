import React from 'react';
// import './TotalWaterWithdraw.css'; // Import your CSS file here

const CarbonEmissionByScopeSingle = ({ consumption, notGenerated, maxConsumption }) => {
  // Calculate the width percentages for each section of the bar
  const totalWidth = 100; // 100% width of the bar
  const filledWidth = (consumption / maxConsumption) * totalWidth;
  const notGeneratedWidth = (notGenerated / maxConsumption) * totalWidth;
  const remainingWidth = totalWidth - (filledWidth + notGeneratedWidth);

  return (
    <div className="water-withdrawn-container">
      <div className="water-withdrawn-header">
       Scope 1 and Scope 2 Comparison
      </div>
      <div className="water-withdrawn-bar-labels">
        <span style={{fontSize:"11px"}}>0</span>
        <span style={{fontSize:"11px"}}>{maxConsumption / 5}</span>
        <span style={{fontSize:"11px"}}>{(maxConsumption / 5) * 2}</span>
        <span style={{fontSize:"11px"}}>{(maxConsumption / 5) * 3}</span>
        <span style={{fontSize:"11px"}}>{(maxConsumption / 5) * 4}</span>
        <span style={{fontSize:"11px"}}>{maxConsumption}</span>
      </div>
      <div className="water-withdrawn-bar-dotted-line"></div>

      <div className="water-withdrawn-bars">
        <div className="water-withdrawn-bar" style={{marginBottom:"2%", backgroundColor:"rgba(28, 28, 28, 0.05)", border:"none"}}>
          <div 
            className="water-withdrawn-bar-filled" 
            style={{ width: `${filledWidth}%`, backgroundColor: '#3ABEC7', borderTopRightRadius:"10px", borderBottomRightRadius:"10px" }}
          />
        </div>
        <div className="water-withdrawn-bar" style={{marginBottom:"2%", backgroundColor:"rgba(28, 28, 28, 0.05)", border:"none"}}>
          <div 
            className="water-withdrawn-bar-not-generated" 
            style={{ width: `${notGeneratedWidth}%`, backgroundColor: '#11546f', borderTopRightRadius:"10px", borderBottomRightRadius:"10px" }}
          />
        </div>
      </div>
      <div className="water-withdrawn-legend" style={{display:"flex", gap:"10%"}}>
    <div className="legend-item">
      <span
        className="legend-color"
        style={{
          backgroundColor: "#3ABEC7",
          display: "inline-block",
          width: "15px",
          height: "15px",
          marginRight: "8px",
          borderRadius: "50%",
        }}
      ></span>
      <span className="legend-label">Scope 2</span>
    </div>
    <div className="legend-item">
      <span
        className="legend-color"
        style={{
          backgroundColor: "#11546f",
          display: "inline-block",
          width: "15px",
          height: "15px",
          marginRight: "8px",
          borderRadius: "50%",
        }}
      ></span>
      <span className="legend-label">Scope 1</span>
    </div>
  </div>
    </div>
  );
};

export default CarbonEmissionByScopeSingle;
