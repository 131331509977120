import React from "react";
import { Table, Modal, Button } from "react-bootstrap";
import "./TabularQuestionType.css";
import { useState } from "react";

const TabularQuestionType = ({
  title,
  answer,
  item,
  question_detail,
  note,
  menu,
   combinedAnswers,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [fullText, setFullText] = useState("");

  const handleDoubleClick = (text) => {
    setFullText(text);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  console.log("itemxs", item);

  const rows = question_detail?.filter(
    (detail) => detail.option_type === "row"
  ) || [
    { id: 1, option: "Row 1" },
    { id: 2, option: "Row 2" },
    { id: 3, option: "Row 3" },
  ];
  const columns = question_detail?.filter(
    (detail) => detail.option_type === "column"
  ) ||   [{ id: 1, option: "Column 1" },
  { id: 2, option: "Column 2" },
  { id: 3, option: "Column 3" },]

  const FullTextModal = ({ show, handleClose, fullText }) => (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Full Text</Modal.Title>
      </Modal.Header>
      <Modal.Body>{fullText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const wrapTextWithLineBreaks = (text, wordsPerLine) => {
    if (!text) return "";
    const words = text.split(" ");
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(" "));
    }
    return lines.join("<br />");
  };

  

  return (
    <div
      className="tabular-question w-100"
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <Table
        bordered
        hover
        responsive="sm"
        className="custom-table"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <thead
          style={{
            borderRight: "none",
            borderLeft: "none",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <tr style={{ borderRight: "none", borderLeft: "none",background:"#F4F7F8" }}>
            {/* Empty header cell for row labels */}
            <th
              className="header-celll"
              style={{
                border:"2px solid #83BBD5",
                borderTopLeftRadius:"10px",
                paddingTop:"15px",
            
                verticalAlign:"top",
                textAlign: "left",

              }}
            >
              <div >#</div>
            </th>
            {(menu === 'audit' ? [...columns].reverse() : columns)?.map((column,index) => (
              <th
                key={column?.id}
                style={{
                  borderRight: "2px solid #83BBD5",
                  borderLeft: "none",
                  verticalAlign:"top",
             
                  textAlign: "left",
                  borderTop:"2px solid #83BBD5",
                  borderTopRightRadius: index === columns.length - 1 ? "10px" : "0px"
                  
                }}
                className="header-celll"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: wrapTextWithLineBreaks(column?.option, 5),
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingTop:"5px",
                    alignItems: "flex-start",
                  }}
                ></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            borderRight: "none",
            borderLeft: "none",
            width: "100%",
            maxWidth: "100%",
          }}
        >
       
          {rows.map((row, rowIndex) => (
            <tr
              key={row?.id}
              style={{ borderRight: "none", borderLeft: "none",background:"#F4F7F8",  borderRight: "2px solid #83BBD5", }}
            >
              <td
                className="row-label"
                style={{ borderRight: "2px solid #83BBD5", borderLeft: "2px solid #83BBD5" }}
              >
                {row?.option}
              </td>
              {columns?.map((column, colIndex) => {
                let cellText;
                if (menu === "audit") {
                  cellText =
                    combinedAnswers !== "No Combined" &&
                    combinedAnswers[rowIndex] &&
                    combinedAnswers[rowIndex][colIndex] !== undefined
                      ? combinedAnswers[rowIndex][colIndex]
                      : "";
                } else {
                  cellText =
                    combinedAnswers !== "No Combined" &&
                    combinedAnswers[0]?.answer[rowIndex] &&
                    combinedAnswers[0]?.answer[rowIndex][colIndex] !== undefined
                      ? combinedAnswers[0]?.answer[rowIndex][colIndex]
                      : "";
                }

                const displayText =
                (cellText ? cellText : " ").length > 100
                  ? cellText.substring(0, 100) + "..."
                  : cellText || " ";
              

                return (
                  <td
                    key={column?.id}
                    style={{
                      borderRight: "2px solid #83BBD5",
                      borderLeft: "none",
                      cursor: "pointer",
                    }}
                    className="data-cell"
                    onDoubleClick={() => handleDoubleClick(cellText)}
                  >
                    {displayText}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {note && (
        <>
          <div
            style={{
              color: "black",
              fontSize: "14px",
              fontFamily: "Open Sans",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            {" "}
            Note{" "}
          </div>

          <div
            style={{
              color: "#3F88A5",
              fontSize: "10px",
              fontFamily: "Open Sans",
              marginBottom: "10px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            {note}
          </div>
        </>
      )}
      <FullTextModal
        show={showModal}
        handleClose={handleClose}
        fullText={fullText}
      />
    </div>
  );
};

export default TabularQuestionType;
