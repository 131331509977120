import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import {ContextDataProvider} from './contextApi';
import PermissionBasedMenuProvider from "./contextApi/permissionBasedMenuContext";
// import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <>
    {/* <CookiesProvider> */}
    <ContextDataProvider>
      <PermissionBasedMenuProvider>
       <App />
       </PermissionBasedMenuProvider>
    </ContextDataProvider>
    {/* </CookiesProvider> */}
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
