import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./TotalEnergyConsum.css";

const TotalEnergyConsumption = ({fromDate, toDate, financialYearId,graphData,numLocations}) => {
  console.log(fromDate, toDate, financialYearId,graphData,"fghjkuijdsoarewii4fey");
  const [selectedYear, setSelectedYear] = useState("2023-24");

  const data = {
    "2023-24": {
      Location1: [2000, 4000, 3000, 3500],
      Location2: [1500, 3500, 2800, 3000],
    },
    "2022-23": {
      Location1: [2500, 4500, 3200, 3800],
      Location2: [2000, 4000, 3000, 3400],
    },
  };

  const singleLocationOptions = {
    colors: ["#11546F"],
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
  };

  const stackedLocationOptions = {
    colors: ["#11546F", "#6BA4A0"],
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
  };

  const chartSeries = numLocations === 1
    ? [
        {
          name: "Energy Consumption",
          data: data[selectedYear].Location1,
        },
      ]
    : [
        {
          name: "Location1",
          data: data[selectedYear].Location1,
        },
        {
          name: "Location2",
          data: data[selectedYear].Location2,
        },
      ];

  const chartOptions = numLocations === 1
    ? singleLocationOptions
    : stackedLocationOptions;

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    const filteredData = graphData && graphData.filter(item => (item.questionId == 451 || item.questionId == 452));
    const datafilter = {
      h1: "2023-01",
      h2: "2023-07",
    };
    console.log(filteredData)
  }, []);

  return (
    <div className="whitecont" style={{ width: "100%", height: "100%" }}>
      <div className="d-flex flex-row flex-space-between w-100" style={{ justifyContent: "space-between", height: "10%" }}>
        <div className="ener-title">
          Total Energy Consumption
        </div>
        {/* Optionally include a year selector */}
        {/* <select value={selectedYear} onChange={handleYearChange}>
          <option value="2023-24">2023-24</option>
          <option value="2022-23">2022-23</option>
        </select> */}
      </div>
      <div style={{ height: "90%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default TotalEnergyConsumption;
