import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const HumanRightsTraining = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {
  const [selectedOption, setSelectedOption] = useState("Employees"); // Default option
  const [companyFramework, setCompanyFramework] = useState([]);
  const [periodSelection, setPeriodSelection] = useState([""]);
  const employees = ["Permanant", "Non-Permanant"];
  const colorPalette= [
    "#11546F",
    "#DB8080",
    "#9CDFE3",
    "#587B87",
    "#9CDFE3",
    "#FF8C33",
    "#33FFF4",
    "#9DFF33",
    "#FF3333",
    "#3377FF",
    "#FF7F50",
    "#FFD700",
    "#8A2BE2",
    "#D2691E",
    "#00FA9A",
  ];

  const [dataOptions,setDataOptions] = useState({
    Employees: [
      { name: "permanent", data: [80, 120, 150, 200] },
      { name: "other than permanent", data: [60, 100, 130, 180] },
    ],
    Workers: [
      { name: "permanent", data: [60, 100, 130, 160] },
      { name: "other than permanent", data: [40, 80, 110, 140] },
    ],
  });

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false, // This disables the toolbar
      },
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      categories: periodSelection,
    },
    legend: {
    show: false, // This hides the legend
  },
    colors: [
      "#11546F",
      "#DB8080",
      "#9CDFE3",
      "#587B87",
      "#9CDFE3",
      "#FF8C33",
      "#33FFF4",
      "#9DFF33",
      "#FF3333",
      "#3377FF",
      "#FF7F50",
      "#FFD700",
      "#8A2BE2",
      "#D2691E",
      "#00FA9A",
    ], // Custom colors for the chart
  };

  useEffect(() => {
    if (frameworkValue && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  useEffect(() => {
    if (companyFramework && companyFramework.length) {
      if (companyFramework.includes(1)) {
        const transformedKeys = Object.keys(timePeriods).map((key) =>
          key.toUpperCase()
        );
        setPeriodSelection(transformedKeys);

        const dataOptions = {
          Employees: [
            {
              name: "permanent",
              data: new Array(transformedKeys.length).fill(0),
            },
            {
              name: "other than permanent",
              data: new Array(transformedKeys.length).fill(0),
            },
          ],
          Workers: [
            {
              name: "permanent",
              data: new Array(transformedKeys.length).fill(0),
            },
            {
              name: "other than permanent",
              data: new Array(transformedKeys.length).fill(0),
            },
          ],
        };

        const answer =
          graphData &&
          graphData.length &&
          graphData.filter((item) => item.questionId === 236);
        if (answer && answer.length) {
          for (let k = 0; k < dataOptions.Employees[0].data.length; k++) {
            let time = transformedKeys[k];
            const lowerCaseKey = time.toLowerCase();
            const formDate = timePeriods[lowerCaseKey];

            const filterData = answer.find(
              (item) => item.formDate === formDate
            );
            dataOptions.Employees[0].data[k] = filterData.answer[0][0] || 0;
            dataOptions.Employees[1].data[k] = filterData.answer[0][1] || 0;
            dataOptions.Workers[0].data[k] = filterData.answer[1][0] || 0;
            dataOptions.Workers[1].data[k] = filterData.answer[1][1] || 0;
          }
        }
        setDataOptions(dataOptions);
      }
    }
  }, [locationOption, timePeriods, graphData,companyFramework]);

  return (
    <div className="container" style={{height:"100%"}}>
      <h2 style={{ fontSize: "1em", marginLeft: "1%" }}>Human Rights Training</h2>
      {<div className="radio-buttons" style={{height:"10%"}}>
        {["Employees", "Workers"].map((option) => (
          <label key={option}>
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={(e) => setSelectedOption(e.target.value)}
            />
            {option.replace(/([A-Z])/g, " $1").trim()}{" "}
          </label>
        ))}
      </div>}

      <div className="chart-container" style={{height:"80%"}}>
        <Chart
          options={chartOptions}
          series={dataOptions[selectedOption]}
          type="area"
          height="100%"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          height: "10%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {employees.map((gender, index) => (
          <div
            key={gender}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: colorPalette[index],
                borderRadius: "50%", // Make it a circle
              }}
            ></div>
            <span>{gender}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HumanRightsTraining;
