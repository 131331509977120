// SocketComponent.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import config from "../../config/config.json";

const SocketComponent = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const userId = currentUser?.id;

    if (!userId) {
      console.error("User ID is not available. Cannot establish WebSocket connection.");
      return;
    }

    const newSocket = io(`${config.BASE_API_URL_COMPANY}`, {
      query: { userId: userId },
    });

    newSocket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error.message);
    });

    newSocket.on('connect', () => {
      if (newSocket.connected) {
        newSocket.emit('addUser');

      }
    });

    newSocket.on(`notification${userId}`, (data) => {
      console.log('Received message:', data);

      // Swal.fire({
      //   title: "Sweet!",
      //   text: "data",
      //   imageUrl: "https://unsplash.it/400/200",
      //   imageWidth: 400,
      //   imageHeight: 200,
      //   imageAlt: "Custom image"
      // });
      alert('New Notification: ' + data);
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
        console.log('Disconnected from the WebSocket server.');
      }
    };
  }, []);

  return (
    <></>
  );
};

export default SocketComponent;
