import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentTraining from "./TopComponentTraining";
import HealthAndSafety from "./HealthAndSafety";
import ComparisonOfHealthAndSafety from "./ComparisonOfHealthAndSafetyMeasures";
import SkillUpgradationTraining from "./SkillUpgradationTraing";
import ComparisonOfSkillUpgradationTraining from "./ComparisonOfSkillUpgradationTraining";
import PerformanceReviewChart from "./PerformanceReviewChart";
import HumanRightsTraining from "./HumanRightsTraining";
import HealthSafetyMeasuresTraining from "./HealthAndSafetyMeasures";
import SafetyTrainingCompletionRate from "./SafetyTrainingCompletionRate";
import ComparisonOfSkillTraining from "./ComparisonOfSkillTraining";
import DetailsOfPerfomance from "./DetailsOfPerformance";
import LeadershipAndMentor from "./LeadershipAndMentor";
import HumanRightsTrainingBarChart from "./HumanRightsTrainingBarChart";
import Details from "./Details";
import LeadershipAndMentorship from "./Leadership";

const Training = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  horizontal,
  type,
}) => {
 

  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
  const [chartData, setChartData] = useState({
    series: [],
    categories: ["Male", "Female"],
  });
  const [chartDataTwo, setChartDataTwo] = useState({
    series: [],
    categories: ["Male", "Female"],
  });
  
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
  
    if (isSuccess) {
      if (chartData?.series?.length && chartData.series[0]?.data?.length) {
        const lastWeekData = {
          "Employees Trained on Health and Safety": {
            number: parseInt(chartData.series[0].data[2], 10),
            questionId: [],
          },
          "Employees Trained on Skill Upgradation": {
            number: parseInt(chartDataTwo.series[0]?.data[2], 10),
            questionId: [],
          },
          "Workers Trained on Health and Safety": {
            number: parseInt(chartData.series[1]?.data[2], 10),
            questionId: [],
          },
          "Workers Trained on Skill Upgradation": {
            number: parseInt(chartDataTwo.series[1]?.data[2], 10),
            questionId: [],
          },
          message: "Good Evening, Sunil Kumar",
        };
        setLastWeekAcitivities(lastWeekData);
      }
    }
  };
  
  const [trainingEmployee, setTrainingEmployee] = useState([]);
  const [trainingWorker, setTrainingWorker] = useState([]);
  const [performanceEmployee, setPerformanceEmployee] = useState([]);
  const [performanceWorker, setPerformanceWorker] = useState([]);
  const [companyFramework, setCompanyFramework] = useState(0);
  
  useEffect(() => {
    const empDiffIncl = graphData?.filter(
      (item) => item.title === "Details of Training Given to Employees"
    ) || [];
    
    const workDiffIncl = graphData?.filter(
      (item) => item.title === "Details of Training Given to workers"
    ) || [];
    
    const perE = graphData?.filter(
      (item) =>
        item.title ===
        "Details of Performance and Career Development Reviews of Employees"
    ) || [];
    
    const perW = graphData?.filter(
      (item) =>
        item.title ===
        "Details of Performance and Career Development Reviews of workers"
    ) || [];
  
    const matchedDiffIncl = empDiffIncl?.filter((item) => 
      Object.values(timePeriods || {}).includes(item.formDate)
    ) || [];
  
    const matchedWorkDiffIncl = workDiffIncl?.filter((item) => 
      Object.values(timePeriods || {}).includes(item.formDate)
    ) || [];
  
    const perEE = perE?.filter((item) => 
      Object.values(timePeriods || {}).includes(item.formDate)
    ) || [];
  
    const perWW = perW?.filter((item) => 
      Object.values(timePeriods || {}).includes(item.formDate)
    ) || [];
  
    setTrainingEmployee(matchedDiffIncl);
    setTrainingWorker(matchedWorkDiffIncl);
    setPerformanceEmployee(perEE);
    setPerformanceWorker(perWW);
  
    const aggregateData = (data) => {
      let totalMales = 0;
      let totalFemales = 0;
  
      data?.forEach((item) => {
        const answers = item.answer || [[], []];
        const males = parseInt(answers[0]?.[0], 10) || 0;
        const females = parseInt(answers[0]?.[1], 10) || 0;
  
        totalMales += males;
        totalFemales += females;
      });
  
      const total = totalMales + totalFemales;
  
      return { totalMales, totalFemales, total };
    };
  
    const employeeData = aggregateData(matchedDiffIncl);
    const workerData = aggregateData(matchedWorkDiffIncl);
  
    const employeeTotal = employeeData.totalMales + employeeData.totalFemales;
    const workerTotal = workerData.totalMales + workerData.totalFemales;
  
    setChartData({
      series: [
        {
          name: "Employees",
          data: [employeeData.totalMales, employeeData.totalFemales, employeeTotal],
        },
        {
          name: "Workers",
          data: [workerData.totalMales, workerData.totalFemales, workerTotal],
        },
      ],
      categories: ["Male", "Female", "Total"],
    });
  
    const employeeDataTwo = aggregateData(perEE);
    const workerDataTwo = aggregateData(perWW);
  
    const employeeTotalTwo = employeeDataTwo.totalMales + employeeDataTwo.totalFemales;
    const workerTotalTwo = workerDataTwo.totalMales + workerDataTwo.totalFemales;
  
    setChartDataTwo({
      series: [
        {
          name: "Employees",
          data: [employeeDataTwo.totalMales, employeeDataTwo.totalFemales, employeeTotalTwo],
        },
        {
          name: "Workers",
          data: [workerDataTwo.totalMales, workerDataTwo.totalFemales, workerTotalTwo],
        },
      ],
      categories: ["Male", "Female", "Total"],
    });
  }, [graphData, timePeriods]);
  
  useEffect(() => {
    if (chartData?.series?.length || chartDataTwo?.series?.length) {
      lastWeekActivity();
    }
  }, [chartData, chartDataTwo]);
  
  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  
  return (
    <div className="progress-container">
      {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentTraining
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>}

  
      {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1)?  <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <HealthAndSafety
              trainingEmployee={trainingEmployee}
              trainingWorker={trainingWorker}
            />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{width:"50%"}}>
              <SkillUpgradationTraining
                trainingEmployee={trainingEmployee}
                trainingWorker={trainingWorker}
              />
            </div>
            <div className="secondhalfprogress" style={{width:"50%"}}>
              <Details
                performanceEmployee={performanceEmployee}
                performanceWorker={performanceWorker}
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
           <LeadershipAndMentorship consumption={5} maxConsumption={20} notGenerated={2}/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HumanRightsTraining />
          </div> */}
        </div> :(
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            {/* <HealthSafetyMeasuresTraining /> */}
            <div className="firsthalfprogressenergy" style={{ height: "100%" }}>
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type="EMPLOYEE"
                horizontal={false}
                forGraph="H&S"
              />
            </div>
            <div className="secondhalfprogress" style={{ height: "100%" }}>
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type="WORKERS"
                horizontal={true}
                forGraph="H&S"
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          > */}
          {/* <ComparisonOfHealthAndSafety
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            /> */}
          {/* </div> */}
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ height: "100%" }}>
              {/* <SafetyTrainingCompletionRate />
               */}
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type="EMPLOYEE"
                horizontal={false}
                forGraph="S&U"
              />
            </div>
            <div className="secondhalfprogress">
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type="WORKERS"
                horizontal={true}
                forGraph="S&U"
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <DetailsOfPerfomance />
            </div>
            <div className="secondhalfprogress">
              <LeadershipAndMentor />
            </div>
          </div> */}
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HumanRightsTraining
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HumanRightsTrainingBarChart />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Training;
