import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import "./esg.css";
import Select from "react-select";

const Esgdownload = ({
  setFinancialYearId,
  setShow,
  financialYear,
  setFinancialYear,
  setTimePeriods,
  setLocationOption,

  downloadPdf,
  show,
  handleClose,
}) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [meterList, setMeterList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [selectedYear, setSelectedYear] = useState("2023-2024");

  const handleBRSRDownload = () => {
    const url =
      "https://riu-bucket.s3.ap-south-1.amazonaws.com/uploads/Business%20responsibility%20and%20sustainability%20report.pdfBusiness%20responsibility%20and%20sustainability%20report.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handleBRSRGudeLineDownload = () => {
    const url =
      "https://riu-bucket.s3.ap-south-1.amazonaws.com/uploads/Business%20responsibility%20and%20sustainability%20reporting%20by%20listed%20entitiesAnnexure2_p.PDFBusiness%20responsibility%20and%20sustainability%20reporting%20by%20listed%20entitiesAnnexure2_p.PDF";
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  useEffect(() => {
    if (currentUser?.frequency === "HALF_YEARLY") {
      setSelectedPeriod(["H1"]);
    } else if (currentUser?.frequency === "YEARLY") {
      setSelectedPeriod(["Y1"]);
    } else if (currentUser?.frequency === "QUARTERLY") {
      setSelectedPeriod(["Q1"]);
    } else if (currentUser?.frequency === "MONTHLY") {
      setSelectedPeriod(["M1"]);
    }
  }, []);

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setFinancialYear(data.data);
      const lastEntry = data.data[data.data.length - 1];
      setSelectedYear(lastEntry.financial_year_value);
      setFinancialYearId(lastEntry.id);
    }
  };

  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        unitCode: item?.unitCode,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setMeterList(locationArray);
    }
  };
  useEffect(() => {
    getFinancialYear();
    getSource();
  }, []);

  const handleSelectionChange = (selectedOptions) => {
    setLocationOption(selectedOptions);
    setSelectedLocations(selectedOptions);
  };
  const calculateDateRange = (type, period, startingMonth, year) => {
    const startMonth = ((startingMonth - 1 + (period - 1) * type) % 12) + 1;

    const startYear =
      year + Math.floor((startingMonth - 1 + (period - 1) * type) / 12);
    const endMonth = ((startMonth - 1 + type) % 12) + 1;
    const endYear = startYear + Math.floor((startMonth - 1 + type) / 12);

    const formatDate = (month, year) =>
      `${year}-${month < 10 ? `0${month}` : month}`;

    return {
      fromDate: formatDate(startMonth, startYear),
      toDate: formatDate(endMonth, endYear),
    };
  };

  const periodOptions = {
    HALF_YEARLY: [
      { value: "H1", label: "H1" },
      { value: "H2", label: "H2" },
    ],
    QUARTERLY: [
      { value: "Q1", label: "Q1" },
      { value: "Q2", label: "Q2" },
      { value: "Q3", label: "Q3" },
      { value: "Q4", label: "Q4" },
    ],
    MONTHLY: Array.from({ length: 12 }, (_, i) => ({
      value: `M${i + 1}`,
      label: `M${i + 1}`,
    })),
    YEARLY: [{ value: "Y1", label: "Y1" }],
  };

  const handleYearChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = financialYear.find(
      (year) => year.financial_year_value === selectedValue
    );
    setSelectedYear(selectedValue);
    setFinancialYearId(selectedOption?.id || "");
  };

  const handlePeriodChange = (selectedOptions) => {
    //  if(selectedYear){
    setSelectedPeriod(selectedOptions);
    const newTimePeriods = {};
    const year = parseInt(selectedYear.split("-")[0]);

    selectedOptions.forEach((period) => {
      let dateRange;

      if (period?.value?.startsWith("H")) {
        const halfYear = period.value === "H1" ? 1 : 2;
        dateRange = calculateDateRange(
          6,
          halfYear,
          currentUser.starting_month,
          year
        );
      } else if (period?.value?.startsWith("Q")) {
        const quarter = parseInt(period.value.replace("Q", ""));
        dateRange = calculateDateRange(
          3,
          quarter,
          currentUser.starting_month,
          year
        );
      } else if (period?.value?.startsWith("M")) {
        const month = parseInt(period.value.replace("M", ""));
        dateRange = calculateDateRange(
          1,
          month,
          currentUser.starting_month,
          year
        );
      } else if (period?.value === "Y1") {
        dateRange = calculateDateRange(12, 1, currentUser.starting_month, year);
      }

      if (dateRange) {
        newTimePeriods[period?.value?.toLowerCase()] = dateRange?.fromDate;
      }
    });

    setTimePeriods(newTimePeriods);
    //  }
  };

  const locationOptions = meterList.map((item) => ({
    value: item.location,
    label: item.location,
    unitCode: item?.unitCode,
    id: item?.id,
  }));

  // Automatically select the first location and period after data is loaded
  useEffect(() => {
    if (meterList.length > 0 && selectedLocations.length === 0) {
      const firstLocation = {
        value: meterList[0].location,
        label: meterList[0].location,
        unitCode: meterList[0].unitCode,
        id: meterList[0].id,
      };
      setSelectedLocations([firstLocation]);
      setLocationOption([firstLocation]);
    }
    const periodOptionsForFrequency =
      periodOptions[currentUser?.frequency] || [];
    if (periodOptionsForFrequency.length > 0 && selectedPeriod.length === 0) {
      const firstPeriod = periodOptionsForFrequency[0];
      setSelectedPeriod([firstPeriod]);
      handlePeriodChange([firstPeriod]);
    }
  }, [meterList, selectedPeriod, selectedLocations]);

  const handleCloseFilter = () => setShowFilter(false);
  return (
    <div style={{ width: "100%" }}>
      <div className="color_div_Current mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="color_rent mb-0">
            <button
              // disabled={true}

              onClick={() => {
                setShow(true);
              }}
              className="esg_button_style"
            >
              Download Report
            </button>
          </div>

          <div className="color_rent mb-0 leftmarg">
            <Form.Select
              className="esg_text2"
              onChange={handleYearChange}
              value={selectedYear}
              style={{ padding: ".375rem 2.25rem .375rem .75rem" }}
            >
              {financialYear.map((year) => (
                <option key={year.id} value={year.financial_year_value}>
                  FY - {"           "}
                  {year.financial_year_value}
                </option>
              ))}
            </Form.Select>
          </div>
          {/* {renderPeriodSelect()} */}

          <div className="color_rent mb-0 leftmarg">
            <button
              // disabled={true}

              onClick={() => {
                setShowFilter(true);
              }}
              className="esg_button_style"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <label className="align-items-center">ESG REPORT</label>
        </Modal.Header>
        <Modal.Body>
          <p>DOWNLOAD YOUR REQUIRED REPORT BELOW</p>
        </Modal.Body>
        <Modal.Footer>
          <div
           
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "40%" }}>
              <button
                onClick={() => {
                  handleBRSRDownload();
                }}
                className="new_button_style"
                style={{width:"100%",fontSize:"10px"}}
              >
                BRSR Report Format
              </button>
            </div>
            <div style={{ width: "40%" }}>
              <button
                // disabled={true}

                onClick={() => {
                  handleBRSRGudeLineDownload();
                }}
                className="new_button_style"
                style={{width:"100%", fontSize:"10px"}}
              >
                BRSR Guideline
              </button>
            </div>
            
          </div>
          <div style={{width:"100%", display:"flex",  justifyContent: "space-between",}}>
          <div style={{ width: "40%" }}>
              <button
                className="new_button_style"
                style={{width:"100%",fontSize:"10px"}}
                onClick={() => {
                  downloadPdf('WORD');
                }}
              >
                DOWNLOAD ESG REPORT {"("} Word Documents {")"}
              </button>
            </div>
            <div style={{ width: "40%" }}>
              <button
                className="new_button_style"
                style={{width:"100%",fontSize:"10px"}}
                onClick={() => {
                  downloadPdf('PDF');
                }}
              >
                DOWNLOAD ESG REPORT {"("} PDF {")"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={showFilter} onHide={handleCloseFilter}>
        <Modal.Header closeButton>
          {/* <label className="align-items-center">ESG REPORT</label> */}
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="color_rent mb-0">
              {currentUser?.frequency === "HALF_YEARLY" && (
                <Select
                  isMulti
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  options={periodOptions[currentUser.frequency] || []}
                  placeholder="Select Periods"
                  className="esg_text2"
                  styles={{
                    control: (base) => ({
                      ...base,
                      paddingLeft: "20px",
                      paddingRight: "80px",
                    }),
                  }}
                />
              )}
              {currentUser?.frequency === "QUARTERLY" && (
                <Select
                  isMulti
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  options={periodOptions[currentUser.frequency] || []}
                  placeholder="Select Periods"
                  className="esg_text2"
                  styles={{
                    control: (base) => ({
                      ...base,
                      paddingLeft: "20px",
                      paddingRight: "80px",
                    }),
                  }}
                />
              )}
              {currentUser?.frequency === "MONTHLY" && (
                <Select
                  isMulti
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  options={periodOptions[currentUser.frequency] || []}
                  placeholder="Select Periods"
                  className="esg_text2"
                  styles={{
                    control: (base) => ({
                      ...base,
                      paddingLeft: "20px",
                      paddingRight: "80px",
                    }),
                  }}
                />
              )}
              {currentUser?.frequency === "YEARLY" && (
                <Select
                  isMulti
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  options={periodOptions[currentUser.frequency] || []}
                  placeholder="Select Periods"
                  className="esg_text2"
                  styles={{
                    control: (base) => ({
                      ...base,
                      paddingLeft: "20px",
                      paddingRight: "80px",
                    }),
                  }}
                />
              )}
            </div>
            <div className="color_rent mb-0 ">
              <Select
                isMulti
                value={selectedLocations}
                onChange={handleSelectionChange}
                options={locationOptions || []}
                placeholder="Select Locations"
                className="esg_text2"
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingLeft: "20px",
                    paddingRight: "80px",
                  }),
                }}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Esgdownload;
