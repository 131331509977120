import React from "react";

import Sidebar from "../../sidebar/sidebar";
import Header from "../../header/header";
import TopComponent from "../../OperationalModule/components/topcomponent";
import TwoButtonComponent from "../../OperationalModule/components/twobuttoncomponent";
import AccordionComponent from "../../OperationalModule/components/accordioncomponent";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { useAudit } from "../../sidebar/sidebar";

const NewAuditList = (props) => {
  const menu = "audit";
  const location = useLocation();

  const [auditModuleFromRefresh, setAuditModuleFromRefresh] = useState();
  const [auditAssignedTo, setAuditAssignedTo] = useState([]);
  
  const { getAuditListing, auditModule } = useAudit();
  // const { auditModuleData } = location.state || {};
  const [auditModuleData, setAuditModuleData] = useState();

  useEffect(() => {
    if (
      location.state?.reportingQuestion &&
      location.state?.reportingQuestion.length
    ) {
      console.log("1");
      getAuditListings();
    } else {
      console.log("2");

      const path = location.pathname;
      const segment = path.split("/audit-listing/")[1]; // Get the part after /audit-listing/

      if (segment) {
        const formattedName = segment
          .replace(/-/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to "General Information" format

        if (auditModule && auditModule[formattedName]) {
          const currentUserId = JSON.parse(
            localStorage.getItem("currentUser")
          )?.id;

          const filteredModuleModules = auditModule[formattedName].filter(
            (item) => {
              return item.matchingAuditors?.some((auditor) => {
                if (auditor.remark === null) {
                  return true;
                }
                if ( Number(auditor?.auditerId) === Number(currentUserId)) {
                  console.log(item?.title,"f");

                  return true;
                }

                return auditor.remark?.every(
                  (remark) => remark.id != currentUserId
                );
              });
            }
          );

          // setAuditModuleFromRefresh(filteredModuleModules);
          setFilteredModuleData(filteredModuleModules);
        } else {
          // setAuditModuleFromRefresh(null);
          setFilteredModuleData(null);
        }
      }
    }
  }, [location.pathname, auditModule, location?.state?.auditModuleData]);
  const { moduleName } = useParams();

  const [moduleData, setModuleData] = useState(() => {
    // Initial state: Get from localStorage if available
    const storedData = localStorage.getItem("auditModuleData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [sourceData, setSourceData] = useState(() => {
    const storedData = localStorage.getItem("sourceData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [filteredModuleData, setFilteredModuleData] = useState(moduleData);

  const [financialYear, setFinancialYear] = useState();
  const [financeObjct, setFinanceObjct] = useState();
  const [startingMonth, setStartingMonth] = useState("");
  const [correctModuleId, setCorrectModuleId] = useState();
  const [currentUserId, setCurrentUserId] = useState();
  const [managementListValue, setManagementListValue] = useState([]);
  const [assignedTo, setAssignedTo] = useState();
  const [moduleId, setModuleId] = useState(() => {
    // Initial state: Get from localStorage if available
    const storedData = localStorage.getItem("auditModuleId");
    return storedData ? JSON.parse(storedData) : null;
  });
  const userId = JSON.parse(localStorage.getItem("user_temp_id"));
  useEffect(() => {
    setStartingMonth(
      JSON.parse(localStorage.getItem("currentUser")).starting_month
    );
    setCurrentUserId(JSON.parse(localStorage.getItem("currentUser")).id);
    const id = getFinancialYear();
  }, []);
  const getAuditListings = async () => {
   const dataExist =  JSON.parse(localStorage.getItem("reportingQuestion"));
    if((location.state?.reportingQuestion && location.state?.reportingQuestion.length) || (dataExist && dataExist.length))
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
        {},
        {
          financialYearId: await getFinancialYear(),
          // frameworkIds: [1]
        },
        "GET"
      );
      if (response.isSuccess) {
        handleAssignedDetails();
        getSource();
        const tmpLocalData =  location.state?.reportingQuestion || dataExist;
        const data = response.data;
        const tmpData =tmpLocalData.length
          ? data?.data.filter((item) =>
             tmpLocalData.includes(
                item.question.questionId
              )
            )
          : data?.data;

        const moduleNamesSet = new Set();

        // Object to group data by module
        const groupedData = tmpData.reduce((acc, item) => {
          const moduleName = item.question?.moduleName || "Unknown Module";

          // Add module name to the set
          moduleNamesSet.add(moduleName);

          // Initialize array if not already done
          if (!acc["All Module"]) {
            acc["All Module"] = [];
          }
          acc["All Module"].push(item);
          if (!acc[moduleName]) {
            acc[moduleName] = [];
          }

          // Add item to the module's array
          acc[moduleName].push(item);
          return acc;
        }, {});

        const moduleNamesList = Array.from(moduleNamesSet);

        setAuditModuleData(groupedData);
        const path = location.pathname;
        const segment = path.split("/audit-listing/")[1]; // Get the part after /audit-listing/
  
        if (segment) {
          const formattedName = segment
            .replace(/-/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to "General Information" format
  
          if (groupedData && groupedData[formattedName]) {
            const currentUserId = JSON.parse(
              localStorage.getItem("currentUser")
            )?.id;
  
            const filteredModuleModules = groupedData[formattedName].filter(
              (item) => {
                return item.matchingAuditors?.some((auditor) => {
                  if (auditor.remark === null) {
                    return true;
                  }
                  // if ( auditor?.auditerId != currentUserId) {
                  //   return true;
                  // }
                  if ( Number(auditor?.auditerId) === Number(currentUserId)) {
                    console.log(item?.title,"f");
  
                    return true;
                  }
  
                  return auditor.remark?.every(
                    (remark) => remark.id != currentUserId
                  );
                });
              }
            );
  
            // setAuditModuleFromRefresh(filteredModuleModules);
            setFilteredModuleData(filteredModuleModules);
          } else {
            // setAuditModuleFromRefresh(null);
            setFilteredModuleData(null);
          }
        }
        // setFilteredModuleData(groupedData["All Module"]);

        const uniqueModuleIds = Array.from(
          new Set(
            groupedData["All Module"].map((item) => item?.question?.moduleId)
          )
        );
        setCorrectModuleId(uniqueModuleIds);
        localStorage.setItem("auditModuleId", JSON.stringify(uniqueModuleIds));
        // setAuditModuleNames(moduleNamesList);

        const assignedToData = data.getAssignedDetails

        setAuditAssignedTo(assignedToData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data[data.data.length - 1].financial_year_value);
      setFinanceObjct(data.data[data.data.length - 1].id);
      return data.data[data.data.length - 1].id;
    }
  };

  const questionIds = moduleData?.map((item) => item.questionId);

  const handleFilteredData = (filteredData) => {
    setFilteredModuleData(filteredData);
  };

  const handleAssignedDetails = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAssignedDetails`,
      {},
      { financialYearId: await getFinancialYear() },
      "GET"
    );
    if (isSuccess) {
      setAssignedTo(data.assignedDetails);
      localStorage.setItem(
        "auditAssignedTo",
        JSON.stringify(data.assignedDetails)
      );
    }
  };

  useEffect(() => {
    if (location.state?.sourceData) {
      setSourceData(location.state.sourceData);
      localStorage.setItem(
        "sourceData",
        JSON.stringify(location.state.sourceData)
      );
    } else if (!sourceData) {
      const storedData = localStorage.getItem("sourceData");
      if (storedData) {
        setSourceData(JSON.parse(storedData));
      }
    }

    if (location.state?.auditAssignedTo) {
      setAssignedTo(location?.state?.auditAssignedTo);
      localStorage.setItem(
        "auditAssignedTo",
        JSON.stringify(location?.state?.auditAssignedTo)
      );
    } else if (!assignedTo) {
      handleAssignedDetails();

    }
    if (location.state?.auditModuleData) {
      // console.log(location?.state?.auditModuleData,"sdfghfdsertrgrfdsaertg")
      const path = location.pathname;
      const segment = path.split("/audit-listing/")[1]; // Get the part after /audit-listing/

      if (segment) {
        const formattedName = segment
          .replace(/-/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to "General Information" format

        if (location.state?.auditModuleData) {
          const currentUserId = JSON.parse(
            localStorage.getItem("currentUser")
          )?.id;

          let filteredModuleModules;

          if (location.state?.auditModuleData[formattedName]) {
            filteredModuleModules = location.state?.auditModuleData[
              formattedName
            ].filter((item) => {
              return item.matchingAuditors?.some((auditor) => {
                if (auditor.remark === null) {
                  return true;
                }

                // if ( auditor?.auditerId != currentUserId) {
                //   return true;
                // }
                if ( Number(auditor?.auditerId) === Number(currentUserId)) {
                  console.log(item?.title,"f");

                  return true;
                }

                return auditor.remark?.every(
                  (remark) => remark.id != currentUserId
                );
              });
            });
          } else {
            filteredModuleModules = location.state?.auditModuleData.filter(
              (item) => {
                return item.matchingAuditors?.some((auditor) => {
                  if (auditor.remark === null) {
                    return true;
                  }
                  if ( Number(auditor?.auditerId) === Number(currentUserId)) {
                    console.log(item?.title,"f");
  
                    return true;
                  }

                  return auditor.remark?.every(
                    (remark) => remark.id != currentUserId
                  );
                });
              }
            );
          }

          // setAuditModuleFromRefresh(filteredModuleModules);
          setFilteredModuleData(filteredModuleModules);
          const uniqueModuleIds = Array.from(
            new Set(
              location.state?.auditModuleData.map(
                (item) => item.question.moduleId
              )
            )
          );
          setCorrectModuleId(uniqueModuleIds);
          localStorage.setItem(
            "auditModuleId",
            JSON.stringify(uniqueModuleIds)
          );
          localStorage.setItem(
            "auditModuleData",
            JSON.stringify(filteredModuleModules)
          );
        } else {
          // setAuditModuleFromRefresh(null);
          setFilteredModuleData(null);
        }
      }
      // setModuleData(location?.state?.auditModuleData || auditModuleData["All Module"]);
      // setModuleData(auditModuleFromRefresh);

      // const uniqueModuleIds = Array.from(
      //   new Set(
      //     location.state?.auditModuleData.map((item) => item.question.moduleId)
      //   )
      // );
      // setCorrectModuleId(uniqueModuleIds);
      // localStorage.setItem("auditModuleId", JSON.stringify(uniqueModuleIds));
      // localStorage.setItem(
      //   "auditModuleData",
      //   JSON.stringify(location?.state?.auditModuleData)
      // );
    } else if (!moduleData) {
      // Load moduleData from localStorage if not already set
      const storedData = localStorage.getItem("auditModuleData");
      const storedId = localStorage.getItem("auditModuleId");
      if (storedData) {
        setModuleData(JSON.parse(storedData));
      }
      if (storedId) {
        setModuleId(storedId);
      }
    }
  }, [
    location.state?.auditModuleData,
    location.state?.auditAssignedTo,
    location.state?.sourceData,
  ]);

  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setSourceData(data?.data);
    }
  };

  useEffect(() => {
    if (
      location.state?.reportingQuestion &&
      location.state?.reportingQuestion.length
    ) {
      getAuditListings();
    
    }
  }, [location.state?.reportingQuestion]);

  useEffect(()=>{
    getAuditListings();

  },[])

  // useEffect(() => {
  //   setFilteredModuleData(moduleData);
  // }, [moduleData]);

  const getDesignation = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getMasterData`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      const nonAuditorRoles = data?.data.filter(
        (role) => role.onlyauditor !== true
      );
      setManagementListValue(nonAuditorRoles?.reverse());
    }
  };

  useEffect(() => {
    getDesignation();
    getSource();
  }, []);

  useEffect(() => {
    return localStorage.removeItem("auditModuleData");
  }, []);

  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <div style={{ flex: "0 0 21%", position: "sticky", top: 0, zIndex: 999 }}>
        <Sidebar
          financeObjct={financeObjct}
          dataFromParent={props.location.pathname}
        />
      </div>
      <div style={{ flex: "1 1 79%" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper p-3">
          {/* <div className="w-100 p-4 ">
            <TopComponent
              handleAssignedDetails={handleAssignedDetails}
              currentUserId={currentUserId}
              financeObjct={financeObjct}
              managementListValue={managementListValue}
              moduleName={moduleName}
              moduleData={auditModuleData}
            />
          </div> */}
          <div
            className="w-100"
            style={{
              paddingRight: "2.5%",
              marginLeft: "2%",
            }}
          >
            <TwoButtonComponent
              handleAssignedDetails={handleAssignedDetails}
              currentUserId={currentUserId}
              questionIds={questionIds}
              menu={menu}
              financeObjct={financeObjct}
              managementListValue={managementListValue}
              moduleName={moduleName}
              onFilteredData={handleFilteredData}
              // moduleData={auditModuleData}
              moduleData={filteredModuleData}
            />
          </div>
          <div className="w-100 p-4 ">
            <AccordionComponent
              modId={moduleId}
              sourceData={sourceData}
              currentUserId={currentUserId}
              assignedTo={assignedTo || auditAssignedTo}
              getAuditListing={getAuditListing}
              correctModuleId={correctModuleId}
              menu={menu}
              moduleData={filteredModuleData}
              financialYear={financialYear}
              startingMonth={startingMonth}
              financeObject={financeObjct}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAuditList;
