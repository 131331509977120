import React from "react";
import "./TopComponentEnvironment.css"
const TopComponentEnvironment = ({ lastWeekActivities, icons }) => {
  console.log(lastWeekActivities)
  const filteredActivities = Object.entries(lastWeekActivities)
    .filter(([key, value]) => key !== "message")
    .map(([key, value]) => ({ key, value }));

  const onSelect = (data) => {
   
    localStorage.setItem("questionIds", data);
    console.log("data in local storage", localStorage.getItem("questionIds"));
    window.location.href = "/#/sector_questions";
  };

  return (
    <div className="topcompcontainer">
      {filteredActivities.map(({ key, value }, index) => (
        <div
          key={key}
          className={
            index !== filteredActivities.length - 1 ? "divvWithBorder" : ""
          }
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            console.log(lastWeekActivities?.done?.questionId.length);
            if (value.questionId.length !== 0) {
              onSelect(value.questionId.length);
            }
          }}
        >
          <div className="firsthalf" style={{paddingTop:"5%"}}>
            <div className="h3-spacing" style={{color: "#011627",fontWeight:"600", fontSize:"24px"}}>{value.number}</div>
            <div className="" style={{color: "#011627",fontFamily:"Poppins",fontSize:"16px"}}>
              {key === "pending"
                ? "Defaulted"
                : key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </div>
            {/* <p className="ptop">In the last 7 days</p> */}
          </div>
          {/* <div className="secondhalff">
            <div className="secondhalf">
              <img src={icons[key]} alt="icon" style={{ height: '20px', width: '20px' }}/>
            </div>
          </div> */}
          {/* <h3>{key}</h3>
                    <p>{value}</p> */}
        </div>
      ))}
    </div>
  );
};

export default TopComponentEnvironment;
