import React from "react";
import { useState } from "react";
import Table from "react-bootstrap/Table";
import "../../Sector Questions/TabularQuestionType.css";
import StatusIndicator from "./StatusIndicator";
import "../../../../ProgressBySector/sectorprogress.css";
import Filter from "../../../../../img/Filter.png";
import ReactDOM from "react-dom";
import MultiSelectDropdown from "./MultiSelectDropwdown";
import { Dropdown, Form, DropdownButton,InputGroup } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import { FaFilter , FaSearch} from "react-icons/fa";

const TopicDetailComponent = ({ topicData, answers, assignedDetails }) => {
  console.log(
    "this is topic data",
    topicData,
    assignedDetails,
    "this is answer",
    answers
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: -150,
    right: 50,
  });

  const [searchTerm, setSearchTerm] = useState('');

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleDropdownToggle = (event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelect = (options) => {
    setSelectedOptions(options);
  };

  const options = ["ACCEPTED", "ANSWERED", "REJECTED"];

  const splitString = (str, length) => {
    const regex = new RegExp(`(.{1,${length}})`, "g");
    return str.match(regex).join("\n");
  };

  const getPadding = (title) => {
    // Check if title is not null or undefined
    if (!title) return "2px";

    // Split the title into words
    const words = title.split(" ");

    // Return appropriate padding based on the number of words
    return words.length > 6 ? "20px" : "5px";
  };

  const filteredByStatus = selectedOptions.length === 0
    ? topicData
    : topicData.filter((item) => {
        const foundData = answers?.find(
          (data) => data.questionId === item.id
        );
        return foundData ? selectedOptions.includes(foundData.status) : false;
      });

      const filteredTopicData = filteredByStatus.filter((item) =>
        item?.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );

  // const filteredTopicData =
  //   selectedOptions.length === 0
  //     ? topicData
  //     : topicData.filter((item) => {
  //         const foundData = answers?.find(
  //           (data) => data.questionId === item.id
  //         );
  //         return foundData ? selectedOptions.includes(foundData.status) : false;
  //       });

  return (
    <div
      className="tabular-question w-100"
      style={{
        width: "100%",
        maxWidth: "100%",
        padding: "20px",

        background: "white",

        borderRadius: "10px",
      }}
    >
      <div className="search-bar-wrapper" style={{width:"100%",marginLeft:"0%",marginRight:"0%", backgroundColor:"transparent",marginBottom:"2%"}}>
        <InputGroup className="search-bar h-100" style={{backgroundColor:"transparent"}}>
          <InputGroup.Text id="basic-addon1" style={{backgroundColor:"transparent"}}>
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            placeholder="Search Questions"
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            aria-describedby="basic-addon1"
            style={{backgroundColor:"transparent"}}
          />
        </InputGroup>
      </div>
      <div
        className="scroll-container"
        style={{ width: "100%", height: "80vh", overflow: "auto", }}
      >
        <Table
          a
          style={{ width: "100%", maxWidth: "100%", overflow: "auto",  }}
          bordered
          className="custom-table table-hover scroll-container"
        >
          <thead
            style={{
              borderRight: "none",
              borderLeft: "none",
              position: "sticky",
              top: -6,
              background:"white",
              width: "100%",
              maxWidth: "100%",
              borderRadius: "10px",
              zIndex: 1,
            }}
          >
            <tr
              style={{
                borderRight: "none",
                borderLeft: "none",
                borderRadius: "10px",
              }}
            >
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "left",
                  backgroundColor: "white",
                  width: "15%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "black",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "10px",
                  // borderBottomLeftRadius:"10px",
                  // borderTopLeftRadius:"10px",

                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                #
              </th>
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "left",
                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  width: "10%",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "10px",

                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                Question Heading
              </th>
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "left",
                  backgroundColor: "white",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "black",
                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "10px",

                  width: "20%",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                Department
              </th>
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "left",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "14px",
                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  width: "20%",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "10px",

                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                Data Owner
              </th>

              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "left",
                  backgroundColor: "white",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "black",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  fontSize: "10px",

                  width: "20%",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                Data Checker
              </th>
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  backgroundColor: "white",
                  textAlign: "left",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "black",
                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "10px",

                  width: "20%",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                Status
              </th>

              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "left",
                  width: "25%",
                  whiteSpace: "nowrap",
                  backgroundColor: "white",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  overflow: "auto",
                  color: "black",
                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "10px",
                }}
              >
                Evidence
              </th>
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  color: "black",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  backgroundColor: "white",
                  fontSize: "10px",

                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  textAlign: "left",
                  width: "20%",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                Remarks
              </th>
              <th
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  color: "black",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontWeight: "700",
                  fontFamily: "'Montserrat', sans-serif",
                  backgroundColor: "white",
                  fontSize: "10px",
                  // borderBottomRightRadius:"10px",
                  // borderTopRightRadius:"10px",

                  borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                  textAlign: "left",
                  width: "20%",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                <FaFilter
                  onClick={handleDropdownToggle}
                  style={{
                    cursor: "pointer",
                   height:"20px",
                   width:"20px",
                    color: "black",
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              borderRight: "none",
              borderLeft: "none",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            {filteredTopicData.map((item, index) => (
              <tr
                key={index}
                style={{ borderRight: "none", borderLeft: "none" }}
              >
                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderLeft: "none",
                    width: "15%",
                    fontFamily: "'Montserrat', sans-serif",
                    // Numeric value, no quotes needed
                    fontSize: "10px",
                    borderTop: '1px solid rgba(0, 80, 164, 0.1)',
                    whiteSpace: "nowrap",

                    overflow: "auto",
                    boxShadow:  "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {item?.report_id}
                </td>
                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    borderLeft: "none",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    width: "80px",
                    paddingTop: getPadding(item?.title),
                    paddingBottom: getPadding(item?.title),

                    whiteSpace: "pre-wrap",
                    overflow: "auto",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {splitString(item?.title, 40)}
                </td>
                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    borderLeft: "none",

                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    width: "20%",
                    whiteSpace: "nowrap",
                    overflow: "auto",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {(() => {
                    const foundData = assignedDetails?.find(
                      (data) => data.questionId === item.id
                    );
                    return foundData
                      ? foundData.assignedToDetails[0].designation
                      : "No Department"; // Replace 'someProperty' with the actual property you want to display
                  })()}
                </td>
                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",

                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderLeft: "none",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    width: "20%",
                    whiteSpace: "nowrap",
                    overflow: "auto",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {(() => {
                    const foundData = assignedDetails?.find(
                      (data) => data.questionId === item.id
                    );
                    return foundData
                      ? foundData.assignedToDetails[0].first_name
                      : "No Owner"; // Replace 'someProperty' with the actual property you want to display
                  })()}
                </td>

                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    borderLeft: "none",
                    width: "20%",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    whiteSpace: "nowrap",
                    overflow: "auto",
                  }}
                >
                  {(() => {
                    const foundData = assignedDetails?.find(
                      (data) => data.mainQuestionId === item.id
                    );
                    console.log(foundData); // Log foundData to the console for debugging
                    return foundData
                      ? foundData?.auditorDetails[0]?.first_name
                      : "No Auditor"; // Replace 'someProperty' with the actual property you want to display
                  })()}
                </td>
                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    borderLeft: "none",

                    width: "20%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    whiteSpace: "nowrap",
                    overflow: "auto",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {(() => {
                    const foundData = answers?.find(
                      (data) => data.questionId === item.id
                    );
                    return (
                      <StatusIndicator
                        status={foundData ? foundData.status : "No Status"}
                      />
                    ); // Replace 'someProperty' with the actual property you want to display
                  })()}
                </td>

                <td
                  style={{
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderLeft: "none",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    width: "25%",

                    whiteSpace: "nowrap",
                    overflow: "auto",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {(() => {
                    const foundData = answers?.find(
                      (data) => data.questionId === item.id
                    );
                    if (foundData && Array.isArray(foundData.proofDocument)) {
                      return foundData?.proofDocument?.map((doc, index) => (
                        <div key={index}>
                          <a
                            href={doc}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link {index + 1}
                          </a>
                        </div>
                      ));
                    }
                    return "No Evidence";
                  })()}
                </td>
                <td
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRight: "1px solid rgba(0, 80, 164, 0.1)",
                    borderLeft: "none",
                    color: "black!important",
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "10px",
                    borderTop: "1px solid rgba(0, 80, 164, 0.1)",
                    width: "20%",
                    whiteSpace: "nowrap",
                    overflow: "auto",
                    boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  Remarks
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {dropdownOpen &&
          ReactDOM.createPortal(
            <div
              className="dropdown-container"
              style={{
                position: "absolute",
                height: "20vh",
                width: "12vw",
                top: dropdownPosition.top,
                left: dropdownPosition.left - 140,
                right: dropdownPosition.right,
                marginRight: "20px",
                backgroundColor: "white",
                border: "1px solid rgba(0, 80, 164, 0.1)",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                zIndex: 1000,
                padding: "10px",
              }}
            >
              <MultiSelectDropdown
                options={options}
                selectedOptions={selectedOptions}
                onSelect={handleSelect}
              />
            </div>,
            document.body
          )}
      </div>
    </div>

    // <div
    //   className="tabular-question w-100"
    //   style={{
    //     width: "100%",
    //     maxWidth: "100%",
    //     padding: "20px",
    //     background: "white",
    //     overflow:"auto",
    //     borderRadius: "10px",
    //   }}
    // >
    //   <Table
    //     a
    //     style={{ width: "100%", maxWidth: "100%",overflow:"auto", }}
    //     bordered
    //     hover
    //     className="custom-table"
    //   >
    //     <thead
    //       style={{
    //         borderRight: "none",
    //         borderLeft: "none",
    //         width: "100%",
    //         maxWidth: "100%",
    //       }}
    //     >
    //       <tr style={{ borderRight: "none", borderLeft: "none" }}>
    //         <th
    //
    //           style={{
    //             borderRight: "none",
    //             borderLeft: "none",
    //             textAlign: "left",
    //           }}
    //         >
    //           Sr Number
    //         </th>
    //         <th
    //           className="header-celll"
    //           style={{
    //             borderRight: "none",
    //             borderLeft: "none",
    //             textAlign: "left",
    //           }}
    //         >
    //           Question Heading
    //         </th>
    //         <th
    //           className="header-celll"
    //           style={{
    //             borderRight: "none",
    //             borderLeft: "none",
    //             textAlign: "left",
    //           }}
    //         >
    //           Data Owner
    //         </th>
    //         <th
    //           className="header-celll"
    //           style={{
    //             borderRight: "none",
    //             borderLeft: "none",
    //             textAlign: "left",
    //           }}
    //         >
    //           Data Checker
    //         </th>
    //         <th
    //           className="header-celll"
    //           style={{
    //             borderRight: "none",
    //             borderLeft: "none",
    //             textAlign: "left",
    //           }}
    //         >
    //           Evidence
    //         </th>
    //       </tr>
    //     </thead>
    //     <tbody
    //       style={{
    //         borderRight: "none",
    //         borderLeft: "none",
    //         width: "100%",
    //         maxWidth: "100%",
    //       }}
    //     >
    //       {topicData.map((item, index) => (
    //         <tr key={index} style={{ borderRight: "none", borderLeft: "none" }}>
    //           <td
    //
    //             style={{ borderRight: "1px solid #83BBD5", borderLeft: "none", width:"15%!important",overflow:"auto" }}
    //           >
    //             {item?.report_id}
    //           </td>
    //           <td
    //
    //             style={{ borderRight: "1px solid #83BBD5", borderLeft: "none", width:"15%!important",overflow:"auto" }}
    //           >
    //             {item?.title}
    //           </td>
    //           <td
    //
    //             style={{ borderRight: "1px solid #83BBD5", borderLeft: "none", width:"15%!important",overflow:"auto" }}
    //           >
    //             {(() => {
    //               const foundData = assignedDetails?.find(
    //                 (data) => data.questionId === item.id
    //               );
    //               return foundData
    //                 ? foundData.assignedToDetails[0].first_name
    //                 : "No Owner"; // Replace 'someProperty' with the actual property you want to display
    //             })()}
    //           </td>

    //           <td
    //
    //             style={{ borderRight: "1px solid #83BBD5", borderLeft: "none", width:"15%!important",overflow:"auto" }}
    //           >
    //             {(() => {
    //               const foundData = answers?.find(
    //                 (data) => data.questionId === item.id
    //               );
    //               return foundData
    //                 ? foundData.auditorName
    //                 : "No Auditor"; // Replace 'someProperty' with the actual property you want to display
    //             })()}
    //           </td>

    //           <td
    //
    //             style={{ borderRight: "1px solid #83BBD5", borderLeft: "none", width:"15%!important",overflow:"auto" }}
    //           >
    //             {(() => {
    //               const foundData = answers?.find(
    //                 (data) => data.questionId === item.id
    //               );
    //               if (foundData && Array.isArray(foundData.proofDocument)) {
    //                 return foundData?.proofDocument?.map((doc, index) => (
    //                   <div key={index}>{doc}</div>
    //                 ));
    //               }
    //               return "No Evidence";
    //             })()}
    //           </td>
    //         </tr>
    //       ))}
    //     </tbody>
    //   </Table>
    // </div>
  );
};

export default TopicDetailComponent;
