import React, { useEffect } from "react";
import TopComponentEnergy from "./TopComponentEnergy";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import TotalEnergyConsumption from "./TotalEnergyConsumption";
import "./energy.css";
import ProductEnergyConsumption from "./ProductEnergyConsumption";
import LocationEnergyConsumption from "./LocationEnergyConsumption";
import TimeBasedProductMix from "./TimeBasedProductMix";
import LocationWise from "./LocationWise";
import OverTheYear from "./OverTheYear";
import ProductWiseEnergyConsumption from "./ProductWiseEnergyConsumption";
import EnergyIntensity from "./EnergyIntensity";
import TotalProductWise from "../Company Sub Admin/Pages/home/TotalProductWise";
import EnergyConsumptionBar from "../Environment/EnergyConsumptionBar";
import ProductWiseSingleLoc from "./ProductWiseSingleLoc";
import EnergyIntensitySingleLoc from "./EnergyIntensitySingleLoc";
import EnergyEfficiencyImprovement from "./EnergyEffImprove";
import Renewable from "../Environment/RenewableAndNoneRenew";
import DonutChart from "../Environment/DonutChart";
import OnSiteSingleLoc from "./OnSiteSingleLoc";
import GreenEnergyPurchaseSingle from "./GreenEnergyPurchaseSingle";
import WaterDonutChart from "../Environment/DonutChart";

const Energy = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
}) => {
 
  const location = 2;
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  const [companyFramework, setCompanyFramework] = useState([]);
  const sectorData = sectorQuestionAnswerDataForGraph?.[1]?.["answer"] || [];

  const [renewableEnergy, setRenewableEnergy] = useState(Number(sectorData?.[4]?.[0] || 0));
  const [nonRenewableEnergy, setNonRenewableEnergy] = useState(Number(sectorData?.[9]?.[0] || 0));
  const [renElec, setRenElec] = useState(Number(sectorData?.[1]?.[0] || 0));
  const [renFuel, setRenFuel] = useState(Number(sectorData?.[3]?.[0] || 0));
  const [nonrenElec, setNonrenElec] = useState(Number(sectorData?.[6]?.[0] || 0));
  const [nonrenFuel, setNonrenFuel] = useState(Number(sectorData?.[7]?.[0] || 0));

  
  const icons = {
    0: done,
    1: updated,
    2: due,
    3: defaulted,
  };
  

  
  useEffect(() => {
    const sectorData = JSON.parse(sectorQuestionAnswerDataForGraph?.[1]?.["answer"] || '[]');
    setRenewableEnergy(Number(sectorData?.[4]?.[0] || 0));
    setNonRenewableEnergy(Number(sectorData?.[9]?.[0] || 0));
    setRenElec(Number(sectorData?.[1]?.[0] || 0));
    setRenFuel(Number(sectorData?.[3]?.[0] || 0));
    setNonrenElec(Number(sectorData?.[6]?.[0] || 0));
    setNonrenFuel(Number(sectorData?.[7]?.[0] || 0));
    const activityData = {
      "Total Energy": {
        number: `${Number(sectorData?.[4]?.[0] || 0) + Number(sectorData?.[9]?.[0] || 0)} GJ`,
        questionId: [],
      },
      "Renewable Energy": {
        number: `${Number(sectorData?.[4]?.[0] || 0)} GJ`,
        questionId: [],
      },
      "Non Renewable Energy": {
        number: `${Number(sectorData?.[9]?.[0] || 0)} GJ`,
        questionId: [],
      },
      message: "Good Evening, Sunil Kumar",
    };
    setLastWeekActivities(activityData);
  }, [sectorQuestionAnswerDataForGraph]);


  
  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  return (
    <div className="progress-container">
      {<div className="topcompo">
        {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) && lastWeekActivities && (
          <TopComponentEnergy
            lastWeekActivities={lastWeekActivities}
            icons={icons}
          />
        )}
      </div>}
     
      {
        (companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
          <div
           className="d-flex flex-row flex-space-between"
           style={{ height: "22vh", marginBottom: "3%" }}
         >
           <EnergyConsumptionBar  sectorQuestionAnswerDataForGraph={sectorQuestionAnswerDataForGraph} graphData={graphData} />
         </div>
         <div
           className="d-flex flex-row flex-space-between"
           style={{ height: "50vh", marginBottom: "3%" }}
         >
           <div className="firsthalfprogressenergy" style={{width:"50%"}}>
             {/* <TotalEnergyConsumption numLocations={location} /> */}
             <ProductWiseSingleLoc renElec={renElec} nonrenElec={nonrenElec} renFuel={renFuel} nonrenFuel={nonrenFuel}/>
           </div>
           <div className="secondhalfprogress">
             <div style={{height:"30%"}}>
               {/* <EnergyIntensitySingleLoc/> */}

             </div>
             <div style={{height:"70%"}}>
               {/* <EnergyEfficiencyImprovement/> */}
               
             </div>
            
           </div>
         </div>
         <div
           className="d-flex flex-row flex-space-between"
           style={{ height: "22vh", marginBottom: "3%" }}
         >
           <Renewable consumption={renewableEnergy} notGenerated={nonRenewableEnergy} maxConsumption={renewableEnergy+nonRenewableEnergy} />
         </div>

         {/* <div
           className="d-flex flex-row flex-space-between"
           style={{ height: "70vh", marginBottom: "3%" }}
         >
           <div className="secondhalfprogress" style={{width:"30%"}}>
           <WaterDonutChart
                 percentageConsumed={60}
                 percentageNotConsumed={40}
                 title="Energy Savings Overview"
                 color="#4cc200" // Custom color for the chart
               />
             <LocationEnergyConsumption />
           </div>
           <div className="firsthalfprogressenergy" style={{width:"70%"}}>
             <OnSiteSingleLoc title={"On Site Renewable Energy Generation"}/>
           </div>
         </div>
         <div
           className="d-flex flex-row flex-space-between"
           style={{ height: "30vh", marginBottom: "3%" }}
         >
          <GreenEnergyPurchaseSingle  consumption={5} notGenerated={20} maxConsumption={30}/>
         </div> */}
       </div>
        ) : (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%", width: "76vw" }}
            >
              <ProductWiseEnergyConsumption
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
              />
            </div>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%", width: "76vw" }}
            >
              <EnergyIntensity
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Energy;
