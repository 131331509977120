import React, { useState } from 'react';

const IncidentRateAnalysis = () => {
  const [selectedCategory, setSelectedCategory] = useState('workers'); // Default category

  // Define the consumption, notGenerated, and maxConsumption values for each category
  const dataOptions = {
    workers: {
      consumption: 400,
      notGenerated: 200,
      maxConsumption: 1000,
    },
    employees: {
      consumption: 600,
      notGenerated: 150,
      maxConsumption: 1000,
    },
    otherThanPermanentWorkers: {
      consumption: 300,
      notGenerated: 100,
      maxConsumption: 1000,
    },
    otherThanPermanentEmployees: {
      consumption: 500,
      notGenerated: 250,
      maxConsumption: 1000,
    },
  };

  // Get the data for the selected category
  const { consumption, notGenerated, maxConsumption } = dataOptions[selectedCategory];

  // Calculate the width percentages for each section of the bar
  const totalWidth = 100; // 100% width of the bar
  const filledWidth = (consumption / maxConsumption) * totalWidth;
  const notGeneratedWidth = (notGenerated / maxConsumption) * totalWidth;
  const remainingWidth = totalWidth - (filledWidth + notGeneratedWidth);

  return (
    <div className="water-withdrawn-container">
      <div className="water-withdrawn-header">
  Incident Rate Analysis
      </div>
      
      {/* Radio Buttons */}
      <div className="radio-buttons" style={{ marginBottom: '20px' }}>
        <label>
          <input
            type="radio"
            value="workers"
            checked={selectedCategory === 'workers'}
            onChange={() => setSelectedCategory('workers')}
          />
          Workers
        </label>
        <label>
          <input
            type="radio"
            value="employees"
            checked={selectedCategory === 'employees'}
            onChange={() => setSelectedCategory('employees')}
          />
          Employees
        </label>
        <label>
          <input
            type="radio"
            value="otherThanPermanentWorkers"
            checked={selectedCategory === 'otherThanPermanentWorkers'}
            onChange={() => setSelectedCategory('otherThanPermanentWorkers')}
          />
          Other than Permanent Workers
        </label>
        <label>
          <input
            type="radio"
            value="otherThanPermanentEmployees"
            checked={selectedCategory === 'otherThanPermanentEmployees'}
            onChange={() => setSelectedCategory('otherThanPermanentEmployees')}
          />
          Other than Permanent Employees
        </label>
      </div>

      <div className="water-withdrawn-bar-labels">
        <span style={{ fontSize: "11px" }}>0</span>
        <span style={{ fontSize: "11px" }}>{maxConsumption / 5}</span>
        <span style={{ fontSize: "11px" }}>{(maxConsumption / 5) * 2}</span>
        <span style={{ fontSize: "11px" }}>{(maxConsumption / 5) * 3}</span>
        <span style={{ fontSize: "11px" }}>{(maxConsumption / 5) * 4}</span>
        <span style={{ fontSize: "11px" }}>{maxConsumption}</span>
      </div>
      <div className="water-withdrawn-bar-dotted-line"></div>

      <div className="water-withdrawn-bars">
        <div className="water-withdrawn-bar" style={{ marginBottom: "2%" }}>
          <div
            className="water-withdrawn-bar-filled"
            style={{ width: `${filledWidth}%`, backgroundColor: 'green' }}
          />
        </div>
        <div className="water-withdrawn-bar" style={{ marginBottom: "2%" }}>
          <div
            className="water-withdrawn-bar-not-generated"
            style={{ width: `${notGeneratedWidth}%`, backgroundColor: 'orange' }}
          />
        </div>
        <div className="water-withdrawn-bar" style={{ marginBottom: "2%" }}>
          <div
            className="water-withdrawn-bar-remaining"
            style={{ width: `${remainingWidth}%`, backgroundColor: 'lightgray' }}
          />
        </div>
      </div>
    </div>
  );
};

export default IncidentRateAnalysis;
