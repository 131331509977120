import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const Details = ({ performanceEmployee, performanceWorker }) => {
  const [selectedData, setSelectedData] = useState('employees'); // Default data selection
  const [chartData, setChartData] = useState({
    series: [],
    categories: ['Male', 'Female'],
  });

  useEffect(() => {
    const aggregateData = (data) => {
      let totalMales = 0;
      let totalFemales = 0;

        data.forEach(item => {
        const answers = item.answer || [[], []];
        const males = parseInt(answers[0][0], 10) || 0;
        const females = parseInt(answers[0][1], 10) || 0;

        totalMales += males;
        totalFemales += females;
      });

      return [totalMales, totalFemales];
    };

    const data =
      selectedData === 'employees'
        ? aggregateData(performanceEmployee)
        : aggregateData(performanceWorker);

    setChartData({
      series: [
        {
          name: selectedData === 'employees' ? 'Employees' : 'Workers',
          data,
        },
      ],
      categories: ['Male', 'Female'],
    });
  }, [selectedData, performanceEmployee, performanceWorker]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        barHeight:"30%",
        borderRadius: 8,
        endingShape: 'rounded',
        distributed: true
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          colors: 'grey',
        },
        offsetY: -175,
        offsetX: 10,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    colors: ['#11566f', '#db8080'],
    legend: {
      show: false, // Hides the legend
    },
  };

  return (
    <div className="container">
      <div className="header" style={{height:"8%"}}>
        <div className="title">Health and Safety Measures Training</div>
      </div>
      <div className='checkboxwaste' style={{ height: "10%" , marginLeft:"1%"}}>
        <label>
          <input
            type="radio"
            value="employees"
            checked={selectedData === 'employees'}
            onChange={() => setSelectedData('employees')}
          />
          Employees
        </label>
        <label>
          <input
            type="radio"
            value="workers"
            checked={selectedData === 'workers'}
            onChange={() => setSelectedData('workers')}
          />
          Workers
        </label>
      </div>

      <div className="chart-container" style={{ marginTop: "-2%", height: "75%" }}>
        <Chart options={options} series={chartData.series} type="bar" height={"100%"} />
      </div>
      <div className="legend-container" style={{ width: "100%", height: "10%", display: "flex", alignContent: "center", justifyContent: "center" }}>
        <div className="legend-item">
          <span className="legend-color" style={{ backgroundColor: '#11566f', borderRadius: "50px" }}></span>
          Male
        </div>
        <div className="legend-item">
          <span className="legend-color" style={{ backgroundColor: '#db8080', borderRadius: "50px" }}></span>
          Female
        </div>
      </div>
    </div>
  );
};

export default Details;
