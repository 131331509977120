import React from 'react';
// import './EnergyConsumption.css'; // Import your CSS file here

const TotalEmissionGeneratedSingle = ({ consumption, notGenerated }) => {
  // Calculate the width of the filled portion of the bar based on consumption
  const filledWidth = (1 / 1) * 100;
  let totalEnergy=consumption+notGenerated;

  return (
    <div className="energy-bar-container">
      <div className="energy-bar-header">
        Total Emission Generated
      </div>
      <div className="energy-bar-labels">
      <span>{0}</span>
        <span>{((1 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((2 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((3 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((4 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((5 / 5) * totalEnergy).toFixed(2)}</span>
      </div>
      <div className="energy-bar-dotted-line"></div>

      <div className="energy-bar">
        <div 
          className="energy-bar-filled" 
          style={{ width: `${filledWidth}%` }}
        />
      </div>
     
    </div>
  );
};

export default TotalEmissionGeneratedSingle;
