import React, { useEffect, useState } from "react";
import { Form, Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import RowComponent from "./RowComponent";
import LeftArrow from "../../../../img/LeftArrow.svg";
import RightArrow from "../../../../img/RightArrow.svg";
import { apiCall } from "../../../../_services/apiCall";
import { FaEdit } from "react-icons/fa";
import swal from "sweetalert";

import config from "../../../../config/config.json";
import { FaFileDownload, FaTrash } from "react-icons/fa";
import { DetailModal } from "./DetailModal";
const TabularComponent = ({
  sourceData,
  answerId,
  getAuditListing,
  setAnsId,
  auditItem,
  // getReportingQuestions,
  financeObject,
  currentUserId,
  assignedToDetails,
  savedAnswers,
  auditorId,
  answer,
  setAnswer,
  menu,
  selectedPeriod,
  item,
  toDate,
  fromDate,
  sourceChange,
}) => {
  const [selectedSource, setSelectedSource] = useState("");
  const [note, setNote] = useState(answer?.note || [[""]]);
  const [answerIdReal, setAnswerIdReal] = useState();
  const [auditButton, setAuditButton] = useState(false);

  const [sourceName, setSourceName] = useState("");
  const [remark, setRemark] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const columns = item?.details
    ?.filter((detail) => /^column\d*$/.test(detail?.option_type))
    .reverse() || [item?.details];

  const [rows, setRows] = useState(
    item?.details
      ?.filter((detail) => detail?.option_type === "row")
      .reverse() || []
  );
  const [heading, setHeading] = useState(
    item?.details
      ?.filter((detail) => detail?.option_type === "heading")
      .reverse() || []
  );
  const [matrixData, setMatrixData] = useState([]);
  const [updatedRows, setUpdatedRows] = useState();
  const [meterList, setMeterList] = useState([]);
  const [proofDocument, setProofDocument] = useState([[]]);
  const [radioValue, setRadioValue] = useState("");
  const [check, setCheck] = useState(false);
  const [edit, setEdit] = useState(true);
  const [status, setStatus] = useState();

  const [showData, setShowData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setComment] = useState([[]]);
  const [file, setFile] = useState("");

  const handleDoubleClick = (data) => {
    setShowData(data);
    setIsModalOpen(true);
  };

  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      width: "100%",
    },
    text: {
      color: "rgba(0, 0, 0, 0.70)",
      fontSize: "12px",
      fontFamily: "Open Sans",
      fontWeight: 400,
    },
    uploadDiv: {
      width: "100%",
      backgroundColor: "#3F88A5",
      borderRadius: "10px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "white",
      position: "relative",
      border: "2px dashed #3F88A5",
      transition: "background-color 0.3s ease",
    },
    uploadDivHover: {
      backgroundColor: "#30707E", // Darker blue on hover
    },
    icon: {
      position: "absolute",
      right: "10px",
      fontSize: "10px",
      color: "white",
    },
    fileInput: {
      display: "none",
    },
    formGroup: {
      marginBottom: "15px",
    },
  };

  const initialize2DArray = (data) => {
    // Initialize an empty object to track column counts per row
    let columnCounts = {};

    // Iterate through data to determine maximum columns for each row
    data.forEach((item) => {
      if (item.option_type.startsWith("column")) {
        let rowNumber = parseInt(item.option_type.replace("column", ""));
        columnCounts[rowNumber] = (columnCounts[rowNumber] || 0) + 1;
      }
    });

    // Find maximum columns from columnCounts
    let maxColumns = Math.max(...Object.values(columnCounts));

    // Initialize an empty 2D array
    let result = [];

    // Create each row with the determined number of columns
    for (let i = 0; i < Object.keys(columnCounts).length; i++) {
      let row = new Array(maxColumns).fill("");
      result.push(row);
    }

    return result;
  };

  const initializeArray = (numRows, numCols) => {
    const newArray = [];
    for (let i = 0; i < numRows; i++) {
      const row = [];
      for (let j = 0; j < numCols; j++) {
        row.push(""); // Initialize each cell with an empty string or any default value
      }
      newArray.push(row);
    }
    return newArray;
  };

  useEffect(() => {
    if (sourceData) {
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      if (locationArray && locationArray.length) {
        setMeterList(locationArray);
        setSelectedSource(locationArray[0]?.id);
      }
    }
  }, [sourceData]);

  const formatNote = (note) => {
    // Check if note is a string
    if (typeof note === "string") {
      return [[note]]; // Wrap the string in a 2D array
    }

    // Check if note is a 2D array
    if (Array.isArray(note) && Array.isArray(note[0])) {
      return note; // Use it as is
    }

    // If note is not a string or a 2D array, return an empty 2D array
    return [[""]];
  };

  const initializeAnswer = () => {
    if (item && item.questionId && savedAnswers && savedAnswers.length) {
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
      } else {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
      }

      if (matchedAnswer) {
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        try {
          // Parse the answer as a 2D array
          setCheck(true);
          setNote((prevState) => {
            return formatNote(matchedAnswer.note);
          });

          if (Array.isArray(matchedAnswer.proofDocument)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocument[0])) {
              setProofDocument(matchedAnswer.proofDocument);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setProofDocument([matchedAnswer.proofDocument]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setProofDocument([[]]);
          }

          if (Array.isArray(matchedAnswer.proofDocumentNote)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocumentNote[0])) {
              setComment(matchedAnswer.proofDocumentNote);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setComment([matchedAnswer.proofDocumentNote]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setComment([[]]);
          }
          const answerObject = JSON.parse(matchedAnswer.answer);

          // Ensure that the parsed answer is a 2D array
          if (Array.isArray(answerObject) && Array.isArray(answerObject[0])) {
            const standaloneOption =
              "Disclosures under this report made on a standalone basis (i.e. only for the entity).";
            const consolidatedOption =
              "On a consolidated basis (i.e. for the entity & all the entities which form a part of its consolidated financial statements, taken together)";
            answerObject[0].forEach((answer) => {
              if (
                answer === standaloneOption ||
                answer === consolidatedOption
              ) {
                setRadioValue(answer);
              }
            });
            setUpdatedRows(answerObject);

            setAnswer((prevAnswer) => ({
              ...prevAnswer,
              questionId: item?.questionId,
              moduleId: item?.moduleId,
              questionType: item?.questionType,
              frequency: item?.frequency,
              note: formatNote(matchedAnswer.note),
              comment: matchedAnswer?.comment || [[]],
              proofDocument: matchedAnswer?.proofDocument || [[]],
              answer: JSON.stringify(answerObject),
            }));
            if (matchedAnswer.sourceId) {
              const sourceObject = meterList?.find(
                (source) => source.id == matchedAnswer.sourceId
              );
              if (sourceObject) {
                setSourceName(sourceObject?.location);
                // Set selectedSource to the sourceObject's name or location
                setSelectedSource(sourceObject?.id); // Assuming 'name' is the correct property
                // If 'location' is the correct property, use sourceObject.location instead
              }
            }
          } else {
            console.error(
              "Parsed answer is not a valid 2D array:",
              answerObject
            );
          }
        } catch (error) {
          console.error("Error parsing matchedAnswer.answer:", error, matchedAnswer, item);
        }
      } else {
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
          note: [[""]],
        }));
        setProofDocument([[]]);
        setNote([[]]);
        const ro =
          item?.details
            ?.filter((detail) => detail?.option_type === "row")
            .reverse() || [];
        const colum =
          item?.details
            ?.filter((detail) => detail?.option_type === "column")
            .reverse() || [];
        const numRows = ro.length;
        const numCols = colum.length;
        if (numRows > 0 && numCols > 0) {
          setUpdatedRows(initializeArray(numRows, numCols));
          setProofDocument(initializeArray(numRows, numCols));
          setComment(initializeArray(numRows, numCols));
          setNote(initializeArray(numRows, numCols));
        } else {
          setUpdatedRows(initialize2DArray(item.details));
          setProofDocument(initialize2DArray(item.details));
          setComment(initialize2DArray(item.details));
          setNote(initialize2DArray(item.details));
        }
      }
    }
  };

  useEffect(() => {
    setRows(
      item?.details
        ?.filter((detail) => detail?.option_type === "row")
        .reverse() || []
    );
  }, [item]);

  // Effect to initialize updatedRows based on rows and columns
  useEffect(() => {
    setEdit(true)
    if (item) {
      const ro =
        item?.details
          ?.filter((detail) => detail?.option_type === "row")
          .reverse() || [];
      const colum =
        item?.details
          ?.filter((detail) => detail?.option_type === "column")
          .reverse() || [];
      const numRows = ro.length;
      const numCols = colum.length;
      if (numRows > 0 && numCols > 0) {
        setUpdatedRows(initializeArray(numRows, numCols));
        setProofDocument(initializeArray(numRows, numCols));
        setComment(initializeArray(numRows, numCols));
        setNote(initializeArray(numRows, numCols));
      } else {
        setUpdatedRows(initialize2DArray(item.details));
        setProofDocument(initialize2DArray(item.details));
        setComment(initialize2DArray(item.details));
        setNote(initializeArray(numRows, numCols));
      }
      if (savedAnswers?.length > 0 && Object.keys(savedAnswers[0]).length > 0){
        initializeAnswer();
      }
      
    }
  }, [savedAnswers, toDate, fromDate, item, sourceChange]);

  const handleInputChange = (rowIndex, colId, value) => {
    const updatedRow = [...updatedRows];
    if (!updatedRow[rowIndex]) {
      updatedRow[rowIndex] = [];
    }
    updatedRow[rowIndex][colId] = value;
    setUpdatedRows(updatedRow);
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionType: item?.questionType,
        frequency: item?.frequency,
        answer: JSON.stringify(updatedRow),
      };
    });
  };

  const handleProofChange = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[currentPage]) {
          newProofDocument[currentPage] = [];
        }

        newProofDocument[currentPage] = [
          ...newProofDocument[currentPage],
          url.url,
        ];

        return newProofDocument;
      });

     
      // setAnswer((prevState) => ({
      //   ...prevState,
      //   questionId: item?.questionId,
      //   moduleId: item?.moduleId,
      //   questionType: item?.questionType,
      //   frequency: item?.frequency,
      //   proofDocument: [...prevState.proofDocument, url.url],
      // }));
      setAnswer((prevState) => {
        // Clone the current proofDocument
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[currentPage]) {
          newProofDocument[currentPage] = [];
        }

        // Update the specific page
        newProofDocument[currentPage] = [
          ...newProofDocument[currentPage],
          url.url,
        ];

        // Return the updated state
        return {
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
          proofDocument: newProofDocument,
        };
      });

      setFile(`${timestamp}_${selectedFile.name}`);
      setCommentModal(true);
    } catch (error) {
      if (error) {
        swal({
          icon: "error",
          title: error,
          timer: 1000,
        });
      }
    }
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setRemark("")
      setAuditButton(false);
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        setRemark("")
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleCommentChange = (value) => {
    setComment((prevState) => {
      const newProofDocument = [...prevState];

      if (!newProofDocument[currentPage]) {
        newProofDocument[currentPage] = [];
      }

      newProofDocument[currentPage] = [...newProofDocument[currentPage], value];

      return newProofDocument;
    });

    setAnswer((prevState) => {
      // Clone the current proofDocument
      const newProofDocument = [...prevState.comment];

      // If currentPage doesn't exist yet, initialize it as an empty array
      if (!newProofDocument[currentPage]) {
        newProofDocument[currentPage] = [];
      }

      // Update the specific page
      newProofDocument[currentPage] = [...newProofDocument[currentPage], value];

      // Return the updated state
      return {
        ...prevState,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionType: item?.questionType,
        frequency: item?.frequency,
        proofDocumentNote: newProofDocument,
      };
    });
  };

  const handleSubmit = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
        financialYearId: financeObject,
        source: selectedSource,
      },
      "POST"
    );

    if (isSuccess) {
      // getReportingQuestions();
    }
  };

  const updateMatrixData = (rowIndex, colId, value) => {
    setMatrixData((prevMatrixData) => {
      const updatedMatrixData = prevMatrixData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [colId]: value,
          };
        } else {
          return row;
        }
      });
      return updatedMatrixData;
    });
  };


  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote((prevNote) => {
      // Clone the current note array
      const updatedNote = [...prevNote];

      // Ensure currentIndex exists in the array
      if (!updatedNote[currentPage]) {
        updatedNote[currentPage] = [];
      }

      // Update the note at currentIndex
      updatedNote[currentPage] = newNote;

      return updatedNote;
    });

    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      note:
        prevAnswer.note &&
        Array.isArray(prevAnswer.note) &&
        Array.isArray(prevAnswer.note[0])
          ? [
              ...prevAnswer.note.slice(0, currentPage),
              [newNote],
              ...prevAnswer.note.slice(currentPage + 1),
            ]
          : [[newNote]], // Convert to 2D array if it's not already
    }));
  };

  const handleNextPage = () => {
    if (
      item?.details.some(
        (item) => item.option_type == "row" && item.option == 1
      )
    ) {
      if (updatedRows?.length <= currentPage + 1) {
        const tmpupdatedRows = [...updatedRows];
        const tempdoc = [...proofDocument];
        const tempCom = [...comment];
        const tempNote = [...note];
        const ro =
          item?.details
            ?.filter((detail) => detail?.option_type === "row")
            .reverse() || [];
        const colum =
          item?.details
            ?.filter((detail) => detail?.option_type === "column")
            .reverse() || [];
        const numRows = ro.length;
        const numCols = colum.length;
        const initializeArrays = initializeArray(numRows, numCols);
        tmpupdatedRows.push(initializeArrays[0]);
        tempdoc.push(initializeArrays[0]);
        tempCom.push(initializeArrays[0]);
        tempNote.push(initializeArrays[0]);
        setNote(tempNote);
        setProofDocument(tempdoc);
        setComment(tempCom);
        setUpdatedRows(tmpupdatedRows);
      }
    }

    const currentRowData = [...rows];
    setMatrixData((prevMatrixData) => [...prevMatrixData, currentRowData]);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePlusPage = () => {
    if (
      item?.details.some(
        (item) => item.option_type == "row" && item.option == 1
      )
    ) {
      const temp = {
        option_type: "row",
        option: "1",
        rules: null,
      };
      setRows((prevRows) => [...prevRows, temp]);
      if (updatedRows?.length <= currentPage + 1) {
        const tempProofdocs = [...proofDocument];
        const tmpupdatedRows = [...updatedRows];
        const tempCom = [...comment];
        const tempNote = [...note];
        const ro =
          item?.details
            ?.filter((detail) => detail?.option_type === "row")
            .reverse() || [];
        const colum =
          item?.details
            ?.filter((detail) => detail?.option_type === "column")
            .reverse() || [];
        const numRows = ro.length;
        const numCols = colum.length;
        const initializeArrays = initializeArray(numRows, numCols);
        tempProofdocs.push(initializeArrays[0]);
        tmpupdatedRows.push(initializeArrays[0]);
        tempCom.push(initializeArrays[0]);
        tempNote.push(initializeArrays[0]);
        setProofDocument(tempProofdocs);
        setComment(tempCom);
        setNote(tempNote);
        setUpdatedRows(tmpupdatedRows);
      }
    }

    const currentRowData = [...rows];

    setMatrixData((prevMatrixData) => [...prevMatrixData, currentRowData]);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {}, [updatedRows]);
  // const handleSave = () => {};
  // const handleSourceSelect = (key) => {
  //   const selectedItem = meterList.find((item) => item.id.toString() === key);
  //   if (selectedItem) {
  //     // Set the selected source to the item's location (name)
  //     setSelectedSource(selectedItem.location);
  //     // Update the answer state with the selected item's id
  //     setAnswer((prevAnswer) => ({
  //       ...prevAnswer,
  //       sourceId: Number(key),
  //     }));
  //   }
  // };

  const handleEditClick = () => {
    setEdit(!edit);
  };

  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {check &&
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) && (
            <FaEdit
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                height: "20px",
                width: "20px",
                color: edit ? "black" : "#BFD7E0",
              }}
              onClick={handleEditClick}
            />
          )}
      </div>
      {rows.length > 0 && (
        <RowComponent
          item={item}
          edit={edit}
          comment={comment[currentPage]}
          handleCommentChange={handleCommentChange}
          note={note[currentPage]}
          setIsModalOpen={setIsModalOpen}
          handleProofChange={handleProofChange}
          isModalOpen={isModalOpen}
          proofDocument={proofDocument[currentPage]}
          DetailModal={
            <DetailModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              data={showData}
            />
          }
          menu={menu}
          heading={heading}
          assignedToDetails={assignedToDetails}
          currentUserId={currentUserId}
          answer={answer}
          setAnswer={setAnswer}
          rowIndex={currentPage}
          file={file}
          columns={columns}
          setProofDocument={setProofDocument}
          commentModal={commentModal}
          setCommentModal={setCommentModal}
          rowData={rows[currentPage]}
          updatedRows={updatedRows}
          handleNoteChange={handleNoteChange}
          handleInputChange={handleInputChange}
          updateMatrixData={updateMatrixData}
          radioValuee={radioValue}
          check={check}
        />
      )}

      {/* <Col md={6}>
        <Form.Group controlId="formFile">
          <Form.Label className="custom-label">Upload Attachment</Form.Label>
          <Form.Control
            style={{ backgroundColor: "#BFD7E0" }}
            type="file"
            onChange={(e) => uploadFile(e)}
          />
        </Form.Group>
      </Col> */}
      <>
        {/* <Form.Group>
          <Form.Label className="custom-label">Uploaded Files</Form.Label>
          {proofDocument.length > 0 &&
            proofDocument.map((url, index) => {
              const segments = url.split("/");
              const fileName =
                segments.length > 1
                  ? segments[segments.length - 2] ===
                    segments[segments.length - 1]
                    ? segments[segments.length - 1] // Handle repeated segment
                    : segments[segments.length - 1] // Regular case
                  : url;

              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                    borderBottom: "1px solid #ccc",
                    paddingBottom: 5,
                  }}
                >
            
                  <FaFileDownload
                    style={{
                      marginLeft: 10,
                      cursor: "pointer",
                      height: "20px",
                      width: "20px",
                      color: "#3F88A5", 
                    }}
                    onClick={() => handleFileDownload(url)}
                    title="Download File" 
                  />

                
                  <span
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      wordBreak: "break-all",
                      flexGrow: 1, 
                      maxWidth: "70%", 
                    }}
                    title={fileName}
                  >
                    {fileName}
                  </span>

              
                  <FaTrash
                    style={{
                      cursor: "pointer",
                      height: "20px",
                      width: "20px",
                      color: "#3F88A5",
                      marginRight: 20, 
                    }}
                    onClick={() => handleDeleteClick(url)}
                    title="Remove File" 
                  />
                </div>
              );
            })}
        </Form.Group> */}

        {/* Confirmation Modal */}
        <DetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          data={showData}
        />
      </>

      {menu === "audit" && (
        <Col md={8}>
          <Form.Group controlId="formInput12">
            <Form.Label className="custom-label">Remark</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#BFD7E0" }}
              type="text"
              as="textarea"
              value={remark}
              onChange={handleRemarkChange}
            />
          </Form.Group>
        </Col>
      )}

      <div className="d-flex justify-content-between align-items-center mt-3">
        {assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) &&
          (menu === "audit" ? (
            <>
              {auditItem &&
                  auditItem?.matchingAuditors
                    .find((d) => d.answerId == answerIdReal)
                    ?.auditerId == currentUserId && (
                <>
                  {
                  (
                    auditItem?.matchingAuditors.find(
                      (d) => d.answerId == answerIdReal
                    )?.remark == null) ||
                  auditItem?.matchingAuditors
                    .find((d) => d.answerId == answerIdReal)
                    ?.remark?.every(
                      (remark) => remark.id != currentUserId
                    ) ||  auditItem.matchingAuditors?.some(
                      (auditor) => Number(auditor?.auditerId) === Number(currentUserId)
                    ) ? (
                    <>
                      <Button
                        className="btn btn-secondary"
                        disabled={auditButton}
                        style={{
                          borderColor: "white",
                          backgroundColor: "#3F88A5",
                          padding: "1%",
                          paddingLeft: "1.5%",
                          paddingRight: "1.5%",
                          width: "15%",
                          minWidth: "100px",
                          maxWidth: "150px",
                        }}
                        onClick={handleAccept}
                      >
                        Accept
                      </Button>
                      <Button
                        className="btn btn-secondary"
                        style={{
                          borderColor: "white",
                          backgroundColor: "#3F88A5",
                          padding: "1%",
                          paddingLeft: "1.5%",
                          paddingRight: "1.5%",
                          width: "15%",
                          minWidth: "100px",
                          maxWidth: "150px",
                          marginLeft: "10px",
                        }}
                        disabled={auditButton}
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </>
                  ) : status === "ACCEPTED" || status === "REJECTED" ? (
                    <p>This answer has been audited.</p>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <Button
              className="btn btn-secondary"
              disabled={item?.frequency === "CUSTOM" && !selectedPeriod}
              style={{
                borderColor: "white",
                backgroundColor: "#3F88A5",
                padding: "1%",
                paddingLeft: "1.5%",
                paddingRight: "1.5%",
                width: "15%",
                minWidth: "100px",
                maxWidth: "150px",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ))}

        {!(
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          )
        ) &&
          (menu === "audit" ? (
            <>
              <>
                {auditItem &&
                  auditItem?.matchingAuditors
                    .find((d) => d.answerId == answerIdReal)
                    ?.auditerId == currentUserId && answerIdReal && (
                  <>
                    {
                    (
                      auditItem?.matchingAuditors.find(
                        (d) => d.answerId == answerIdReal
                      )?.remark == null) ||
                    auditItem?.matchingAuditors
                      .find((d) => d.answerId == answerIdReal)
                      ?.remark?.every(
                        (remark) => remark.id != currentUserId
                      ) ||  auditItem.matchingAuditors?.some(
                        (auditor) => Number(auditor?.auditerId) === Number(currentUserId)
                      ) ? (
                      <>
                        <Button
                          className="btn btn-secondary"
                          disabled={auditButton}
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "1%",
                            paddingLeft: "1.5%",
                            paddingRight: "1.5%",
                            width: "15%",
                            minWidth: "100px",
                            maxWidth: "150px",
                          }}
                          onClick={handleAccept}
                        >
                          Accept
                        </Button>
                        <Button
                          className="btn btn-secondary"
                          disabled={auditButton}
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "1%",
                            paddingLeft: "1.5%",
                            paddingRight: "1.5%",
                            width: "15%",
                            minWidth: "100px",
                            maxWidth: "150px",
                            marginLeft: "10px",
                          }}
                          onClick={handleReject}
                        >
                          Reject
                        </Button>
                      </>
                    ) : status === "ACCEPTED" || status === "REJECTED" ? (
                      <p>This answer has been audited.</p>
                    ) : null}
                  </>
                )}
              </>
            </>
          ) : (
            <></>
          ))}

        <div
          className="d-flex"
          style={{ justifyContent: "flex-end", gap: "5px", flex: 1 }}
        >
          {/* <Button
            className="btn btn-primary me-5x"
            style={{
              borderColor: "white",
              backgroundColor: "#3F88A5",
              paddingLeft: "2%",
              paddingRight: "2%",
              width: "15%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
            onClick={handleSave}
          >
            Save the answer
          </Button> */}

          {rows[0] &&
            rows[0]?.option === "1" &&
            assignedToDetails?.assignedTo?.length > 0 &&
            assignedToDetails.assignedTo.some(
              (id) => parseInt(id, 10) === currentUserId
            ) && (
              <Button
                className="btn btn-primary"
                style={{
                  borderColor: "white",
                  backgroundColor: "#3F88A5",
                  padding: "1%",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  width: "5%",
                  minWidth: "50px",
                  maxWidth: "150px",
                }}
                onClick={handlePlusPage}
                title="Add more"
              >
                +
              </Button> 
            )}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3 gap-3">
        {/* {rows.length !== 1 && currentPage >= 1 && ( */}
        <div className="arrow-container">
          <Button
            // disabled={!(rows.length !== 1 && currentPage >= 1)}
            disabled={
              !(updatedRows?.length > rows?.length
                ? updatedRows.length !== 1 && currentPage >= 1
                : rows.length !== 1 && currentPage >= 1)
            }
            onClick={handlePreviousPage}
            style={{
              borderColor: "white",
              backgroundColor: "#3F88A5",
              padding: "1%",
              paddingLeft: "1.5%",
              paddingRight: "1.5%",
              width: "15%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
          >
            Previous
          </Button>
        </div>
        {/* )} */}
        {/* {rows.length !== 1 && currentPage <= rows.length - 2 && ( */}
        <div className="arrow-container">
          <Button
            onClick={handleNextPage}
            disabled={
              !(updatedRows?.length > rows?.length
                ? updatedRows.length !== 1 &&
                  currentPage <= updatedRows.length - 2
                : rows?.length !== 1 && currentPage <= rows.length - 2)
            }
            style={{
              borderColor: "white",
              backgroundColor: "#3F88A5",
              padding: "1%",
              paddingLeft: "1.5%",
              paddingRight: "1.5%",
              width: "15%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
          >
            Next
          </Button>
        </div>
        {/* )} */}
      </div>
    </Form>
  );
};

export default TabularComponent;
